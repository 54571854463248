import { Organization as CdmOrganization, Role, SystemUser } from '@plugsurfing/cdm-api-client';
import type { Privilege } from 'cdm-api-client/v1RolesApi';
import Authorization from 'components/general/Authorization/Authorization';
import { Organization, OrganizationModule } from 'models/organization';
import { ComponentType } from 'react';
import AccessDeniedView from 'views/AccessDeniedView';

export const {
  SYSTEMUSERREAD,
  SYSTEMUSERUPDATE,
  SYSTEMUSERDELETE,
  SYSTEMUSERCREATE,
  CUSTOMERREAD,
  CUSTOMERDELETE,
  CUSTOMERKEYCREATE,
  CUSTOMERKEYREAD,
  CUSTOMERKEYUPDATE,
  CUSTOMERKEYORDERREAD,
  CUSTOMERPAYMENTMETHODSREAD,
  CUSTOMERPAYMENTMETHODSCREATE,
  CUSTOMERPAYMENTMETHODSUPDATE,
  CUSTOMERPAYMENTMETHODSDELETE,
  CHARGINGSESSIONSREAD,
  CHARGINGSESSIONSUPDATE,
  CHARGINGSESSIONSCREATE,
  CONNECTORPRICEREAD,
  CONNECTORPRICEUPDATE,
  CONNECTORPRICECREATE,
  GENERALEXPORTREAD,
  ORGANIZATIONCREATE,
  ORGANIZATIONREAD,
  ORGANIZATIONUPDATE,
  ORGANIZATIONSTRIPEACCOUNTCREATE,
  ORGANIZATIONELWINACCOUNTCREATE,
  ORGANIZATIONTEMPLATECREATE,
  ORGANIZATIONTEMPLATEREAD,
  TEMPLATEREAD,
  TEMPLATEUPDATE,
  ORGANIZATIONACCESSIBLECREATE,
  ORGANIZATIONACCESSIBLEDELETE,
  ORGANIZATIONCKRREAD,
  ORGANIZATIONCKRIMPORTCREATE,
  EMPCPOASSOCIATIONREAD,
  CAMPAIGNREAD,
  CHARGEPOINTMODELCREATE,
  CHARGEPOINTMODELREAD,
  CHARGEPOINTMODELUPDATE,
  CHARGEPOINTSITECREATE,
  CHARGEPOINTSITEREAD,
  CHARGEPOINTSITEUPDATE,
  CHARGEPOINTSITEDELETE,
  CHARGEPOINTCREATE,
  CHARGEPOINTREAD,
  CHARGEPOINTLOGREAD,
  CHARGEPOINTUPDATE,
  CHARGEPOINTDELETE,
  CHARGEPOINTREPORTINGREAD,
  CHARGEPOINTREPORTINGSENSITIVEDATAREAD,
  CHARGEPOINTCOMMANDSTOP,
  CHARGEPOINTCOMMANDRESET,
  CHARGEPOINTCOMMANDCLEARAUTHORIZATIONLIST,
  CHARGEPOINTCOMMANDCLEARCACHE,
  CHARGEPOINTCOMMANDUNLOCK,
  CHARGEPOINTCOMMANDSYNCHRONIZECONFIGURATION,
  CHARGEPOINTCOMMANDGETDIAGNOSTICS,
  CHARGEPOINTFIRMWARECREATE,
  CHARGEPOINTFIRMWAREREAD,
  CHARGEPOINTFIRMWAREUPDATE,
  CHARGEPOINTNOTIFICATIONPROFILECREATE,
  CHARGEPOINTCONNECTIONPROFILECREATE,
  CHARGEPOINTCONNECTIONPROFILEREAD,
  CHARGEPOINTCONNECTIONPROFILEUPDATE,
  CHARGEPOINTCONNECTIONPROFILEDELETE,
  EVSEMAPPINGCREATE,
  EXTERNALCHARGEPOINTREAD,
  EXTERNALCHARGEPOINTDELETE,
  EXTERNALLOCATIONREAD,
  EXTERNALLOCATIONWRITE,
  EXTERNALLOCATIONDELETE,
  ISSUETRACKERCREATE,
  ISSUETRACKERREAD,
  ISSUETRACKERDELETE,
  PUBLISHINGCHANNELSET,
  SMARTCHARGINGCREATE,
  SMARTCHARGINGREAD,
  SMARTCHARGINGDELETE,
  CONNECTORGROUPUPDATE,
  CONNECTORGROUPCREATE,
  CONNECTORGROUPDELETE,
  CONNECTORGROUPREAD,
  CONNECTORGROUPSGROUPSUPDATE,
  FLEETMEMBERINVITECREATE,
  FLEETMEMBERINVITEREAD,
  FLEETFEECREATE,
  FLEETFEEDELETE,
  FLEETFEEREAD,
  USERGROUPSCREATE,
  USERGROUPSREAD,
  USERGROUPSUPDATE,
  USERGROUPSDELETE,
  USERGROUPSADDUSER,
  USERGROUPSADDORGANIZATION,
  USERGROUPSREMOVEORGANIZATION,
  USERGROUPSREMOVEUSER,
  PARTNERCREATE,
  PARTNERDELETE,
  PARTNERREAD,
  CHARGINGAUTHORIZATIONGROUPREAD,
  CHARGINGAUTHORIZATIONGROUPUPDATE,
  EVMODELSCREATE,
  EVMODELSREAD,
  MANAGEPLATFORM,
  ORGANIZATIONBRANDINGDELETE,
  ORGANIZATIONBRANDINGREAD,
  ORGANIZATIONBRANDINGUPDATE,
  CUSTOMERINVOICEREAD,
  CUSTOMERINVOICEUPDATE,
  CUSTOMERINVOICECREATE,
  SUBSCRIPTIONCREATE,
  SUBSCRIPTIONDELETE,
  SUBSCRIPTIONREAD,
  SUBSCRIPTIONUPDATE,
  ORGANIZATIONREGISTRATIONREAD,
  ORGANIZATIONREGISTRATIONUPDATE,
  ORGANIZATIONREGISTRATIONDELETE,
  PRODUCTCREATE,
  PRODUCTDELETE,
  PRODUCTREAD,
  PRODUCTUPDATE,
  ADHOCCONFIGREAD,
  ADHOCCONFIGUPDATE,
  ORGANIZATIONACCEPTEDPAYMENTMETHODCREATE,
  ORGANIZATIONACCEPTEDPAYMENTMETHODREAD,
  CDRUPLOAD,
  SESSIONCLASSIFICATIONRULESCREATE,
  SESSIONCLASSIFICATIONRULESDELETE,
  SESSIONCLASSIFICATIONRULESREAD,
  CONNECTORGROUPSGROUPSREAD,
  CONNECTORGROUPSGROUPSCREATE,
  CONNECTORGROUPSGROUPSDELETE,
  MARKETPLACEAGREEMENTCREATE,
  MARKETPLACEAGREEMENTREAD,
  EXTERNALROAMINGINTEGRATIONSCREATE,
  EXTERNALROAMINGINTEGRATIONSREAD,
  EXTERNALROAMINGINTEGRATIONSUPDATE,
  PAYOUTREPORTREAD,
  FLEETPAYMENTMETHODCREATE,
  FLEETPAYMENTMETHODDELETE,
  FLEETPAYMENTMETHODREAD,
  FLEETPAYMENTMETHODUPDATE,
  BANKTRANSFERCREATE,
  BANKTRANSFERUPDATE,
  EXTERNALPAYMENTMETHODCREATE,
  EXTERNALPAYMENTMETHODDELETE,
  ERRORSCENARIOREAD,
  ERRORSCENARIOWRITE,
  REPORTEDCHARGERPROBLEMREAD,
  PRICEASSOCIATIONBULKWRITE,
  PRICEPROFILEBULKWRITE,
  INVOICERECONCILIATIONCREATE,
  BOTENANNA,
  TAXATIONREAD,
  TAXATIONWRITE,
  SYSTEMAPIKEYREAD,
  SYSTEMAPIKEYCREATE,
  SYSTEMAPIKEYUPDATE,
  DEVELOPERPAGELOGSREAD,
  DEVELOPERPAGEWEBHOOKSREAD,
  DEVELOPERPAGEOVERVIEWREAD,
  DEVELOPERPAGEWEBHOOKSMANAGE,
  ORGANIZATIONCONFIGCREATE,
  ORGANIZATIONCONFIGREAD,
  ORGANIZATIONCONFIGUPDATE,
  ORGANIZATIONCONFIGDELETE,
  ORGANIZATIONCONFIGDEVELOPERADMIN,
  CREDITCARDRESERVATIONUPDATE,
  CREDITCARDRESERVATIONENABLE,
  CREDITCARDRESERVATIONREAD,
  STRIPELINKREAD,
  RAWTARIFFDATAREAD,
  AUTHRESULTREAD,
  MFARESET,
  REMOTESTARTCONFIGREAD,
  REMOTESTARTCONFIGCREATE,
  REMOTESTARTCONFIGDELETE,
  REMOTESTARTCONFIGUPDATE,
  DISCOUNTREAD,
  DISCOUNTWRITE,
} = Role.PrivilegesEnum;

export interface PrivilegeCheck {
  allowedPrivileges?: Privilege | Privilege[];
  allowedModules?: OrganizationModule.AbbreviationEnum[];
}

export function createComponentWithPrivilegeAuthorization<P = object>(
  allowedPrivileges: Privilege | Privilege[] | undefined,
  AuthComponent: ComponentType<P>,
  FallbackComponent: ComponentType<any> | null = null,
  allowedModules?: OrganizationModule.AbbreviationEnum[],
): ComponentType<P> {
  const ComponentWithPrivilegeAuthorization =
    allowedPrivileges || allowedModules
      ? Authorization(allowedPrivileges, allowedModules)(AuthComponent, FallbackComponent)
      : (AuthComponent as any);
  return props => <ComponentWithPrivilegeAuthorization {...props} />;
}

export function AuthorizationWithAccessDenied(
  privileges: Privilege | Privilege[] | undefined,
  Component: ComponentType<any>,
  allowedModules?: OrganizationModule.AbbreviationEnum[],
) {
  return Authorization(privileges, allowedModules)(Component, AccessDeniedView);
}

export function isEMP(organizationLike: Pick<Organization, 'modules'>) {
  return (organizationLike.modules || []).some(
    module => module.abbreviation === OrganizationModule.AbbreviationEnum.EMP,
  );
}

export function isExternalEMP(organization: Organization) {
  return (organization.modules || []).some(module => module.abbreviation === CdmOrganization.ModulesEnum.EXTERNALEMP);
}

export function isCPO(organization: Organization) {
  return (organization.modules || []).some(module => module.abbreviation === OrganizationModule.AbbreviationEnum.CPO);
}

export function isExternalCPO(organization: Organization) {
  return (organization.modules || []).some(module => module.abbreviation === CdmOrganization.ModulesEnum.EXTERNALCPO);
}

export function isAO(organization: Organization) {
  return (
    !isCPO(organization) &&
    (organization.modules || []).some(module => module.abbreviation === OrganizationModule.AbbreviationEnum.AO)
  );
}

export function isLO(organization: Organization) {
  return (
    !isCPO(organization) &&
    (organization.modules || []).some(module => module.abbreviation === OrganizationModule.AbbreviationEnum.LO)
  );
}

export function isFleet(organization: Organization) {
  return (organization.modules || []).some(module => module.abbreviation === CdmOrganization.ModulesEnum.FLEET);
}

export function isPublic(organization: Organization) {
  return (organization.modules || []).some(module => module.abbreviation === CdmOrganization.ModulesEnum.PUBLIC);
}

export function getUserPrivileges(user: SystemUser, allRoles: Role[]) {
  const { roles: userRoles = [] } = user;
  return [
    ...new Set(
      allRoles.reduce<Role.PrivilegesEnum[]>(
        (arr, role) => (userRoles.find(ur => ur.id === role.id) ? [...arr, ...(role.privileges || [])] : arr),
        [],
      ),
    ),
  ];
}

export function canUserAccess(
  user: SystemUser,
  allRoles: Role[],
  privileges?: Privilege | Privilege[],
  modules?: OrganizationModule.AbbreviationEnum[],
): boolean {
  // @ts-ignore TODO: Align org module consistency
  const userModules = user.organization?.modules?.map(m => (typeof m === 'object' ? m.abbreviation : m)) || [];
  const userPrivileges = getUserPrivileges(user, allRoles);
  const privilegesArray = privileges === undefined ? [] : Array.isArray(privileges) ? privileges : [privileges];
  const hasPrivileges =
    privilegesArray.length === 0 || privilegesArray.some(privilege => userPrivileges.includes(privilege));
  const hasModules = modules ? modules.some(m => userModules.includes(m)) : true;
  return hasPrivileges && hasModules;
}
