import { v3SessionHistoryApi as original } from '../__generated__/cdm-api-client/v3SessionHistoryApi';

export * from '../__generated__/cdm-api-client/v3SessionHistoryApi';

export const v3SessionHistoryApi = original.enhanceEndpoints({
  addTagTypes: ['sessions', 'session'],
  endpoints: {
    searchSessionsUsingPost1: {
      providesTags: res => [
        'sessions',
        ...(res?.content.map(session => ({ type: 'session' as const, id: session.id })) ?? []),
      ],
    },
  },
});
