import { BasicChargePoint, BasicConnector, ChargePointStatusResponse } from '@plugsurfing/cdm-api-client';
import { MAX_DATE } from 'config/constants';
import { LocalesKey, t } from 'i18n';
import produce from 'immer';
import { DateTime } from 'luxon';
import { ConnectorInformation } from 'views/chargers/CreateChargePoint/Forms/ConnectorsForm';
import { priceFromString } from '../pricing';
import { isEmptyString } from './general';

const createMinLengthValidationMessage = (min: number) => t('validateMinCharacters', { min });
const createMaxLengthValidationMessage = (max: number) => t('validateMaxCharacters', { max });

export const createMinLengthValidator = (min: number) => (value?: string) => {
  if (value === undefined || value.length < min) {
    return createMinLengthValidationMessage(min);
  }
};

/**
 * @param fileName full file name with format extension.
 * @param allowedFormats array of allowed file formats without dots in front.
 */
export const isAllowedFileFormat = (fileName: any, allowedFormats: string[]) => {
  for (const format of allowedFormats) {
    if (fileName !== undefined && fileName.endsWith(`.${format}`)) {
      return undefined;
    }
  }

  return t('validateFileAllowedFormat', { allowedFormats });
};

export const notFalse = (value: boolean) => {
  if (value === undefined || value === null || value === false) {
    return t('validateNotFalse');
  }
};

export const notEmpty = (value: any) => {
  if (value === undefined || value === '' || value === null) {
    return t('validateNotEmpty');
  }
};

export const notDuplicate = (value: any, arr: any[], selfIndex: number) => {
  if (arr.indexOf(value) !== selfIndex) {
    return t('validateNotDuplicate');
  }
};

export const notEmptyString = (value: string) => {
  if (value === undefined || value.trim() === '') {
    return t('validateNotEmpty');
  }
};

export const notCurrencyValue = (value: string) => {
  const n = priceFromString(value);
  if (n === -1) {
    return t('validateInvalidPrice');
  }
};

export const notValidMonetaryValue = (value: string) => {
  if (!/^((0|([1-9][0-9]*))([.][0-9]{1,2})?)$/.test(value)) {
    return t('validateInvalidPrice');
  }
};

export const notValidFloat = (value: string) => {
  if (!/^((0|([1-9][0-9]*))([.][0-9]{1,})?)$/.test(value)) {
    return t('validateIsNumber');
  }
};

export const notValidExternalOrgId = (value: string) => {
  // For OCPI & Hubject
  if (!/^(([A-Za-z]{2}\*?[A-Za-z0-9]{3})|(\+?[0-9]{1,3}\*[0-9]{3}))$/.test(value)) {
    return t('validateExternalId');
  }
};

export const notCurrencyValueOptional = (value: string) => {
  if (value === undefined || value === '') {
    return undefined;
  }
  return notCurrencyValue(value);
};

export const notEmptyArray = (arr: unknown[], messageKey: LocalesKey = 'validateNotEmpty') => {
  if (arr === undefined || arr.length === 0) {
    return t(messageKey);
  }
};

export const isNumber = (value: any) => {
  if (typeof value !== 'number' && !value.match(/^[0-9]+$/)) {
    return t('validateIsNumber');
  }
};

export const isInteger = (value: number) => {
  if (value !== Math.round(value)) {
    return t('validateIsInteger');
  }
};

export const isBiggerThanZero = (value: any) => {
  if (!(parseFloat(value) > 0)) {
    return t('validateIsBiggerThanZero');
  }
};

export const isNonNegative = (value: any) => {
  if (!(parseFloat(value) >= 0)) {
    return t('validateNonNegative');
  }
};

export const isNumberInRange = (value: any, min?: number, max?: number) => {
  if (min === undefined) {
    if (max !== undefined && Number(value) > max) {
      return t('notLargerThan', { size: max });
    }

    return undefined;
  }

  if (max === undefined) {
    if (Number(value) < min) {
      return t('notLessThan', { size: min });
    }

    return undefined;
  }

  if (isNaN(value) || Number(value) < min || Number(value) > max) {
    return t('validateNumberBetween', { min, max });
  }
};

export const isUrl = (value: any) => {
  // If the value can be parsed as a URL, it's valid
  try {
    // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
    new URL(value);
    return undefined;
  } catch (err) {
    return t('validateValidUrl');
  }
};

// Email regexp from http://emailregex.com/
const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const notValidEmailAddress = (str: any) => {
  // The regex /^.+@.+\..+$/ is a very crude email address validator, just checking
  // that the address starts with at least one character, followed by an @, followed by at least
  // one character, followed by a dot, followed by at least one character and the end of the string.
  if (!str || str.length === 0 || !str.trim() || !emailRegExp.test(str)) {
    return t('validateValidEmail');
  }
};

export const exists = (value: any, errorMessage = ' ') => {
  if (!value) {
    return errorMessage;
  }
};

export const notEmptyOrBlank = (str?: string) => {
  if (!str || str.length === 0 || /^\s*$/.test(str) || !str.trim()) {
    return t('validateNotEmpty');
  }
};

export const isAfterDate = (date: string, compareDate: DateTime) => {
  const parsedDate = DateTime.fromISO(date);

  if (!parsedDate.isValid) {
    return t('invalidDate');
  }

  if (parsedDate.toMillis() < compareDate.startOf('day').toMillis()) {
    return t('validateNotAfter', { date: compareDate.toISODate() });
  }
};

export const notAfterMaxDate = (date: string, maxDate = MAX_DATE) => {
  const parsedDate = DateTime.fromISO(date);
  const parsedMaxDate = DateTime.fromISO(maxDate);

  if (!parsedDate.isValid) {
    return t('invalidDate');
  }

  if (!parsedMaxDate.isValid) {
    return undefined;
  }

  if (parsedDate.toMillis() > parsedMaxDate.toMillis()) {
    return t('validateAfter', { limit: maxDate });
  }
};

export const isValidTextPhoneNumber = (value: string) => {
  if (!value.match(/^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{3,5}$/)) {
    return t('validateValidPhoneNumber');
  }
};

export const isValidCpoHotlineNumber = (value?: string) => {
  if (!value || value === '') {
    return;
  }
  if (!value.match(/^\+[0-9][0-9\s]{3,}$/)) {
    return t('cpoHotlineValidationError');
  }
};

export const noSpecialCharacters = (value: string) => {
  if (!value.match(/^[a-zA-Z0-9]*$/)) {
    return t('validateNoSpecialCharacters');
  }
};

export const notMatchByPattern = (value: string, pattern: string) => {
  if (!value.match(pattern)) {
    return t('validateNoForbiddenCharacters');
  }
};

export const notInRange = (value = '', max: number, min: number) => {
  if (value.length > max || value.length < min) {
    if (min === 0) {
      return t('notLongerThanCharacters', { max });
    }
    return t('notLongerOrShorterThanCharacters', { min, max });
  }
};

export const exactNumberOfCharacters = (value: string, numberOfCharacters: number) => {
  if (value.length !== numberOfCharacters) {
    return t('validateLengthExactly', { length: numberOfCharacters });
  }
};

export const descriptionFieldValidator = (value: string) => {
  if (value.length > 255) {
    return createMaxLengthValidationMessage(255);
  }
};

export const validateConnectorPower = (power: string) => {
  const powerRegex = /^[0-9]+\.?[0-9]*$/;
  if (!String(power).match(powerRegex)) {
    return t('validateIsNumber');
  }
  return undefined;
};

export const validateExternalIdText = (externalId: string) => {
  const externalIdRegex = /^([A-Z]{1,})\*([A-Z0-9]{3,})$/;
  const extenalIds = externalId.split(';');

  for (const id of extenalIds) {
    if (!String(id.trim()).match(externalIdRegex)) {
      return t('validateExternalId');
    }
  }
};

export const validateConnectorLabel = (label?: string) => {
  const labelRegex = /^[a-zA-Z0-9]{1,2}$/;
  if (label && !label.match(labelRegex)) {
    return t('validateAssetLabel');
  }
};

export const validateOrganizationDomain = (domain?: string) => {
  const labelRegex = /^[a-z0-9-_.]+$/;
  if (domain && !domain.match(labelRegex)) {
    return t('validateOrganizationDomain');
  }
};

export const validateEvseId = (evseId?: string) => {
  const evseIdRegex = /^[A-Za-z0-9*]{1,30}$/;
  if (evseId && !evseId.match(evseIdRegex)) {
    return t('validateEvseId');
  }
};

export const validateHeartbeatInterval = (value?: string) => {
  if (value !== undefined && value !== '') {
    return isBiggerThanZero(value);
  }
};
const validateConnectorAdminStatus = (
  adminStatus: BasicConnector.AdminStatusEnum,
  chargingStationStatus?: ChargePointStatusResponse,
) => {
  switch (adminStatus) {
    case BasicConnector.AdminStatusEnum.OPERATIONAL:
      return chargingStationStatus && chargingStationStatus.online ? undefined : t('chargingStationOfflineError');
  }
};

export const validateConnectors = (
  connectors: ConnectorInformation['connectorInformation']['connectors'],
  hasEvseId: boolean,
  chargingStationStatus?: ChargePointStatusResponse,
) => {
  const { connectorLabels, connectorEvseIds } = connectors.reduce<{
    connectorLabels: Array<string | undefined>;
    connectorEvseIds: Array<string | undefined>;
  }>(
    (obj, connector) => {
      obj.connectorLabels.push(connector.label);
      obj.connectorEvseIds.push(connector.evseId);
      return obj;
    },
    { connectorLabels: [], connectorEvseIds: [] },
  );
  return connectors.map((connector, i) =>
    produce<{ label?: string; evseId?: string; adminStatus?: string }>({}, draft => {
      draft.label = notDuplicate(connector.label, connectorLabels, i) || validateConnectorLabel(connector.label);
      if (hasEvseId) {
        draft.evseId = notDuplicate(connector.evseId, connectorEvseIds, i) || validateEvseId(connector.evseId);
      }
      draft.adminStatus =
        connector.adminStatus && chargingStationStatus
          ? validateConnectorAdminStatus(connector.adminStatus, chargingStationStatus)
          : undefined;
    }),
  );
};

export const validateChargingStationAdminStatus = (
  adminStatus: BasicChargePoint.AdminStatusEnum,
  chargingStationStatus?: ChargePointStatusResponse,
) => {
  const { INOPERATION } = BasicChargePoint.AdminStatusEnum;
  switch (adminStatus) {
    case INOPERATION:
      return chargingStationStatus && chargingStationStatus.online
        ? notEmpty(adminStatus)
        : t('chargingStationOfflineError');
  }
};

export const isValidCognitoPassword = (value: string) => {
  const numbersRegex = /[0-9]/;
  const lowercaseLettersRegex = /[a-z]/;
  const uppercaseLettersRegex = /[A-Z]/;
  const specialCharacters = `^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < ' : ; | _ ~ \``.split(' ');
  const specialCharactersRegex = new RegExp(`[${specialCharacters.map(c => `\\${c}`).join('')}]`);
  const validCharactersRegex = new RegExp(`^[\\w${specialCharacters.map(c => `\\${c}`).join('')}]+$`);

  const minimumLength = 8;
  const maximumLength = 32;

  if (!numbersRegex.test(value)) {
    return t('validateIncludesNumber');
  }

  if (!specialCharactersRegex.test(value)) {
    return t('validateIncludesSpecialCharacters');
  }

  if (!lowercaseLettersRegex.test(value)) {
    return t('validateIncludesLowercase');
  }

  if (!uppercaseLettersRegex.test(value)) {
    return t('validateIncludesUppercase');
  }

  if (!validCharactersRegex.test(value)) {
    return t('validateInvalidCharacters');
  }

  if (value.length < minimumLength) {
    return createMinLengthValidationMessage(minimumLength);
  }

  if (value.length > maximumLength) {
    return createMaxLengthValidationMessage(maximumLength);
  }
};

export const isValidVatNumber = (value: any) => {
  if (value === undefined || isEmptyString(value)) {
    return undefined;
  }
  if (/[A-Z]{2}[A-Za-z0-9]+/.test(value)) {
    return undefined;
  }
  return 'invalidVatNumber';
};
