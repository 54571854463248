import { cdmApi as api } from 'redux/slices/cdmApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    createPriceProfileUsingPost: build.mutation<
      CreatePriceProfileUsingPostApiResponse,
      CreatePriceProfileUsingPostApiArg
    >({
      query: queryArg => ({ url: `/v4/prices`, method: 'POST', body: queryArg.profileCreateRequest }),
    }),
    updateDescriptionUsingPut: build.mutation<UpdateDescriptionUsingPutApiResponse, UpdateDescriptionUsingPutApiArg>({
      query: queryArg => ({ url: `/v4/prices`, method: 'PUT', body: queryArg.profileUpdateRequest }),
    }),
    decommissionUsingPut: build.mutation<DecommissionUsingPutApiResponse, DecommissionUsingPutApiArg>({
      query: queryArg => ({
        url: `/v4/prices/decommission/${queryArg.organizationId}/${queryArg.profileId}`,
        method: 'PUT',
      }),
    }),
    getByOwnerOrgIdUsingPost: build.query<GetByOwnerOrgIdUsingPostApiResponse, GetByOwnerOrgIdUsingPostApiArg>({
      query: queryArg => ({
        url: `/v4/prices/page/${queryArg.ownerId}`,
        method: 'POST',
        body: queryArg.priceRequestFilters,
        params: {
          size: queryArg.size,
          fetchPrevious: queryArg.fetchPrevious,
          includeRoamingProviders: queryArg.includeRoamingProviders,
          includeDirectAgreementCpos: queryArg.includeDirectAgreementCpos,
          sortField: queryArg.sortField,
          sortFieldCursor: queryArg.sortFieldCursor,
          sortFieldSortOrder: queryArg.sortFieldSortOrder,
          idField: queryArg.idField,
          idFieldCursor: queryArg.idFieldCursor,
          idFieldSortOrder: queryArg.idFieldSortOrder,
        },
      }),
    }),
    getByIdUsingGet2: build.query<GetByIdUsingGet2ApiResponse, GetByIdUsingGet2ApiArg>({
      query: queryArg => ({ url: `/v4/prices/profile/${queryArg.profileId}` }),
    }),
    getSimplifiedPriceProfileUsingPost: build.query<
      GetSimplifiedPriceProfileUsingPostApiResponse,
      GetSimplifiedPriceProfileUsingPostApiArg
    >({
      query: queryArg => ({
        url: `/v4/prices/profile/${queryArg.profileId}/simplified`,
        method: 'POST',
        body: queryArg.profileSimplificationRequest,
      }),
    }),
    deleteUsingDelete: build.mutation<DeleteUsingDeleteApiResponse, DeleteUsingDeleteApiArg>({
      query: queryArg => ({ url: `/v4/prices/${queryArg.organizationId}/${queryArg.profileId}`, method: 'DELETE' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as v4PricesApi };
export type CreatePriceProfileUsingPostApiResponse = /** status 200 OK */ PriceProfileIdResponse;
export type CreatePriceProfileUsingPostApiArg = {
  /** request */
  profileCreateRequest: ProfileCreateRequest;
};
export type UpdateDescriptionUsingPutApiResponse = /** status 200 OK */ void;
export type UpdateDescriptionUsingPutApiArg = {
  /** request */
  profileUpdateRequest: ProfileUpdateRequest;
};
export type DecommissionUsingPutApiResponse = /** status 200 OK */ void;
export type DecommissionUsingPutApiArg = {
  /** organizationId */
  organizationId: string;
  /** profileId */
  profileId: string;
};
export type GetByOwnerOrgIdUsingPostApiResponse = /** status 200 OK */ PageResponsePriceResponse;
export type GetByOwnerOrgIdUsingPostApiArg = {
  /** ownerId */
  ownerId: string;
  /** size */
  size?: number;
  /** fetchPrevious */
  fetchPrevious?: boolean;
  /** includeRoamingProviders */
  includeRoamingProviders?: boolean;
  /** includeDirectAgreementCpos */
  includeDirectAgreementCpos?: boolean;
  sortField?: string;
  sortFieldCursor?: object;
  sortFieldSortOrder?: string;
  idField?: string;
  idFieldCursor?: string;
  idFieldSortOrder?: string;
  /** filters */
  priceRequestFilters: PriceRequestFilters;
};
export type GetByIdUsingGet2ApiResponse = /** status 200 OK */ PriceResponse;
export type GetByIdUsingGet2ApiArg = {
  /** profileId */
  profileId: string;
};
export type GetSimplifiedPriceProfileUsingPostApiResponse = /** status 200 OK */ PriceResponse;
export type GetSimplifiedPriceProfileUsingPostApiArg = {
  /** profileId */
  profileId: string;
  /** profileSimplificationRequest */
  profileSimplificationRequest: ProfileSimplificationRequest;
};
export type DeleteUsingDeleteApiResponse = /** status 200 OK */ void;
export type DeleteUsingDeleteApiArg = {
  /** organizationId */
  organizationId: string;
  /** profileId */
  profileId: string;
};
export type PriceProfileIdResponse = {
  id: string;
};
export type FieldConstraintViolation = {
  errorKey?:
    | 'MUST_BE_HIGHER'
    | 'MUST_BE_LOWER'
    | 'MUST_BE_NEGATIVE'
    | 'MUST_BE_NEGATIVE_OR_ZERO'
    | 'MUST_BE_POSITIVE'
    | 'MUST_BE_POSITIVE_OR_ZERO'
    | 'MUST_BE_UNIQUE'
    | 'MUST_BE_VALID'
    | 'MUST_BE_WITHIN_RANGE'
    | 'MUST_NOT_BE_EMPTY'
    | 'MUST_NOT_BE_NULL'
    | 'MUST_NOT_BE_NULL_OR_EMPTY'
    | 'MUST_NOT_BE_ZERO';
  field?: string;
  message?: string;
  rejectedValue?: object;
};
export type ErrorResponse = {
  code?: number;
  details?: string[];
  fieldViolations?: FieldConstraintViolation[];
  message?: string;
  status?: string;
  statusCode?: number;
};
export type GraceRuleConfiguration = {
  duration: number;
  energy: number;
  operator: 'AND' | 'OR';
};
export type Price = {
  excludingVat: number;
  includingVat: number;
};
export type ProfileCreateRequest = {
  organizationId: string;
  name: string;
  content: string;
  currency?: string;
  description: string;
  taxation?: number;
  cap?: number;
  graceRuleConfiguration?: GraceRuleConfiguration;
  includingVat: boolean;
  empId: string;
  minPrice: Price;
  maxPrice: Price;
};
export type ProfileUpdateRequest = {
  organizationId: string;
  profileId: string;
  description: string;
};
export type PriceComponent = {
  type: 'ENERGY' | 'TIME' | 'PARKING_TIME' | 'FLAT' | 'MARKUP';
  price: number | string;
  step_size?: number;
};
export type Restrictions = {
  start_time?: string;
  end_time?: string;
  start_date?: string;
  end_date?: string;
  day_of_week?: ('MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY')[];
  min_duration?: number;
  max_duration?: number;
  min_kwh?: number;
  max_kwh?: number;
};
export type Segment = {
  price_components: PriceComponent[];
  restrictions?: Restrictions;
};
export type PriceProfileContent = {
  segments: Segment[];
};
export type PriceResponse = {
  cap?: number;
  content?: PriceProfileContent;
  createdAt: number;
  currency: string;
  description: string;
  graceRule?: GraceRuleConfiguration;
  id: string;
  includingVat: boolean;
  name: string;
  organizationId: string;
  status?: 'DECOMMISSIONED' | 'UNUSED';
  vat?: number;
};
export type AbstractPagingCursor = {
  idField: string;
  idFieldCursor?: object;
  idFieldSortOrder: string;
  sortField: string;
  sortFieldCursor?: object;
  sortFieldSortOrder: string;
};
export type Pagination = {
  next?: (any | null) | AbstractPagingCursor;
  prev?: (any | null) | AbstractPagingCursor;
};
export type PageResponsePriceResponse = {
  content: PriceResponse[];
  pagination: Pagination;
  totalItems: number;
};
export type PriceRequestFilters = {
  name?: string;
  currency?: string;
  status?: 'ACTIVE' | 'ACTIVE_UNUSED' | 'DECOMMISSIONED';
  type?: 'REGULAR' | 'MARKUP';
};
export type ProfileSimplificationRequest = {
  atZonedDateTime?: string;
  maxElectricPower?: number;
  roamingProviderId?: string;
};
export const {
  useCreatePriceProfileUsingPostMutation,
  useUpdateDescriptionUsingPutMutation,
  useDecommissionUsingPutMutation,
  useGetByOwnerOrgIdUsingPostQuery,
  useGetByIdUsingGet2Query,
  useGetSimplifiedPriceProfileUsingPostQuery,
  useDeleteUsingDeleteMutation,
} = injectedRtkApi;
