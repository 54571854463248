export default {
  "ABSOLUTE": "Date & Time based",
  "ACTIVE": "Active",
  "AC_1_PHASE": "AC - 1 PHASE",
  "AC_3_PHASE": "AC - 3 PHASE",
  "ADD_CONNECTOR_GROUPS": "Add connector groups",
  "ADD_PUBLISHING_CHANNELS": "Add publishing channels",
  "ADHOC": "Ad hoc",
  "ADMINISTRATOR": "Administrator",
  "ADMIN_CANCELLATION": "Operator canceled",
  "AGL_ENERGY": "AGL Energy",
  "ALIZE_MAP": "Alize Map",
  "ALL": "All",
  "ALL_EXTERNAL": "All external",
  "ALWAYS": "Always",
  "AMOUNT": "Amount",
  "ANNUAL": "Annually",
  "AO": "Asset Owner",
  "APPROVED": "Approved",
  "AT": "Austria",
  "AU": "Australia",
  "AUS": "Australia",
  "AUT": "Austria",
  "AUTHORIZATION": "Authorization",
  "AWAITING_REPAIR": "Awaiting Repair",
  "Available": "Available",
  "B2B": "B2B",
  "B2BConfiguration": "B2B settings",
  "B2B_MANAGER": "B2b manager",
  "BE": "Belgium",
  "BEL": "Belgium",
  "BE_CHARGE": "BE Charge",
  "BG": "Bulgaria",
  "BGR": "Bulgaria",
  "BILLING": "Billing",
  "BONNET": "Bonnet",
  "BOTH": "All",
  "CA": "Canada",
  "CAN": "Canada",
  "CANCELED": "Canceled",
  "CANCEL_RESERVATION": "Cancel Reservation",
  "CAR": "Car",
  "CCS": "CCS",
  "CENTRAL_SYSTEM": "Plugsurfing Power Platform",
  "CH": "Switzerland",
  "CHADEMO": "CHAdeMO",
  "CHADEMO_AND_CCS": "CHAdeMO and CCS",
  "CHARGER_MANUFACTURER": "Charger manufacturer",
  "CHARGE_AND_DRIVE": "Charge & Drive",
  "CHARGE_AND_DRIVE_ADHOC": "Ad-Hoc",
  "CHARGE_AND_DRIVE_CONNECT_API": "White Label App & Marketplace",
  "CHARGE_AND_DRIVE_MAP": "Charge & Drive Map",
  "CHARGE_POINT": "Charging Station",
  "CHARGE_POINT_SITE_OWNER": "Charge point site owner",
  "CHARGING_NETWORK": "Charging Network",
  "CHE": "Switzerland",
  "CHL": "Chile",
  "CKRFilePreviewDescription": "You can upload only one CSV file per one import. If you want to upload new file you need to delete previous one.",
  "CKRYourCSVFile": "Your CSV file",
  "CL": "Chile",
  "CLEAR_AUTHORIZATION_CACHE": "Clear Authorization Cache",
  "CLEAR_AUTHORIZATION_LIST": "Clear Authorization List",
  "CLOSED": "Closed",
  "CONNECTOR_GROUP_BASED": "Based on connector group",
  "CONNECTOR_OFF": "Connector Off",
  "CONNECTOR_ON": "Connector On",
  "CPO": "Charging Station Operator",
  "CREATED": "Created",
  "CREDIT_CARD": "Credit Card",
  "CSV_HEADERS": "CSV",
  "CUSTOMER_SERVICE": "Customer service",
  "CUSTOMER_SERVICE_LITE": "Customer Service Lite",
  "CZ": "Czech Republic",
  "CZE": "Czech Republic",
  "CardOrdersExport": "Export Charging Key Orders as CSV",
  "CardOrdersExportDescription": "Export the card orders of the organisation's to a CSV-file that you can open in a spreadsheet application like Excel. Click \"Email CSV File\" to get an email sent to you.",
  "CardOrdersExportError": "Unable to export charging key orders",
  "ChargerStatusColumn": "Charger",
  "Charging": "Charging",
  "ChargingKeyOrdersExport": "Export Charging Key Orders as CSV",
  "ChargingKeyOrdersExportDescription": "Export the charging key orders of the organisation's to a CSV-file that you can open in a spreadsheet application like Excel. Click \"Email CSV File\" to get an email sent to you.",
  "ChargingKeyOrdersExportError": "Unable to export charging key orders",
  "ConnectorLockFailure": "Failure to lock or unlock connector",
  "DAILY": "Daily",
  "DAYS": "Days",
  "DC": "DC",
  "DCS": "Digital Charging Solutions",
  "DE": "Germany",
  "DECOMMISSIONED": "Decommissioned",
  "DELIVERED": "Delivered",
  "DEU": "Germany",
  "DISABLED": "Disabled",
  "DK": "Denmark",
  "DNK": "Denmark",
  "DOMESTIC_A": "NEMA 1-15, 2 pins",
  "DOMESTIC_B": "NEMA 5-15, 3 pins",
  "DOMESTIC_C": "CEE 7/17, 2 pins",
  "DOMESTIC_D": "Standard 3 pin",
  "DOMESTIC_E": "CEE 7/5 3 pins",
  "DOMESTIC_F": "CEE 7/4, Schuko, 3 pins",
  "DOMESTIC_G": "BS 1363, Commonwealth, 3 pins",
  "DOMESTIC_H": "SI-32, 3 pins",
  "DOMESTIC_I": "AS 3112, 3 pins",
  "DOMESTIC_J": "SEV 1011, 3 pins",
  "DOMESTIC_K": "DS 60884-2-D1, 3 pins",
  "DOMESTIC_L": "CEI 23-16-VII, 3 pins",
  "DRIVE_API_MANAGER": "Drive API manager",
  "DRIVE_API_USER": "Drive API user",
  "EASY_PARK": "Easy Park",
  "ECO_MOVEMENT": "ECO Movement",
  "EE": "Estonia",
  "ELWIN": "Enoro",
  "EMAIL": "Email",
  "EMP": "E-Mobility Service Provider",
  "EMPARK": "Empark",
  "ENDED": "Ended",
  "ENEL": "Enel",
  "ENEL_X": "Enel X",
  "ERROR": "Error",
  "ES": "Spain",
  "ESMART": "E-Smart",
  "ESP": "Spain",
  "EST": "Estonia",
  "EVCommunicationError": "Communication failure with the vehicle, might be Mode 3 or other communication protocol problem. This is not a real error in the sense that the Charge Point doesn’t need to go to the faulted state. Instead, it should go to the SuspendedEVSE state.",
  "EXTERNAL": "External",
  "EXTERNAL_CANCELLATION": "Externally canceled",
  "EXTERNAL_CPO": "External Charging Station Operator",
  "EXTERNAL_EMP": "External E-Mobility Service Provider",
  "ExternalPrivateEvseVisibleInApp": "EVSE is not visible in the app",
  "ExternalPrivateLocationVisibleInApp": "Location is not visible in the app",
  "ExternalPublicEvseVisibleInApp": "EVSE is visible in the app",
  "ExternalPublicLocationVisibleInApp": "Location is visible in the app",
  "FAILED": "Failed",
  "FI": "Finland",
  "FIN": "Finland",
  "FINANCE_ADMIN": "Finance Admin",
  "FINANCE_ANALYST": "Finance analyst",
  "FINISHED": "Finished",
  "FIRST_REAL_CUSTOM_ROLE": "First real custom role",
  "FLEET": "Fleet",
  "FLEET_CK_RESERVATIONS": "Fleet Charging key reserver",
  "FORTUM_INDIA": "DISABLED",
  "FORTUM_SHOWROOM": "Gireve - OCPI",
  "FORTUM_SPRING": "Fortum Spring",
  "FORTUM_SPRING_SMART_CHARGING": "Fortum Spring Smart Charging",
  "FR": "France",
  "FRA": "France",
  "FRIDAY": "Friday",
  "Faulted": "Faulted",
  "Finishing": "Finishing",
  "FleetChargingSessionsExportDescription": "Export the Fleet's charging sessions to a CSV-file that you can open in a spreadsheet application like Excel. Choose a time period and click \"Email CSV File\" to get an email sent to you.",
  "G20_CA01_1_WIRELESS": "G20 CA01 1 Wireless",
  "GB": "United Kingdom",
  "GBR": "United Kingdom",
  "GBT": "GB/T",
  "GE": "Georgia",
  "GENERAL_MANAGER": "General manager",
  "GET_CONFIGURATION": "Get Configuration",
  "GET_DIAGNOSTICS": "Get Diagnostics",
  "GG": "Guernsey",
  "GGY": "Guernsey",
  "GI": "Gibraltar",
  "GIB": "Gibraltar",
  "GIREVE": "Gireve",
  "GREENCHARGE": "GreenCharge EU",
  "GroundFailure": "Ground fault circuit interrupter has been activated.",
  "H": "h",
  "HARD_RESET": "Hard Reset",
  "HAS_TO_BE": "Has To Be",
  "HAYDON_ME": "Haydonme",
  "HIGH": "High",
  "HOURS": "Hours",
  "HR": "Croatia",
  "HRV": "Croatia",
  "HU": "Hungary",
  "HUBJECT": "Hubject",
  "HUN": "Hungary",
  "HighTemperature": "Temperature inside charge point is too high.",
  "ICCID": "ICCid",
  "IE": "Ireland",
  "IEC_60309_2_SINGLE_16": "Industrial Single Phase 16 Amp",
  "IEC_60309_2_THREE_16": "Industrial Three Phase 16 Amp",
  "IEC_60309_2_THREE_32": "Industrial Three Phase 32 Amp",
  "IEC_60309_2_THREE_64": "Industrial Three Phase 64 Amp",
  "IEC_62196_T1": "Type 1 \"SAE J1772\"",
  "IEC_62196_T1_COMBO": "Combo Type 1 based, DC",
  "IEC_62196_T2": "IEC 62196 Type 2 \"Mennekes\"",
  "IEC_62196_T2_COMBO": "Combo Type 2 based, DC",
  "IEC_62196_T3C": "IEC 62196 Type 3C",
  "IN": "India",
  "INACTIVE": "Inactive",
  "INCOMING": "Incoming",
  "IND": "India",
  "INSTALLED": "Installed",
  "INTERNAL": "P3 (Internal)",
  "INVOICE": "Invoice",
  "IN_OPERATION": "In Operation",
  "IN_TESTING": "In Testing",
  "IN_WAREHOUSE": "In Warehouse",
  "IRL": "Ireland",
  "IS": "Iceland",
  "ISL": "Iceland",
  "ISSUES_FOUND": "Issues found",
  "IT": "Italy",
  "ITA": "Italy",
  "InternalError": "Error in internal hard- or software component.",
  "InvoiceExportError": "Unable to export invoices",
  "InvoiceItemsExport": "Export Invoice Items as CSV",
  "InvoiceItemsExportDescription": "Export the items of the organisation's invoices to a CSV-file that you can open in a spreadsheet application like Excel. Click \"Email CSV File\" to get an email sent to you.",
  "InvoicesArchiveExport": "Export Invoices By Date Interval as PDF",
  "InvoicesArchiveExportDescription": "Export the organisation's invoices to a PDF-file. Choose a time period and click \"Email PDF File\" to get an email sent to you.",
  "InvoicesExport": "Export All Invoices as CSV",
  "InvoicesExportArchiveDescription": "Export the organization's invoices to a PDF-file. Choose a time period and click \"Email PDF File\" to get an email sent to you.",
  "InvoicesExportDescription": "Export all of the the organisation's invoices to a CSV-file that you can open in a spreadsheet application like Excel. Click \"Email CSV File\" to get an email sent to you.",
  "JP": "Japan",
  "KEY_CARD": "Key card",
  "KEY_TAG": "Key tag",
  "KOPLE_MAP": "Kople Map",
  "KWH": "kWh",
  "LANDSCAPE": "Landscape",
  "LI": "Liechtenstein",
  "LIE": "Liechtenstein",
  "LINK_MOBILITY": "Link Mobility",
  "LO": "Location Owner",
  "LOW": "Low",
  "LT": "Lithuania",
  "LTU": "Lithuania",
  "LU": "Luxembourg",
  "LUX": "Luxembourg",
  "LV": "Latvia",
  "LVA": "Latvia",
  "LocalListConflict": "The authorization information received from the Central System is in conflict with the LocalAuthorizationList.",
  "MARUTI": "Maruti",
  "MEDIUM": "Medium",
  "MFR": "Manufacturer",
  "MIN": "min",
  "MINUTES": "Minutes",
  "MODE1": "Mode 1",
  "MODE2": "Mode 2",
  "MODE3": "Mode 3",
  "MODE4": "Mode 4",
  "MONDAY": "Monday",
  "MONTH": "Month",
  "MONTHLY": "Monthly",
  "MONTHS": "Months",
  "MOTORCYCLE": "Motorcycle",
  "MOVE_MOBILITY": "MOVE Mobility",
  "Mode3Error": "Problem with Mode 3 connection to vehicle.",
  "NAF": "NAF",
  "NEF": "NEF",
  "NETWORK_MANAGER": "Network manager",
  "NEVER": "Never",
  "NEW": "New",
  "NEWMOTION": "New Motion",
  "NEXTGREENCAR": "Next Green Car",
  "NIO": " ",
  "NL": "The Netherlands",
  "NLD": "Netherlands",
  "NO": "Norway",
  "NOBIL": "Nobil",
  "NOR": "Norway",
  "NOTSUPPORTED": "Unlock connector not supported",
  "NOT_MAPPABLE": "-",
  "NOT_SUPPORTED_YET": "Not supported yet",
  "NoError": "No error to report.",
  "NoTermsAndConditionsFile": "No terms and conditions added",
  "OCPP_SOAP_1_5": "OCPP SOAP 1.5",
  "OCPP_SOAP_1_6": "OCPP SOAP 1.6",
  "OCPP_WEB_SOCKET_1_6": "OCPP WebSocket 1.6",
  "ONE_TIME_FEE": "One time fee",
  "OPEN": "Open",
  "OPERATIONAL": "Operational",
  "OPERATIONS_MANAGER": "Operations manager",
  "ORDERED": "Ordered",
  "ORDER_FREE_OF_CHARGE": "Free of charge",
  "ORDER_PAID": "Order paid",
  "ORDER_PAYED": "Order paid",
  "ORDER_PAYED_WITHOUT_RECEIPT": "Order payed without receipt",
  "OTHER": "Other",
  "OUTGOING": "Outgoing",
  "OUT_OF_ORDER": "Out of Order",
  "OWNER_CANCELLATION": "Customer canceled",
  "OWN_CHARGERS": "Own chargers",
  "Occupied": "Occupied",
  "OtherError": "Other type of error. More information in vendorErrorCode.",
  "OverCurrentFailure": "Over current protection device has tripped.",
  "OverVoltage": "Voltage has risen above an acceptable level.",
  "PAYMENT": "Payment",
  "PAYMENT_FAILURE": "Payment failure",
  "PENDING": "Pending",
  "PENDING_ACTIVE": "Pending & Active",
  "PENDING_DELETE": "Pending Delete",
  "PERCENTAGE": "Percentage",
  "PERSONAL_DATA_MANAGER": "Personal data manager",
  "PL": "Poland",
  "PLATFORM_ADMINISTRATOR": "Platform administrator",
  "PLUGSURFING": "Plugsurfing",
  "POL": "Poland",
  "PORTRAIT": "Portrait",
  "PREPAID": "Prepaid",
  "PROCESSED": "Processed",
  "PRODUCT_ORDER_MANAGER": "Product order manager",
  "PRT": "Portugal",
  "PT": "Portugal",
  "PUBLIC": "Public",
  "PUBLISHING_CHANNEL_001": "Charge4Go",
  "PUBLISHING_CHANNEL_002": "Farol",
  "PUBLISHING_CHANNEL_003": "Easypark_OCPI",
  "PUBLISHING_CHANNEL_004": "Monta",
  "PUBLISHING_CHANNEL_005": "JAMP",
  "PUBLISHING_CHANNEL_006": "Allstar_OCPI",
  "PUBLISHING_CHANNEL_007": "DGPark",
  "PUBLISHING_CHANNEL_008": "Kempower",
  "PUBLISHING_CHANNEL_009": "Elvah",
  "PUBLISHING_CHANNEL_010": "Porsche_OCPI",
  "PUBLISHING_CHANNEL_011": "Bosch",
  "PUBLISHING_CHANNEL_012": "Greenflux",
  "PUBLISHING_CHANNEL_013": "Deftpower",
  "PUBLISHING_CHANNEL_014": "Elli",
  "PUBLISHING_CHANNEL_015": "LeasePlan",
  "PUBLISHING_CHANNEL_016": "LAKD",
  "PUBLISHING_CHANNEL_017": "unassigned",
  "PUBLISHING_CHANNEL_018": "unassigned",
  "PUBLISHING_CHANNEL_019": "unassigned",
  "PUBLISHING_CHANNEL_020": "unassigned",
  "PUBLISHING_CHANNEL_021": "unassigned",
  "PUBLISHING_CHANNEL_022": "unassigned",
  "PUBLISHING_CHANNEL_023": "unassigned",
  "PUBLISHING_CHANNEL_024": "unassigned",
  "PUBLISHING_CHANNEL_025": "unassigned",
  "PUBLISHING_CHANNEL_026": "unassigned",
  "PUBLISHING_CHANNEL_027": "unassigned",
  "PUBLISHING_CHANNEL_028": "unassigned",
  "PUBLISHING_CHANNEL_029": "unassigned",
  "PUBLISHING_CHANNEL_030": "unassigned",
  "PUBLISHING_CHANNEL_031": "unassigned",
  "PUBLISHING_CHANNEL_032": "unassigned",
  "PUBLISHING_CHANNEL_033": "unassigned",
  "PUBLISHING_CHANNEL_034": "unassigned",
  "PUBLISHING_CHANNEL_035": "unassigned",
  "PUBLISHING_CHANNEL_036": "unassigned",
  "PUBLISHING_CHANNEL_037": "unassigned",
  "PUBLISHING_CHANNEL_038": "unassigned",
  "PUBLISHING_CHANNEL_039": "unassigned",
  "PUBLISHING_CHANNEL_040": "unassigned",
  "PUBLISHING_CHANNEL_041": "unassigned",
  "PUBLISHING_CHANNEL_042": "unassigned",
  "PUBLISHING_CHANNEL_043": "unassigned",
  "PUBLISHING_CHANNEL_044": "unassigned",
  "PUBLISHING_CHANNEL_045": "unassigned",
  "PUBLISHING_CHANNEL_046": "unassigned",
  "PUBLISHING_CHANNEL_047": "unassigned",
  "PUBLISHING_CHANNEL_048": "unassigned",
  "PUBLISHING_CHANNEL_049": "unassigned",
  "PUBLISHING_CHANNEL_050": "unassigned",
  "PUBLISHING_CHANNEL_051": "unassigned",
  "PUBLISHING_CHANNEL_052": "unassigned",
  "PUBLISHING_CHANNEL_053": "unassigned",
  "PUBLISHING_CHANNEL_054": "unassigned",
  "PUBLISHING_CHANNEL_055": "unassigned",
  "PUBLISHING_CHANNEL_056": "unassigned",
  "PUBLISHING_CHANNEL_057": "unassigned",
  "PUBLISHING_CHANNEL_058": "unassigned",
  "PUBLISHING_CHANNEL_059": "unassigned",
  "PUBLISHING_CHANNEL_060": "unassigned",
  "PowerMeterFailure": "Failure to read power meter.",
  "PowerSwitchFailure": "Failure to control power switch.",
  "PrepaidBalancesExport": "Export Prepaid Balances as CSV",
  "PrepaidBalancesExportDescription": "Export prepaid balances of the organisation to a CSV-file that you can open in a spreadsheet application like Excel. Click “Email CSV File” to get an email sent to you.",
  "Preparing": "Preparing",
  "PrivateChargerVisibleInApp": "Private Location",
  "PublicChargerVisibleInApp": "Public Location",
  "QUARTERLY": "Quarterly",
  "QUEUED_TO_OEM": "Queued to OEM",
  "RECEIPT": "Receipt",
  "RECEIVED": "Received",
  "RECHARGE_MAP": "Recharge Map",
  "REFUNDED": "Refunded",
  "REFUNDFAILED": "Refund failed",
  "REFUNDPENDING": "Refund pending",
  "REFUND_FAILED": "Refund failed",
  "REFUND_PENDING": "Refund pending",
  "REGENERATING": "Regenerating",
  "REJECTED": "Rejected",
  "REJECTED_BY_OEM": "Rejected by OEM",
  "RELATIVE": "Duration based",
  "REMOTE_START_CHARGING": "Remote start charging",
  "REMOTE_START_STOP_CAPABLE": "Remote start capable",
  "REMOTE_STOP_CHARGING": "Remote stop charging",
  "REMOVE_CONNECTOR_GROUPS": "Remove connector groups",
  "REMOVE_PUBLISHING_CHANNELS": "Remove publishing channels",
  "REPLACE_CONNECTOR_GROUPS": "Replace connector groups",
  "REPLACE_PUBLISHING_CHANNELS": "Replace publishing channels",
  "RESENT_START_CHARGING": "Resent start charging",
  "RESERVABLE": "Reservable",
  "RESERVE_NOW": "Reserve now",
  "RESET": "Reset",
  "RETIRED": "Retired",
  "REVIEWED": "Reviewed",
  "REVOKED": "Revoked",
  "RO": "Romania",
  "ROAMING_MANAGER": "Roaming manager",
  "ROU": "Romania",
  "RS": "Serbia",
  "RUNNING": "Running",
  "ReaderFailure": "Failure with ID tag reader.",
  "Reserved": "Reserved",
  "ResetFailure": "Unable to perform a reset.",
  "SA": "Saudi Arabia",
  "SATURDAY": "Saturday",
  "SAU": "Saudi Arabia",
  "SCHUKO": "Schuko",
  "SE": "Sweden",
  "SECONDS": "Seconds",
  "SEND_AUTHORIZATION_LIST": "Send Authorization List",
  "SENT_TO_OEM": "Sent to OEM",
  "SEPA": "SEPA",
  "SEPA_CREDIT_TRANSFER": "SEPA credit transfer",
  "SEPA_DEBIT": "SEPA debit",
  "SESSION": "Session start date",
  "SI": "Slovenia",
  "SK": "Slovakia",
  "SMS": "SMS",
  "SOFT_RESET": "Soft Reset",
  "SQUARE": "Square",
  "SRB": "Serbia",
  "ST1": "ST1",
  "START_CHARGING": "Start charging",
  "START_REQUEST_TIMEOUT": "Start request timeout",
  "STOCKHOLM_EXERGI": "Stockholm Exergi",
  "STOP_CHARGING": "Stop charging",
  "STRIPE": "Stripe",
  "STRIPE_BILLING_CREDIT_CARD": "Stripe billing credit card",
  "STRIPE_BILLING_SEPA": "Stripe billing sepa",
  "STRIPE_BILLING_SEPA_CREDIT_TRANSFER": "Stripe billing sepa credit transfer",
  "STRIPE_CREDIT_CARD": "Stripe credit card",
  "STRIPE_SEPA": "Stripe sepa",
  "SUBSCRIPTION_EXPIRED": "Expired",
  "SUNDAY": "Sunday",
  "SUPERVISOR": "Supervisor",
  "SUSPICIOUS": "Suspicious",
  "SVK": "Slovakia",
  "SVN": "Slovenia",
  "SWE": "Sweden",
  "SuspendedEV": "Suspended EV",
  "SuspendedEVSE": "Suspended EVSE",
  "TERMINATED": "Terminated",
  "TESLA_R": "Tesla \"Roadster\"-type (round, 4 pin)",
  "TESLA_S": "Tesla \"Model-S\"-type (oval, 5 pin)",
  "TESTING_OK": "Testing OK",
  "TEST_CHANNEL": "Test Channel",
  "THREE_WHEELER": "Three wheeler",
  "THURSDAY": "Thursday",
  "TR": "Turkey",
  "TRANSACTION": "Transaction date",
  "TUESDAY": "Tuesday",
  "TYPE1": "Type 1",
  "TYPE1_CABLE": "Type 1 Cable",
  "TYPE2": "Type 2",
  "TYPE2_AND_SCHUKO": "Type 2 and Schuko",
  "TYPE2_CABLE": "Type 2 Cable",
  "TYPE3A": "Type 3A",
  "TYPE3A_AND_TYPE2": "Type 3A and Type 2",
  "TYPE3C": "Type 3C",
  "TYPE3C_AND_SCHUKO": "Type 3C and Schuko",
  "TYPE3C_AND_TYPE2": "Type 3C and Type 2",
  "UNKNOWN": "Unknown",
  "UNLOCKED": "Unlock command sent to the charger",
  "UNLOCKFAILED": "Failed to unlock connector",
  "UNLOCK_CONNECTOR": "Unlock Connector",
  "UNUSED": "Unused",
  "UPDATE_FIRMWARE": "Update Firmware",
  "Unavailable": "Unavailable",
  "UnderVoltage": "Voltage has dropped below an acceptable level.",
  "Unknown": "Unknown",
  "VG": "VG",
  "WATTS_UP": "Watts up",
  "WEDNESDAY": "Wednesday",
  "WEEKLY": "Weekly",
  "WEEKS": "Weeks",
  "WH": "Wh",
  "WRITE_OFF": "Write off",
  "WeakSignal": "Wireless communication device reports a weak signal.",
  "YEARS": "Years",
  "ZA": "South Africa",
  "ZET": "Zet",
  "aboutCkInFile": "About the keys in the file",
  "aboutThisLocation": "About this location",
  "absolute": "Absolute",
  "absoluteTrialEndDate": "Grace period end date",
  "acceptedFileTypes": "Accepted file types are {{accept}}",
  "accessControlPanelButton": "Access Control Panel",
  "accessDenied": "Access denied",
  "accessibility": "Accessibility",
  "accessibilityApp": "Start from App",
  "accountDetails": "Account Details",
  "accountNumber": "Account number",
  "accountStatus": "Account Status",
  "action": "Action",
  "actionNotRevertable": "You cannot undo this action",
  "actions": "Actions",
  "activatedByUser": "Activated",
  "activationDate": "Activation Date",
  "active": "Active",
  "activeCountries": "Active countries",
  "activeFrom": "Active from",
  "activeFromBeforeTo": "Active from must be before active to",
  "activeTo": "Active to",
  "activityPeriod": "Activity period",
  "adHocCharging": "Ad hoc charging",
  "adHocChargingToolTip": "Charging for unregistered users with card payments",
  "adHocUpdateError": "Failed to update ad hoc configuration",
  "adHocUpdateSuccess": "Successfully updated ad hoc configuration",
  "add": "Add",
  "addApiKey": "Add API key",
  "addAssociation": "Add association",
  "addBillingAddress": "Add billing address",
  "addBillingPaymentAccount": "Add periodic payment",
  "addBulkAssociation": "Add bulk association",
  "addCPOError": "There was an error to add a CPO",
  "addCPOExistingConnection": "Add CPO via existing connection",
  "addCPOSuccess": "CPO successfully added",
  "addChargingSessionFees": "Add charging session fees",
  "addConfiguration": "Add Configuration",
  "addConnectionRestriction": "Add connection restriction",
  "addConnector": "Add Connector",
  "addContact": "Add Contact",
  "addCountriesAndPrices": "Add countries and prices",
  "addCountry": "Add country",
  "addCpo": "Add CPO",
  "addCpoRestriction": "Add CPO restriction",
  "addCreditReason": "Add reason",
  "addCustomerGroupDescription": "Provide additional information about the customer group here",
  "addCustomerGroupName": "Add a name for a new group",
  "addDiscount": "Add discount",
  "addDiscountChooseCPOmessage": "Choose a CPO above to see the discounts",
  "addDiscountConditions": "Add discount conditions",
  "addDiscountMessage": "Add the negotiated discount on this space",
  "addDurationRestriction": "Add duration restriction",
  "addEmp": "Add EMP",
  "addEmpRoamingPriceAssociation": "Add EMP Roaming Price Association",
  "addHeader": "Add header",
  "addHubjectIntegrations": "Add CPO to Hubject Integrations",
  "addIntegration": "Add Integration",
  "addLanguage": "Add language",
  "addLicensePlate": "Add License Plate",
  "addMapping": "Add mapping",
  "addMarketplaceProvider": "Add provider",
  "addMarketplaceReceiver": "Add receiver",
  "addModel": "Add Model",
  "addMonthlyFee": "Add monthly fee",
  "addMonthlyFees": "Add monthly fees",
  "addOneTimeFee": "Add one time fee",
  "addOpeningHours": "Add Opening hours",
  "addOperatorMapping": "Add Operator Mapping",
  "addOperatorMappingError": "There was an error to add an Operator Mapping",
  "addOperatorMappingSuccess": "Operator Mapping was successfully added",
  "addOwner": "Add owner",
  "addPrepaidAmount": "Add PrePaid Amount",
  "addPriceAssociation": "Add price association",
  "addPriceProfileAssociation": "Add price profile association",
  "addProduct": "Add product",
  "addRelation": "Add relation",
  "addRestriction": "Add Restriction",
  "addRestrictionSuccess": "Successfully added network restriction",
  "addSquishVin_duplicated_values": "One or more of the Squish VIN numbers seem to be duplicated: {{duplicated_values}}",
  "addSquishVin_invalid_config": "One or more of the Squish VIN numbers does not seem to be valid: {{invalid_values}}",
  "addSquishVin_label": "All accepted Squish VIN numbers, separated by comma (,)",
  "addSquishVin_title": "VIN restriction",
  "addSubCPO": "Add Sub CPO",
  "addSubCPOError": "There was an error to add a subCPO",
  "addSubCPOSuccess": "subCPO successfully added",
  "addSupportedCountries": "Add supported countries",
  "addToBlackList": "Add CPO to disable list",
  "addToBlackListError": "Failed to add CPO to disable list",
  "addToBlackListSuccess": "Remote start has been successfully disabled for this CPO.",
  "addTranslations": "Add translations",
  "addTrigger": "Add Trigger",
  "addUnitPrice": "Add unit price",
  "addValidToDate": "Add custom “valid to” date",
  "addWebhook": "Add webhook",
  "additionalCurrency": "Additional currency",
  "additionalLanguages": "Additional languages",
  "additionalOptions": "Additional Options",
  "additionalOwners": "Additional owners",
  "address": "Address",
  "addressLine1": "Line 1",
  "addressLine2": "Address line 2",
  "adhocJobValidationError": "Adhoc Jobs are only allowed for CDR data type",
  "admin": "Admin",
  "advancedFilters": "Advanced Filters",
  "advancedSearchPlaceholder": "Search by name or external Id",
  "aggregated": "Aggregated",
  "aggregatedConnectorStatus": "Aggregated Connectors Status",
  "aggregatedConnectorsStatusTooltip": "The dashboard is only showing connectors belonging to chargers that are in Operational status In Operation OR Awaiting Repair\n\nDefinition of Available:\n- Network status Online AND Connector Operational status Operational AND Connector OCPP status (Available or Preparing or Finishing or Reserved or Unknown)\n\nDefinition of Charging:\n- Connector OCPP status Charging or SuspendedEVSE or SuspendedEV or Occupied\n\nDefinition of Needs attention:\n- Network status Offline OR Connector Operational status Out of Order OR Connector OCPP status (Unavailable or Faulted)",
  "aggregatedStatus": "Aggregated Status",
  "agreementNoAdded": "No agreement added",
  "agreementPublishInfo": "When publishing this article all customers need to approve once again.",
  "agreementSaveOrCancel": "Save or cancel edit of the agreement first.",
  "agreements": "Agreements",
  "all": "All",
  "allAdhocCustomers": "All ad-hoc customers",
  "allCountries": "All countries",
  "allDay": "All day",
  "allEMPs": "All EMPs",
  "allEndCustomers": "All end-customers",
  "allExternal": "All external",
  "allOptionsMessage": "Use Any instead of all power ranges",
  "allTokensWarning": "This operation might involve large number of tokens be pushed. Please make sure CPO is aligned before the push is initiated to avoid overloading CPO system.",
  "allowUnknownLocations": "Unknown Locations Allowed",
  "allowUnknownLocations_tooltip": "If enabled, charging is allowed on unknown locations that the CPO has not shared with us over OCPI. The default value is true.",
  "allowedContractIdPrefixes": "Allowed prefixes",
  "allowedMemberDurationInDays": "Customer stay time",
  "allowedMemberDurationTooltip": "Select here a duration to determine the period of time that customers will stay in this group. Customers will automatically be removed from the group when the selected period expires.",
  "allowedOCPIVersions": "Allowed OCPI Versions",
  "alwaysApplied": "Always applied",
  "amount": "Amount",
  "amountWithCurrency": "Amount ({{currency}})",
  "and": "and",
  "andMore": "<0>{{label}}</0><1> and </1><2>{{count}} more</2>",
  "any": "Any",
  "aoConfiguration": "AO Configuration",
  "aoId": "AO ID",
  "apiKeyConfiguration": "API key configuration",
  "apiKeyConfigurationUpdateSuccess": "Successfully updated API key configuration",
  "apiKeyCreateSuccess": "Successfully created an API key",
  "apiKeyGenerateError": "Failed to generate new API key",
  "apiKeyGenerateForConfirm": "Generate new API key for {{entity}}? This will invalidate the current key: {{key}}",
  "apiKeyGenerateSuccess": "New API key generated",
  "apiKeyName": "API key name",
  "apiKeys": "API keys",
  "apiLogs": "API logs",
  "apiRequests": "API requests",
  "app": "App",
  "appConfigUpdatedError": "An error occurred when updating the app settings.",
  "appConfigUpdatedSuccess": "App settings updated!",
  "appConfiguration": "App settings",
  "appliedTo": "Applied to",
  "apply": "Apply",
  "approveAgreement": "Activate agreement",
  "approveAgreementConfirm": "Are you sure you want to Activate? This will start displaying locations to the drivers.",
  "approveMarketplaceConnectionError": "Failed to approve the agreement",
  "approveMarketplaceConnectionSuccess": "Agreement has been approved ",
  "approvedAfter": "Approved From",
  "approvedAt": "Approved at",
  "approvedBefore": "Approved Till",
  "approvedPrivacyNotice": "Approved Privacy Notice",
  "archiveExportError": "Could not export archive file",
  "assetLabel": "Label",
  "assetLabelExactMatch": "Label (exact match)",
  "assetOwner": "Asset owner",
  "assetOwnerIsPayee": "Asset Owner is payee",
  "assetOwnerIsPayeeExternalCpoInfo": "Asset owner is never payee for external CPOs",
  "assetOwnerIsPayeeExternalOrgInfo": "EMP is always payee for external organization",
  "assetsInOtherCDApps": "Connected CPO assets shown in the app",
  "assetsIntegratedTroughConnectionId": "Assets integrated through Connection ID:",
  "assetsIntegratedTroughConnectionId_plural": "Assets integrated through Connection IDs:",
  "assignKey": "Assign Key",
  "assigned": "Assigned",
  "assigning": "Assigning",
  "associatedCpoFetchError": "Failed to fetch associated CPO's",
  "associatedFirmwares": "Associated Firmwares",
  "associatedModels": "Associated Models",
  "associatedModelsUpdatedError": "Failed to update associated models",
  "associatedModelsUpdatedSuccess": "Successfully updated associated models",
  "associationFilters_associationType-ADHOC": "Ad-hoc association",
  "associationFilters_associationType-EMP": "EMP association",
  "associationFilters_associationType-EXTERNALCPO": "Incoming CPO association",
  "associationFilters_associationType-EXTERNAL_CPO": "Incoming CPO association",
  "associationFilters_associationType-INTERNALCPO": "CPO association",
  "associationFilters_associationType-INTERNAL_CPO": "CPO association",
  "associationFilters_country": "Country",
  "associationFilters_cpo": "CPO",
  "associationFilters_currentType": "Current type",
  "associationFilters_currentType-AC": "AC",
  "associationFilters_currentType-DC": "DC",
  "associationFilters_customerGroup": "Customer group",
  "associationFilters_emp": "EMP",
  "associationFilters_externalConnectors": "External connectors:",
  "associationFilters_internalConnectorGroup": "Internal connector group",
  "associationFilters_priceProfile": "Price profiles",
  "associationFilters_tariffId": "Tariff ID",
  "associationFilters_type": "Type",
  "associationFilters_validAt": "Valid at",
  "associationProvider": "Provider",
  "associationReceiver": "Receiver",
  "associationTerminateFailed": "Failed to terminate association",
  "associationTerminateSuccess": "Association was successfully terminated",
  "associationType": "Association type",
  "associationType-ADHOC": "ADHOC",
  "associationType-CPO": "CPO",
  "associationType-EMP": "EMP",
  "associationType-EXTERNAL_CPO": "OUTGOING",
  "associationType-INTERNAL_CPO": "INCOMING",
  "associationType-UNKNOWN": "UNKNOWN",
  "associationType_tooltip-ADHOC": "AdHoc association",
  "associationType_tooltip-EMP": "EMP association",
  "associationType_tooltip-EXTERNAL_CPO": "Incoming CPO association",
  "associationType_tooltip-INTERNAL_CPO": "CPO association",
  "authAttemptedAt": "Auth attempted at",
  "authErrorMessage": "We're sorry for the interruption. Our team is constantly working to resolve this problem as soon as possible. Please try again later.",
  "authorization": "Authorization",
  "authorizationError": "Authorization error",
  "authorizationInfoDetails": "Authorization info details",
  "authorizationMethod": "Authorization method",
  "authorizationReference": "Authorization reference",
  "authorized": "Authorized",
  "auto": "Auto",
  "autoGenerated": "Auto-generated",
  "autoPriceDescriptionInfo": "Automatic price summary is a beta feature. Please be mindful of that and use “Manual” if the price isn’t described as you please.",
  "autoSameAsPrice": "Auto - Same as Price summary",
  "automaticallyCreate": "Automatically create",
  "availability": "Availability",
  "availabilityPeriod": "Availability period",
  "available": "Available",
  "availableConnectorsAggregated": "Available",
  "availableRevenueElements": "Available revenue elements",
  "averageUptimeCountDaily": "{{uptime}}% on avg. for the last {{count}} days",
  "averageUtilisation": "average utilization",
  "avgPower": "Avg power",
  "b2bCustomer": "B2B Customer",
  "back": "Back",
  "backToCkPage": "Back to Charging Key page",
  "backToHome": "Back to home",
  "balance": "Balance",
  "bankAccount": "Bank Account",
  "bankAccountCountry": "Bank account country",
  "bankAccountCurrency": "Bank account currency",
  "bankAccountNumber": "Bank account number",
  "bankName": "Bank name",
  "bankSepaPayment": "Bank (SEPA payment)",
  "bankTransfer": "Bank transfer",
  "bankTransferAddError": "Unable to add bank transfer",
  "bankTransferAddSuccess": "Successfully added bank transfer",
  "bankTransferEditWarnOnPositiveBalance": "There is balance remaining. The account will be changed and all money accumulated on it will be lost forever. Do you confirm the actions?",
  "bankTransferFetchBalanceError": "An error occurred while fetching the bank transfer balance.",
  "bankTransferRemoveError": "Unable to remove bank transfer",
  "bankTransferRemoveSuccess": "Successfully removed bank transfer",
  "bankTransferRemoveWarnOnPositiveBalance": "There is balance remaining. The account will be deleted and all money accumulated on it will be lost forever. Do you confirm the actions?",
  "base64AuthorizationHeader_tooltip": "If enabled, the authorization header is base 64 encoded once sent to the partner. The default value for OCPI 2.1.1 is false, and the default value for OCPI 2.2 is true. ",
  "basedOnCpoPrice": "Based on CPO price",
  "batchName": "Batch Name",
  "batchNameDescription": "Should be the name of your file",
  "batchSize": "Batch size",
  "beCarefulThisActionCannotBeUndone": "Be careful! This action cannot be undone!",
  "bic": "BIC",
  "billing": "Billing",
  "billingAddError": "Failed to add billing account!",
  "billingAddSettings": "Add billing settings",
  "billingAddSuccess": "Successfully added billing account",
  "billingAddress": "Billing Address",
  "billingAddressAdd": "Add billing address",
  "billingAddressDeleteError": "Unable to remove billing address",
  "billingAddressDeleteSuccess": "Successfully removed billing address",
  "billingAddressRequired": "In order to register for invoice, the organization must have a billing address.",
  "billingAddressUpdateError": "Unable to update billing address",
  "billingAddressUpdateSuccess": "Successfully updated billing address",
  "billingDayOfInvoice": "Day of invoice",
  "billingDayOfInvoiceInfo": "Choose day of the month when the invoice will be sent to the customer",
  "billingDaysUntilDue": "Days until due",
  "billingDaysUntilDueInfo": "Time from when the invoice is sent until the money is drawn from the customer",
  "billingDeleteConfirm": "Are you sure you want to delete the selected billing account?",
  "billingDeleteError": "Failed to delete the selected billing account. {{error}}",
  "billingDeleteSuccess": "Successfully deleted billing account",
  "billingEnabledError": "Unable to save billing settings",
  "billingEnabledSuccess": "Successfully saved billing settings",
  "billingInformation": "Billing Information",
  "billingPeriod": "Billing period",
  "billingPlan": "Billing plan",
  "billingPlanName": "Settings name",
  "billingPlanUpdateError": "Unable to update billing plan",
  "billingPlanUpdateSuccess": "Successfully updated billing plan",
  "billingStatementDescriptorField": "Référence de facturation",
  "billingThresholdInfo": "When the sum is reached by the customer, they will be billed",
  "billingUpdateError": "Failed to update billing account!",
  "billingUpdateSuccess": "Successfully updated billing account",
  "blockInvoice": "Block Invoice",
  "blocked": "Blocked",
  "body": "Body",
  "botenAnna": "BotenAnna",
  "branding": "Branding",
  "brandingConfigFetchError": "An error occurred while fetching the branding configuration.",
  "brandingLogoRemoveError": "Failed to remove logotype",
  "brandingLogoRemoveSuccess": "Successfully removed logotype",
  "brandingLogoUploadError": "Failed to upload logotype",
  "brandingLogoUploadSuccess": "Successfully uploaded logotype",
  "brandingString": "Web portal Branding string",
  "bulkAction": "Bulk Action",
  "bulkActionError": "Unfortunately we couldn’t {{action}} the charging key(s)",
  "bulkActionPartialSuccess": "We could only {{action}} {{count}} out of {{total}} charging key(s)",
  "bulkActionSuccess": "Successfully {{action}} {{count}} charging key(s)",
  "bulkAssociations": "Bulk associations",
  "bulkAssociationsError": "Failed to fetch associations",
  "bulkRetryCdrError": "There was an error trying to reprocess the CDRs",
  "bulkRetryCdrSuccess": "CDRs have been successfully submitted for retrying",
  "businessTaxID": "Business tax ID",
  "busy": "Busy",
  "calculateChargingSessions": "Calculate charging sessions",
  "calculatingLocationUtilisation": "Old calculation detected. Preparing new utilization calculation.",
  "calculationType": "Calculation type",
  "campaign": "Campaign",
  "campaign-status-ACTIVE": "Active",
  "campaign-status-EXPIRED": "Expired",
  "campaignCreate": "Create new campaign",
  "campaignCreatedError": "Failed to create campaign",
  "campaignCreatedSuccess": "Successfully created campaign",
  "campaignExportCreditsInfo": "Export all credits associated with this campaign",
  "campaignExportInfo": "Export all codes associated with this campaign - shows all codes in the campaign and which are REDEEMED and which are still possible to claim",
  "campaignFetchError": "Failed to fetch campaign",
  "campaignStatusDropdownLabel": "Campaign status",
  "campaignTerminateError": "Failed to terminate campaign",
  "campaignTerminateSuccess": "Campaign successfully terminated",
  "campaignType": "Type of Campaign",
  "campaignUpdateError": "An error occurred while updating the campaign",
  "campaignUpdatedSuccessfully": "Campaign has been successfully updated!",
  "campaigns": "Campaigns",
  "campaignsActiveGetError": "Failed to get active campaigns",
  "cancel": "Cancel",
  "cancelOrder": "Cancel Order",
  "cancelOrders": "Cancel Orders",
  "cancelOrganizationOrder": "Cancel Order",
  "cancelOrganizationOrderConfirmation": "Do you want to cancel the order?",
  "cancelReservation": "Stop",
  "cancelSubscription": "Cancel subscription",
  "cancelUpdateCredentials": "Cancel Update",
  "canceled": "Canceled",
  "canceledOrders": "Canceled Orders",
  "cancellationPolicyLink": "Cancellation Policy URL",
  "cannotAddBankTransferUser": "Periodic payment cannot be added. Check if user is from the Euro zone and user organisation has a billing plan in Euro and user organisation accepts Billing SEPA credit transfer",
  "cannotDeleteCredit": "Cannot remove this credit",
  "cannotDeleteOneTimeFee": "Cannot remove this fee",
  "cannotRemoveSupportedCountryRemoveReservationFirst": "Country {{country}} can not be removed because credit card reservation is active. Please remove it, and try again",
  "cap": "Maximum cost per session",
  "capabilities": "Capabilities",
  "capability": "Capability",
  "capturedTransactions": "Captured Transactions",
  "carModel": "Car model",
  "card": "Card",
  "cardBrand": "Card Brand",
  "cardNumber": "Card Number",
  "cardType": "Card type",
  "card_reservation_failed": "The reservation on your card was declined. Please verify that your payment method is valid and that there are funds available on the card.",
  "carryOutCommandFailed": "Failed to carry out command",
  "cdmc": "P3 (Internal)",
  "cdrFaild": "CDR Retry failed",
  "cdrId": "CDR ID",
  "cdrImports": "CDR Imports",
  "cdrPayload": "CDR payload",
  "cdrPrice": "CDR price",
  "cdrRetried": "CDR will be retried. Please wait a minute and then refresh the page to see the updated status.",
  "certified": "Certified",
  "cet": "Central European Time (CET)",
  "changeLocationTypeError": "Failed to made Location {{type}}",
  "changeLocationTypeSuccess": "Successfully made Location {{type}}",
  "changePassword": "Save new password",
  "changeVersionError": "Failed to changed version",
  "changeVersionSuccess": "Successfully changed version",
  "channel": "Channel",
  "chargePointNetworkStatus": "Network status",
  "chargePointOcppStatus": "OCPP status and Network status",
  "chargePointOperationalStatus": "Operational status",
  "chargePointStatus": "Charger status",
  "chargePointStatusInfo": "Network status on charger level (Online/ Offline)\n\nOperational status on charger level (editable from backoffice)\nOperational status on connector level (editable from backoffice)\n\nCharger status on charger level (reported by the charger)\nCharger status on connector level (reported by the charger)\n\nAggregated status is combining all statuses and is what will be shown in the new version of the app.",
  "chargePointStatusInfoHeader": "Status information",
  "chargerCertified": "This charger is certified by Fortum",
  "chargerQuickConnect": "This charger is QuickConnect-ready",
  "chargerTimeIssue": "Charger time issue",
  "chargerTimeIssueTooltip": "Number of sessions in which the charger's internal time is not set correctly and does not match with our system time.",
  "chargerUptime": "Charger Uptime",
  "chargerUtilisation": "Charger Utilization",
  "charger_is_damaged": "Charger is damaged",
  "charger_is_hard_to_find": "Charger is hard to find",
  "charger_is_not_available": "Charger is not available",
  "chargers": "Chargers",
  "chargersReportingError": "Chargers reporting this error",
  "chargersStatusWidgetTooltip": "The dashboard is only showing chargers that are in Operational status In Operation OR Awaiting Repair\n\nDefinition of Offline:\n- No messages from charging station received during 2.5 times the heartbeat interval (eg heartbeat interval = 240s --> marked as Offline if no messages received in 10 minutes)",
  "chargingAuthResults": "Charging Auth Results",
  "chargingAuthentication": "Plug&Charge",
  "chargingAvailableWhenClosed": "Charging available outside opening hours",
  "chargingConnectorsAggregated": "Charging",
  "chargingDuration": "Charging duration",
  "chargingKey": "Charging Key",
  "chargingKeyActionMessage": "This action will affect the charging key immediately.",
  "chargingKeyCardTitle": "About the key",
  "chargingKeyConfigUpdatedError": "Failed to update Charging Key Configuration",
  "chargingKeyConfigUpdatedSuccess": "Charging Key Configuration was updated",
  "chargingKeyConfiguration": "Charging Key Configuration",
  "chargingKeyCreate": "Create charging key",
  "chargingKeyCreateError": "Something went wrong when trying to create the key.",
  "chargingKeyCreateSuccess": "Successfully created Charging Key",
  "chargingKeyDebtSummary": "Outstanding Amount",
  "chargingKeyDebtSummaryClearError": "Could not clear debt at this time",
  "chargingKeyDebtSummaryClearInfo": "Please note that it might take up to 1 minute for the debt to be cleared",
  "chargingKeyDebtSummaryClearInfoText": "Please confirm your action by typing 'CLEAR'",
  "chargingKeyDebtSummaryClearLabel": "Clear Outstanding Amount",
  "chargingKeyDebtSummaryClearUndoInfoText": "Clearing outstanding amount",
  "chargingKeyDelete": "Delete Charging Key",
  "chargingKeyDeleteError": "Failed to delete charging key",
  "chargingKeyDeleteMessage": "If you want to permanently delete this charging key you can activate the process here. You can not undo this action.",
  "chargingKeyDeleteSuccess": "Successfully deleted Charging Key",
  "chargingKeyDeleteUndoInfoText": "Deleting Charging Key",
  "chargingKeyDetails": "Charging Key General Information",
  "chargingKeyDisableError": "There was a system error, the key was not disabled.",
  "chargingKeyDisableSuccess": "The charging key was disabled.",
  "chargingKeyEditDisableText": "Editing is enabled, exclusively, when the charging key has not been used in any charging session.",
  "chargingKeyEnableError": "There was a system error, the key was not enabled.",
  "chargingKeyEnableSuccess": "The charging key was enabled.",
  "chargingKeyLogCardTitle": "Key log",
  "chargingKeyName": "Charging key name",
  "chargingKeyOrder": "Order {{id}}",
  "chargingKeyOrderAssignError": "Something went wrong when trying to assign the key order.",
  "chargingKeyOrderAssignSuccess": "The key was assigned successfully",
  "chargingKeyOrderCancelError": "Something went wrong when trying to assign the key order.",
  "chargingKeyOrderCanceled": "The key order was cancelled.",
  "chargingKeyOrderConfirmBulkError": "Error occurred during confirming selected charging keys",
  "chargingKeyOrderConfirmError": "Something went wrong when trying to confirm the key order.",
  "chargingKeyOrderConfirmSuccess": "Charging key order confirmed",
  "chargingKeyOrderConfirmedBulk": "Successfully confirmed selected keys",
  "chargingKeyOrderProducts": "{{quantity}} x {{productType}}",
  "chargingKeyOrders": "Charging Key Orders",
  "chargingKeyOwner": "Charging key owner",
  "chargingKeyOwnerCardTitle": "About the owner",
  "chargingKeyPrepaid": "Prepaid Charging Key",
  "chargingKeyReservations": "Charging Key Reservations",
  "chargingKeyReservationsSubtitle": "Use this page to see the list of reserved keys and bulk upload of charging keys reservations.",
  "chargingKeySessions": "Charging Key Sessions",
  "chargingKeyType": "Charging Key Type",
  "chargingKeyUpdateError": "Failed to update the charging key",
  "chargingKeyUpdateSuccess": "Charging key successfully updated",
  "chargingKeys": "Charging Keys",
  "chargingKeysCreate": "Create charging keys",
  "chargingKeysUpdatedError": "Could not update the following keys:",
  "chargingKeysUpdatedSuccess": "Successfully updated the following keys:",
  "chargingSessionFees": "Charging session fees",
  "chargingSessions": "Charging Sessions",
  "chargingSessionsExport": "Export Charging Sessions",
  "chargingSessionsExportDescription": "Export the {{csvExportType}}'s charging sessions to a CSV-file that you can open in a spreadsheet application like Excel. Choose a time period and click \"Email CSV File\" to get an email sent to you.",
  "chargingSessionsExportError": "Unable to export charging sessions",
  "chargingSessionsFor": "Charging Sessions for {{name}}",
  "chargingSessionsSummaryExport": "Export Charging Sessions summary",
  "chargingSessionsSummaryExportDescription": "Export the summary of organization's charging sessions to a CSV-file that you can open in a spreadsheet application like Excel. Choose a time period and click \"Email CSV File\" to get an email sent to you.",
  "chargingSessionsSummaryExportError": "Unable to export charging sessions summary",
  "chargingSessionsTransactions": "Charging Sessions/Transactions",
  "chargingStation": "Charging Station",
  "chargingStationAdd": "Add Charging Station",
  "chargingStationAddError": "Failed to add Charging station",
  "chargingStationAddFieldMissingError": "Failed to add Charging Station. Some fields are missing.",
  "chargingStationAddSuccess": "Successfully added Charging station",
  "chargingStationConnectorGroupsWarning": "Warning! All current connector groups will be deleted on the Charging Station.",
  "chargingStationData": "Charging Station Data",
  "chargingStationDelete": "Delete Charging Station",
  "chargingStationDeleteConfirm": "Do you want to delete this Charging Station?",
  "chargingStationDeleteError": "Failed to delete Charging station",
  "chargingStationDeleteOnLocationInfo": "This Location still has Charging Stations. Delete or move them before deleting the Location.",
  "chargingStationDeleteSuccess": "Succesfully deleted Charging Station",
  "chargingStationDetails": "Charging Station Details",
  "chargingStationDynamicIp": "Dynamic IP",
  "chargingStationId": "Charging Station ID",
  "chargingStationIdInfo": "Charging Station ID ({#} represents the generated running number)",
  "chargingStationIdPrefix": "Charging Station ID (Prefix)",
  "chargingStationInstallationTime": "Installation Time",
  "chargingStationIpSetting": "IP Setting",
  "chargingStationMasterSettingsUpdateError": "Error when updating master for: {{assetName}}",
  "chargingStationMasterSettingsUpdateSuccess": "Successfully updated master for: {{assetName}}",
  "chargingStationModel": "Model",
  "chargingStationModelCreateError": "Failed to create model",
  "chargingStationModelCreateSuccess": "Successfully created model",
  "chargingStationModelUpdateError": "Failed to update model",
  "chargingStationModelUpdateSuccess": "Model successfully updated",
  "chargingStationName": "Charging Station Name",
  "chargingStationNameInfo": "Charging Station Name ({#} represents the generated running number)",
  "chargingStationNameLabelInfo": "Charging Station Name (Label will be added to the name)",
  "chargingStationNamePrefix": "Charging Station Name (Prefix)",
  "chargingStationNoLongerAvailable": "This Charging Station is no longer available for you",
  "chargingStationNoneSelected": "No Charging Stations selected!",
  "chargingStationOfflineError": "Status can not be changed since the charger is offline and an Availability command can not be sent to the charger. Status can be updated when charger is Online again.",
  "chargingStationPath": "Path",
  "chargingStationPort": "Port",
  "chargingStationPrefix": "Charging Station Name (Prefix)",
  "chargingStationProtocol": "Protocol",
  "chargingStationProtocolHttp": "HTTP",
  "chargingStationProtocolHttps": "HTTPS",
  "chargingStationPublishingChannelsAndConnectorGroupsWarning": "Warning! All current publishing channels and connector groups will be deleted on the Charging Station.",
  "chargingStationPublishingChannelsWarning": "Warning! All current publishing channels will be deleted on the Charging Station.",
  "chargingStationReservable": "Hardware model supports reservation",
  "chargingStationSendRequestError": "Unable to send request to Charging Station",
  "chargingStationSendRequestSuccess": "Request successfully sent to Charging Station",
  "chargingStationStaticIp": "Static IP",
  "chargingStationStatus": "Charging Station Status",
  "chargingStationTemplate": "Charging Station Template",
  "chargingStationTemplateCreate": "Add Charging Station Template",
  "chargingStationTemplateCreatedSuccess": "Charging Station Template created.",
  "chargingStationTemplateDeleteConfirm": "Are you sure you want to delete the template?",
  "chargingStationTemplateDeleteSuccess": "Charging Station Template deleted.",
  "chargingStationTemplateUpdate": "Update Charging Station Template",
  "chargingStationTemplateUpdatedSuccess": "Charging Station Template updated.",
  "chargingStationTemplates": "Charging Station Templates",
  "chargingStationUpdateError": "Failed to update information on {{assetName}}",
  "chargingStationUpdateSuccess": "Updated information on {{assetName}}",
  "chargingStationUrl": "Charging Station URL",
  "chargingStationUrlMissingError": "URL to charger not set in General Information.",
  "chargingStations": "Charging Stations",
  "chargingStationsAddMultiple": "Add Multiple Charging Stations",
  "chargingStationsAddSuccess": "Successfully added {{count}} Charging Stations!",
  "chargingStationsConnected": "Connected Charging Stations",
  "chargingStationsCreate": "Create Charging Stations",
  "chargingStationsCreateInstructionHeading": "Please follow these steps to create many charging stations from a CSV file",
  "chargingStationsCreateInstructionStep1": "Download the latest version of the CSV template here:",
  "chargingStationsCreateInstructionStep2": "Fill out the CSV template with your data, <0>please see the hints below</0>",
  "chargingStationsCreateInstructionStep3": "Upload your CSV file (You can add up to 2k charge points at once)",
  "chargingStationsCreated": "{{count}} charging stations successfully created.",
  "chargingStationsDeleteConfirm": "Are you sure you want to delete the selected Charging Stations?",
  "chargingStationsDeleteSuccess": "Successfully deleted {{count}} Charging stations! Please note that you might need to refresh to see the changes.",
  "chargingStationsEditMultiple": "Edit Multiple Charging Stations",
  "chargingStationsFetchAfterSynchronizedSettings": "Failed to fetch updated Charging Station after synchronized settings",
  "chargingStationsFetchError": "Could not fetch Charging Stations",
  "chargingStationsNotCreatedDueToInvalidData": "{{count}} charging stations could not be created due to incorrect data",
  "chargingStationsNotCreatedDueToInvalidDataDescription": "Please validate and edit the data in these rows and upload your file again.\nIn case you can not find the problem, please create a support ticket for assistance.",
  "chargingStationsUpdate": "Update Charging Stations",
  "chargingStationsUpdateError": "Failed to update Charging Stations!",
  "chargingStationsUpdateSuccess": "Successfully updated {{count}} Charging Stations!",
  "chargingStationsUploadMultiple": "Create charging stations from a CSV file",
  "chargingTimestamp": "Charging timestamp",
  "charging_session_did_not_start": "Charging session did not start",
  "charging_session_interrupted": "Charging session interrupted",
  "chatSupportLink": "Support chatbot",
  "checkKeyReservation": "Check reservation database",
  "chooseANameForChargingKey": "Choose a name for the charging key",
  "chooseCPOConnectedViaIntegration": "Choose a CPO connected via this integration",
  "chooseConnectiontype": "Choose connection type",
  "chooseExternalCPOPartnerConnectedMarketplace": "Choose a partner - Must be an external CPO you are connected to in Marketplace",
  "chooseFile": "Choose a file to upload",
  "chooseOrganisation": "Choose an organisation",
  "choosePartner": "Choose a partner",
  "choosePartnerIntegrate": "Choose which partner to integrate with",
  "chooseTheKeyType": "Choose the key type",
  "chooseTheNumberFormat": "Choose the number format",
  "chooseUserGroups": "Choose groups of customers",
  "city": "City",
  "ckFileExampleDescription": "We have added new fields to the CSV file, please follow the example below.",
  "ckImportFileInstructions": "The input file should contain four columns separated by comma or semicolon: \n\nThe first column is the key name and it is mandatory, The second mandatory column stands for the key identifier provided in a format selected in the metadata, The third column is optional and should contain the reference id which represents the exact key number physically presented on the key, The forth column is also optional and it is for license plate. \n\nHeaders should be ordered by: name, serialNumber, referenceId, licensePlate.",
  "ckKeyNamePlaceholder": "Choose the key name prefix",
  "ckKeyNumberFormatPlaceholder": "Choose the number format",
  "ckKeyTypePlaceholder": "Choose the key type",
  "ckLastDisabled": "Last disabled on",
  "ckLastEnabled": "Last enabled on",
  "ckToBeUploaded": "Keys to be uploaded",
  "ckUploadNewBatch": "Upload another batch of keys",
  "ckUploadSuccess": "Upload results",
  "ckUploaded": "Keys uploaded",
  "ckWithIssues": "Keys with issues",
  "ckrConfirmImport": "Confirm import",
  "ckrCustomerGroupDescription": "Depends on the selected organization and must be created before reserving keys",
  "ckrEnterOrganizationDescription": "Enter the organisation or fleet name",
  "ckrFileExample": "Example:",
  "ckrFileExampleDescription": "We recommend you to look at the example CSV file.",
  "ckrFileExampleName": "CSV file",
  "ckrImportFileInstructions": "The input file should contain only two columns separated by comma or semicolon. The first column is mandatory and stands for the key identifier provided in a format selected in the metadata. The second column is optional and should contain the reference id which represents the exact key number physically presented on the key. No spaces, headers or column descriptors should be included in the file.",
  "ckrImportFormBatchNamePlaceholder": "whole import qualifier",
  "ckrImportFormKeyNamePlaceholder": "key name prefix",
  "ckrKeyNumberFormat": "Key number format",
  "ckrKeyNumberFormatInfo": "Key number formats are different from each other. Always ask your key manufacturer before uploading keys. Choosing one format over the other makes a difference if key activation by the driver will be successful or not even if the upload was successful.  ",
  "ckrKeyType": "Key type",
  "ckrKeysCount": "Keys in the file",
  "ckr_selected_org_is_not_emp_or_fleet": "The selected organization needs to be an EMP or fleet",
  "clearAuthorizationList": "Clear Authorization List",
  "clearCache": "Clear Cache",
  "clearFilter": "Clear Filter",
  "clickToCopy": "Click to copy",
  "clickToRefresh": "Click to refresh",
  "clipboardEVSEID": "Copy EVSE ID",
  "clipboardError": "Error to copy!",
  "clipboardSuccess": "Successfully copied!",
  "close": "Close",
  "code": "Code",
  "codeType": "Code type",
  "collapseSidebar": "Collapse sidebar",
  "colorBackground": "Background",
  "colorError": "Error",
  "colorLinks": "Links",
  "colorOnBackground": "On background",
  "colorOnError": "On error",
  "colorOnPrimary": "On primary",
  "colorOnSecondary": "On secondary",
  "colorOnSurface": "On surface",
  "colorPrimary": "Primary color",
  "colorPrimaryInfo": "Color Primary Info",
  "colorPrimaryVariant": "Primary variant",
  "colorReset": "Reset to default colors",
  "colorResetError": "Unable to reset to default colors",
  "colorResetSuccess": "Successfully reset to default colors",
  "colorSecondary": "Secondary color",
  "colorSecondaryInfo": "Color Secondary Info",
  "colorSecondaryVariant": "Secondary variant",
  "colorSuccess": "Success",
  "colorSurface": "Surface",
  "colorUpdateError": "Unable to update colors",
  "colorUpdateSuccess": "Successfully updated colors",
  "colorWarning": "Warning",
  "colorbackground": "Background",
  "colorerror": "Error",
  "colorlinks": "Links",
  "coloronBackground": "On background",
  "coloronError": "On error",
  "coloronPrimary": "On primary",
  "coloronSecondary": "On secondary",
  "coloronSurface": "On surface",
  "colorprimary": "Primary color",
  "colorprimaryInfo": "Color Primary Info",
  "colorprimaryVariant": "Primary variant",
  "colors": "Colors",
  "colorsecondary": "Secondary color",
  "colorsecondaryVariant": "Secondary variant",
  "colorsuccess": "Success",
  "colorsurface": "Surface",
  "colorwarning": "Warning",
  "commandAccepted": "Command Accepted",
  "commands": "Commands",
  "comment": "Comment",
  "commercial": "Commercial",
  "companyAddress": "Company address",
  "companyInformation": "Company information",
  "companyName": "Company name",
  "compare_to_number": "Number",
  "compare_to_other_currency": "Currency",
  "compare_to_other_price": "Relative to price",
  "compare_to_string": "String",
  "completeNow": "Complete now",
  "completeWithoutAssigning": "Complete without assigning",
  "completed": "Completed",
  "completedOrders": "Completed Orders",
  "compteTypeField": "Type de compte",
  "condition": "Condition",
  "condition_connector_format": "Connector format comparison",
  "condition_connector_type": "Connector type comparison",
  "condition_country_code": "Country comparison",
  "condition_cpo": "CPO comparison",
  "condition_cpo_emp_id": "CPO/EMP comparison",
  "condition_cpo_id": "CPO comparison",
  "condition_currency": "Currency comparison",
  "condition_date": "Date comparison",
  "condition_emp_id": "EMP comparison",
  "condition_energy": "Energy comparison",
  "condition_power_type": "Power type comparison",
  "condition_price": "Price comparison",
  "condition_tariff_id": "Tariff ID comparison",
  "condition_time_skew": "Time skew comparison",
  "conditions": "Conditions",
  "configVersion": "Configuration version",
  "configuration": "Configuration",
  "configurationKeys": "Configuration Keys",
  "configurationKeysOutOfSyncError": "Please synchronize configuration before any updates.",
  "configurationName": "Configuration Name",
  "configurationRemove": "Remove configuration",
  "confirm": "Confirm",
  "confirmAddExternalPaymentAccount": "Are you sure you want to add external billing? This will remove all current user payment methods.",
  "confirmCancelCampaign": "Do you really want to cancel {{ campaignName }} campaign?",
  "confirmCkrInportTitle": "Please confirm your import",
  "confirmCreateKeysButton": "Confirm creation of keys",
  "confirmCreateKeysTitle": "Please confirm your creation of keys",
  "confirmDeletion": "Confirm deletion",
  "confirmEndSubscription": "Are you sure you want to end the subscription?",
  "confirmKeyCreation": "Confirm key creation",
  "confirmMessageDeleteDiscount": "Deleting the discount will remove all previous discount calculations. Are you sure you want to delete or just remove (which ends the calculations but keeps existing)?",
  "confirmMessageDeleteEvse": "EVSE including connectors will be deleted. You cannot undo this action, but you will still be able to view the EVSE data.",
  "confirmMessageDeletePullJob": "Are you sure? The pull job will be deleted and some functions may no longer work for this integration.",
  "confirmMessageResetPullJob": "Are you sure? This will pull the data from the beginning of time.",
  "confirmMessageResethandshake": "Are you sure? The handshake details will be reset for this integration.",
  "confirmMessageRetryCDR": "We will try to process this CDR again.",
  "confirmMessageUpdatePrivacy": "This will impact the charging station visibility immediately.",
  "confirmPassword": "Confirm Password",
  "confirmResetColors": "Are you sure you want to reset colors?",
  "confirmTitleDeleteEvse": "Delete EVSE",
  "confirmTitleDeletePullJob": "Delete Pull Job",
  "confirmTitleResetHandshake": "Reset Handshake",
  "confirmTitleResetPullJob": "Reset Pull Job",
  "confirmTitleRetryCDR": "Are you sure?",
  "confirmationCode": "Confirmation Code",
  "connectedPartner": "Connected partner",
  "connectedPartners": "Connected partners",
  "connectedVia": "Connected via",
  "connectionCount": "Connection Service",
  "connectionFilter": "Connection Filter",
  "connectionId": "Connection ID",
  "connectionIdLabelExactMatch": "Connection ID (exact match)",
  "connectionOwner": "Connection owner",
  "connectionProfile": "Connection Profile",
  "connectionProfileAdd": "Add Connection Profile",
  "connectionProfileCreatedError": "Unable to create connection profile",
  "connectionProfileCreatedSuccess": "Connection profile created",
  "connectionProfileDelete": "Delete Connection Profile",
  "connectionProfileDetails": "Connection Profile Details",
  "connectionProfileNotFound": "No connection profile found",
  "connectionProfileOnChargingStationUpdatedError": "Failed to update connection profile on Charging Station",
  "connectionProfileOnChargingStationUpdatedSuccess": "Successfully updated connection profile on Charging Station",
  "connectionProfileSelect": "Select Connection Profile",
  "connectionProfileUpdatedError": "Unable to update connection profile",
  "connectionProfileUpdatedSuccess": "Connection profile updated",
  "connectionProfiles": "Connection Profiles",
  "connectionProfilesAvailableSavedError": "Failed to save available profiles",
  "connectionProfilesAvailableSavedSuccess": "Successfully saved available profiles",
  "connectionProfilesDeletedError": "Unable to delete Connection profile",
  "connectionProfilesDeletedSuccess": "Connection profile deleted",
  "connectionProtocol": "Connection Protocol",
  "connectionService": "Connection Service",
  "connectionTo": "Connection to",
  "connectionType": "Connection type",
  "connectionVia": "Connection via",
  "connector": "Connector",
  "connectorChargePointModelConfig": "Model configuration",
  "connectorConfiguration": "Connector Configuration",
  "connectorConfigurations": "Connector Configurations",
  "connectorCount": "# of connectors",
  "connectorCurrent": "Current [A]",
  "connectorEvseId": "EVSE ID",
  "connectorFormat": "Connector format",
  "connectorGroup": "Connector group",
  "connectorGroupConfirmDelete": "Are you sure you want to delete the Connector Group?",
  "connectorGroupCreate": "Create Connector Group",
  "connectorGroupCreatedError": "Failed to create Connector Group",
  "connectorGroupCreatedSuccess": "Connector Group created.",
  "connectorGroupCustomerGroupRemove": "Remove access for this Customer group",
  "connectorGroupCustomerGroupsRestriction": "Access to Connector Group is restricted to the following Customer Groups",
  "connectorGroupDeleteError": "Unable to delete Connector Group",
  "connectorGroupDeleteSuccess": "Connector Group successfully deleted!",
  "connectorGroupDetails": "Connector Group Details",
  "connectorGroupGetError": "Unable to get connector groups",
  "connectorGroupName": "Connector group name",
  "connectorGroupNoAvailable": "No connector group available",
  "connectorGroupUpdatedError": "Failed to update Connector Group",
  "connectorGroupUpdatedSuccess": "Connector Group updated!",
  "connectorGroup_condition": "Connector group",
  "connectorGroups": "Connector Groups",
  "connectorGroupsAddNyConnector": "Add Connector Groups by connector",
  "connectorGroupsAddToAll": "Add Connector Groups to all connectors",
  "connectorGroupsAddUserGroupsError": "Failed to restrict access for the customer group",
  "connectorGroupsAddUserGroupsSuccess": "Successfully restricted access for the customer group",
  "connectorGroupsUpdatedForError": "Failed to update publishing channels for {{entity}}",
  "connectorGroupsUpdatedForSuccess": "Updated Connector Groups for {{entity}}",
  "connectorIssue": "Connector Issue",
  "connectorLabel": "Label",
  "connectorMaxCurrent": "Max current per connector [A]",
  "connectorMinCurrent": "Min current per connector [A]",
  "connectorMode": "Model",
  "connectorNumber": "Connector Number",
  "connectorOnlineStatus": "Status",
  "connectorOperational": "Operational",
  "connectorOperationalStatus": "Operational Status",
  "connectorPower": "Power [kW]",
  "connectorReservation": "Reservation",
  "connectorRestricted": "Restricted",
  "connectorSession": "Session",
  "connectorSpecificPrices": "Connector-specific prices",
  "connectorStatus": "Connector Status",
  "connectorStatusFaulted": "Faulted and Online",
  "connectorStatusTooltip": "The dashboard is only showing connectors belonging to chargers that are in Operational status In Operation OR Awaiting Repair\n\nDefinition of Unavailable:\n- Connector OCPP status Unavailable AND Network status Online\n\nDefinition of Faulted:\n- Connector OCPP status Faulted AND Network status Online\n\nDefinition of Offline:\n- Network status Offline\n\nPlease note that this widget is showing the number of connectors. When you click through, you will get to a filtered list based on charging stations that have one or several connector(s) in the defined state.",
  "connectorStatusUnavailable": "Unavailable and Online",
  "connectorStoppedError": "Failed to stop connector",
  "connectorStoppedSuccess": "Connector stopped",
  "connectorType": "Connector Type",
  "connectorUnlock": "Unlock",
  "connectorUpdateError": "Failed to updated connectors on: {{assetName}}",
  "connectorUpdateSuccess": "Updated connectors on: {{assetName}}",
  "connectorUptime": "Connector Uptime",
  "connectorUtilisation": "Connector Utilization",
  "connectorVoltage": "Voltage [V]",
  "connectorWithourPriceInfo": "No Price Profile is associated with the connector. Charging is free of charge.",
  "connectors": "Connectors",
  "connectorsByChargingStation": "Connectors (by Charging Station)",
  "contactInformation": "Contact Information",
  "contactPerson": "Contact Person",
  "contactTitle": "{{type}} contact",
  "contacts": "Contacts",
  "containerSettings": "Container settings",
  "content": "Content",
  "continue": "Continue",
  "continueToContacts": "Continue to contacts",
  "continueToContent": "Continue to content",
  "continueToFeeAndTariff": "Continue to fee and tariff",
  "continueToSummary": "Continue to summary",
  "contractConditions": "Contract conditions",
  "contractDefinition": "Contract definition",
  "contractEventLog": "Contract Event Log",
  "contractId": "Contract ID",
  "contractIdConfigUpdatedError": "Failed to update Contract Id Configuration",
  "contractIdConfigUpdatedSuccess": "Contract Id Configuration was updated",
  "contractIdLabelExactMatch": "Contract ID (exact match)",
  "contractNotFound": "Contract not found",
  "contractStatus": "Contract status",
  "controlMode": "Control Mode",
  "cookiePreferences": "Cookie preferences",
  "copied": "Copied",
  "copy": "Copy",
  "copyLink": "Copy link",
  "cost": "Cost",
  "count": "Count",
  "countTransactions": "{{count}} transactions",
  "countriesAddSuccess": "Successfully added countries",
  "countriesAndPrices": "Countries and prices",
  "countriesRemoveSuccess": "Successfully removed countries",
  "countriesToAdd": "Countries to add",
  "countriesToEnableProduct": "Countries to enable product",
  "country": "Country",
  "countryCode": "Country code",
  "countryCode_condition": "Country",
  "countryFieldNew": "Country (new)",
  "countryHeader": "Supported country",
  "countryRequiredForAdHoc": "In order to set up ad hoc charging, a country must be set to the organization.",
  "countryRequiredForStripeConnect": "In order to create a Stripe Connect account, a country must be set to the organization.",
  "countrySelect": "Select country",
  "cpoAbbrevation": "CPO",
  "cpoConfiguration": "CPO Configuration",
  "cpoConnectorGroups": "CPO connector groups",
  "cpoCurrency": "CPO currency",
  "cpoData": "CPO Data",
  "cpoDelete": "Delete CPO",
  "cpoDisplayName": "CPO Display Name (in app)",
  "cpoDisplayNameBulkEditDescription": "Here you can see the names of our organizations (types “External Charging Station Operator” and “Charging Station Operator”) as CPO Organization Names and give them a 'readable' CPO Display Name. The CPO Display Name will be shown to our users in the app. The change of the CPO Display Names is immediately visible in the app.",
  "cpoDisplayNameBulkEditHeader": "CPO Display Name Edit",
  "cpoDisplayNameChanged": "Changed",
  "cpoDisplayNameStatus": "Display Name Status",
  "cpoFetchError": "Failed to fetch CPO's list",
  "cpoFilter": "CPO Filter",
  "cpoHotline": "Hotline (in app)",
  "cpoHotlineValidationError": "Format including country code, e.g. +49 800 1122334455",
  "cpoId": "CPO ID",
  "cpoIdMissing": "CPO ID missing",
  "cpoInvoiceUpload": "CPO invoice upload",
  "cpoInvoiceUpload_FormTitle": "CPO invoice upload",
  "cpoInvoiceUpload_continue": "Continue",
  "cpoInvoiceUpload_currency": "Invoice currency",
  "cpoInvoiceUpload_receiver": "Invoice receiver",
  "cpoInvoiceUpload_sender": "Invoice sender",
  "cpoInvoiceUpload_senderCurrency": "Choose currency",
  "cpoInvoiceUpload_senderPlaceholder": "Choose external CPO",
  "cpoInvoiceUpload_startButton": "Upload CPO Invoice",
  "cpoInvoiceUpload_startDescription": "Upload a CPO invoice file to reconcile invoices in the data warehouse.",
  "cpoInvoiceUpload_step1Info": "Please ensure to have the columns “claimed_cost” and “external_cdr_id” in the file.",
  "cpoInvoiceUpload_step1Title": "Invoice configuration",
  "cpoInvoiceUpload_step2Title": "Upload invoice",
  "cpoInvoiceUpload_uploadButton": "Upload invoice",
  "cpoInvoiceUpload_uploadError": "CPO invoice wasn't uploaded to the database. Please review file and retry. ",
  "cpoInvoiceUpload_uploadSuccess": "CPO invoice was successfully uploaded.",
  "cpoInvoiceUpload_yourInvoiceFile": "Your CSV file",
  "cpoMessage": "CPO message",
  "cpoOrganization": "CPO Organization",
  "cpoOrganizationAddError": "EMP organization add action failed.",
  "cpoOrganizationAddSuccess": "CPO organization added.",
  "cpoOrganizationName": "CPO Organization Name (internal)",
  "cpoOrganizationRemoveError": "CPO organization removed action error.",
  "cpoOrganizationRemoveSuccess": "CPO organization removed from assets.",
  "cpoOverview": "CPO overview",
  "cpoPrice": "CPO price",
  "cpoPriceExcVat": "CPO price excl VAT",
  "cpoPriceIncVat": "CPO price incl VAT",
  "cpoPriceProfileAssociations": "CPO Price Profile Associations",
  "cpoSubscription": "CPO Subscription",
  "cpoTariffInformation": "CPO tariff",
  "cpoVat": "CPO price VAT",
  "cpos": "CPOs",
  "crashErrorHeading": "It's not you. It's us.",
  "crashErrorMessage": "We're sorry for the interruption. Our team is constantly working to ensure you see this page as little as possible. You can try refreshing the page. If that doesn't work, start from the home page.",
  "create": "Create",
  "createBankTransfer": "Add bank transfer",
  "createBillingPlan": "Create a billing plan",
  "createCampaignActiveFromTooltip": "The provided data range is when users can claim the code in the mobile app i.e. enter now until 31/12/2022 if the campaign is over at the end of the year.",
  "createCampaignAmountTooltip": "Enter an amount including decimals e.g. 10 or 10.99.",
  "createCampaignCurrencyTooltip": "Enter the currency where the credit can be used (credits cannot be used outside of this currency zone).",
  "createCampaignCustomerGroupTooltip": "Specify the customer group the customer should be connected to.",
  "createCampaignNameTooltip": "The name has no external impact so just select a name that helps you understand what this campaign is for.",
  "createCampaignQuantityTooltip": "Unique codes - this is the number of codes that will be generated.\n\nReusable codes - this is the number of times the code can be used by all users claiming the campaign.",
  "createCampaignTypeOfCampaignTooltip": "Prepaid - will give users prepaid money.\n\nCustomer group - will put users in a certain customer group.",
  "createCampaignTypeTooltip": "Unique voucher code - will generate a set of \"single use\" codes that need to distributed to users for claiming in the mobile app.\n\nReusable voucher code - will generate one code that can be used by all users .",
  "createCampaignValidityOfPrepaidTooltip": "Default - the user has 1 year to use the credit from the day they claim it.\n\nDate - the user has until the date specified to use the credit.\n\nDuration - the user has x number of days from the date the claimed the credit to use it.",
  "createCampaignVoucherCodeTooltip": "If \"Reusable\" code was used then this is the code that users will enter to claim the campaign.\n\nIf \"Unique\" code was used then this code will be a prefix in the code that users will enter to claim the campaign.",
  "createChargingKey": "Create Charging Key",
  "createDiscount_discountSource_CDR": "CDR",
  "createDiscount_discountSource_CLAIM_BACK": "Claim back",
  "createDiscount_discountSource_INVOICE": "Invoice",
  "createDiscount_discountType_FIXED": "Fixed",
  "createDiscount_discountType_MANUAL": "Manual",
  "createDiscount_discountType_TARIFF": "Tariff",
  "createDiscount_discountType_VOLUME": "Volume",
  "createEmpRoamingPricing": "Create EMP Roaming Pricing",
  "createErrorSetting": "Create Error Setting",
  "createErrorSettings": "Create Error Settings",
  "createExternalConnectorGroup": "Create external connector group",
  "createIntegrationError": "Error! Integration wasn't successfully created",
  "createIntegrationSuccess": "Integration successfully created",
  "createMarketplaceConnectionError": "Failed to create connection",
  "createMarketplaceConnectionSuccess": "Connection was created",
  "createMonthlyFees": "Create monthly fees",
  "createNew": "Create new",
  "createNewIntegration": "Create New Integration",
  "createNewTicket": "Create new Ticket",
  "createOneTimeFee": "Create One Time Fee",
  "createPriceProfileParkingTimeInfo": "Profile can only be used on external chargers.",
  "createPricingProfile": "Create Price Profile",
  "createPricingProfileConflictError": "A price profile with the same configuration already exists",
  "createPricingProfileError": "Unable to create Price Profile",
  "createPricingProfileSuccess": "Successfully created Price Profile",
  "createPullJob": "Create Pull Job",
  "createPullJobError": "Error! Job wasn't successfully created",
  "createPullJobSuccess": "Job successfully created",
  "createSessionRule": "Add session rule",
  "createSessionRuleError": "Failed to create session rule. {{error}}",
  "createSessionRuleSuccess": "Session rule created.",
  "createStripeAccount": "Create Stripe account",
  "createSubscriptionCommitmentPeriodMessage": "Fill a number of months first",
  "createSubscriptionContentInfo": "All content from this page will appear in the app.",
  "createSubscriptionContentTip": "English language could be useful for travellers and foriegners. Always try to keep it as fallback.",
  "createSubscriptionCountryTooltip": "Determine where VAT is paid and which currency the subscription is offered in. Only end-customers in the selected country will see the subscription in the mobile app.",
  "createSubscriptionDescriptionTooltip": "This text is optional and can be used to explain more details about the subscription. Please note that charging price information and detailed terms and conditions is covered by other fields below.",
  "createSubscriptionDurationTooltip": "For one-time fee, it sets the subscription duration from subscribing to the end of it. For recurring fees, it's the cycle at which the subscription renews.",
  "createSubscriptionFeeTypeTooltip": "If you want to create a free of charge subscription set fee to 0",
  "createSubscriptionFileUpload": "Drag and drop files here or click to upload",
  "createSubscriptionGracePeriodCloseSessionTooltip": "If this is selected then when a user performs a charging session their grace period ends with immediate effect.",
  "createSubscriptionGracePeriodSectionTooltip": "Grace periods can be used to provide users with \"free periods\" or \"regret periods\" where they have the subscription but are not charged for it.",
  "createSubscriptionGracePeriodTypeTooltip": "Grace periods can have \"Fixed\" or \"Variable\" periods. Fixed is used when the grace period for all users, regardless of when each user joined the subscription, ends on the same day. For example free until 31/12/2023. Variable is used when each user gets a defined period of time from the time the join the subscription e.g. 14 days.",
  "createSubscriptionInfo": "Before creating a subscription, make sure that you have created all needed customer groups and price profiles, and connected them to each other using the price profile association.",
  "createSubscriptionInitialFeeTooltip": "Initial fee",
  "createSubscriptionInternalNameTooltip": "The unique identifier for internal usage. It won't be visible to end-customers. Please provide a descriptive and meaningful name that accurately represents the subscription.",
  "createSubscriptionMinimalCommitmentPeriodTooltip": "Minimal commitment period",
  "createSubscriptionNameTooltip": "The name will be displayed in the app. Different names in different languages can be provided at the bottom of this page",
  "createSubscriptionPriceDescription": "Our system is built that way that in order to connect the price you need to connect a customer group associated with this price",
  "createSubscriptionPrivateAccessTooltip": "Defines who has access to the subscription. All your end-customers or a specific group.",
  "createSubscriptionRightOfWithdrawalTooltip": "The right of withdrawal applies to all subscriptions, as consumers are entitled by law to cancel subscription within 14 days without giving a specific reason.\nAll refunds in this case will be processed automatically.",
  "createSubscriptionStatusTooltip": "Active means that the subscription can be seen in the app and users can be added to it. Inactive means that existing users keep the subscription but no new users can be added to it, even by a system administrator. ",
  "createSubscriptionSubscriptionFeePerUserTooltip": "The fee on user level is the same each month regardless of the number of keys.",
  "createSubscriptionSupportedPaymentMethodsTooltip": "This method will be available for subscription payments",
  "createSubscriptionUserFeeMessage": "Fill user fee first",
  "createSubscriptionVisibilityPeriodTooltip": "Specifies the duration of subscription visibility in the app. Once this period ends, the subscription will automatically be hidden from the application. This change doesn't impact current subscribers but alters subscription availability.",
  "createTariff_ACpowerRange-AC_1": "0 - 7.3 kW",
  "createTariff_ACpowerRange-AC_2": "7.4 - 10 kW",
  "createTariff_ACpowerRange-AC_3": "11 - 21 kW",
  "createTariff_ACpowerRange-AC_4": "22 - 42 kW",
  "createTariff_ACpowerRange-AC_5": "43 - 45 kW",
  "createTariff_DCpowerRange-DC_1": "0 - 49 kW",
  "createTariff_DCpowerRange-DC_2": "50 - 74 kW",
  "createTariff_DCpowerRange-DC_3": "75 - 99 kW",
  "createTariff_DCpowerRange-DC_4": "100 - 149 kW",
  "createTariff_DCpowerRange-DC_5": "150 - 249 kW",
  "createTariff_DCpowerRange-DC_6": "250 - 399 kW",
  "createTariff_DCpowerRange-DC_7": "400 - 749 kW",
  "createTariff_DCpowerRange-DC_8": "750 - 999 kW",
  "createTariff_adhoc_customerInfo": "All customers of the ad-hoc solution",
  "createTariff_any": "Any",
  "createTariff_connectorGroup": "Connector group",
  "createTariff_connectorGroups": "Connector groups",
  "createTariff_connectorOwnerType": "Connectors owner type",
  "createTariff_connectorOwnerType-externalCPO": "External CPO",
  "createTariff_connectorOwnerType-internalCPO": "Internal CPO",
  "createTariff_connectorOwnerType-ownCPO": "Own CPO",
  "createTariff_connectors": "Connectors",
  "createTariff_countries": "Countries",
  "createTariff_country": "Country",
  "createTariff_cpo": "CPO",
  "createTariff_createTariffButton": "Create association",
  "createTariff_createTariffStep2Title_ADHOC": "Creating ad-hoc association",
  "createTariff_createTariffStep2Title_CPO": "Creating CPO association",
  "createTariff_createTariffStep2Title_EMP": "Creating EMP association",
  "createTariff_createTariffStep2Title_EXTERNALCPO": "Creating CPO price association",
  "createTariff_createTariffStep2Title_EXTERNAL_CPO": "Creating CPO price association",
  "createTariff_createTariffStep2Title_INTERNAL_CPO": "Creating CPO association",
  "createTariff_createTariffStep2Title_ROAMING_PROVIDER": "Creating roaming provider price association",
  "createTariff_currentType": "Current type",
  "createTariff_currentType-AC": "AC",
  "createTariff_currentType-Any": "Any",
  "createTariff_currentType-DC": "DC",
  "createTariff_customerGroup": "Customer group",
  "createTariff_customerGroups": "Customer groups",
  "createTariff_customerType-allCustomers": "All end-customers",
  "createTariff_customerType-specificCustomerGroup": "Specific customer group",
  "createTariff_customers": "Customers",
  "createTariff_details": "Details",
  "createTariff_emp": "EMP",
  "createTariff_emps": "EMPs",
  "createTariff_enableTariffOption": "I want to create an association based on a tariff ID",
  "createTariff_enterTariffId": "Enter Tariff ID here",
  "createTariff_externalCpo": "External CPO",
  "createTariff_externalCpo_toggle": "Create price profile for an external CPO",
  "createTariff_externalCpo_tooltip": "When setting a price for an external CPO. Leave empty if this should be set for the EMP configuring it.",
  "createTariff_externalCpos": "External CPOs",
  "createTariff_incomingTariffsTitle": "Incoming price associations",
  "createTariff_incoming_explanation": "This tariff will be created on behalf of the external CPO for your EMP organisation",
  "createTariff_internalCpo": "Internal CPO",
  "createTariff_networkOwner": "Network owner",
  "createTariff_noOptionsGoToMarketplace": "This organization has no agreements. Please go to the Marketplace and set one up.",
  "createTariff_outgoingTariffsTitle": "Outgoing price associations",
  "createTariff_powerRange": "Power range",
  "createTariff_powerRange-AC_1": "0 - 7.3 kW",
  "createTariff_powerRange-AC_2": "7.4 - 10 kW",
  "createTariff_powerRange-AC_3": "11 - 21 kW",
  "createTariff_powerRange-AC_4": "22 - 42 kW",
  "createTariff_powerRange-AC_5": "43 - 45 kW",
  "createTariff_powerRange-DC_1": "0 - 49 kW",
  "createTariff_powerRange-DC_2": "50 - 74 kW",
  "createTariff_powerRange-DC_3": "75 - 99 kW",
  "createTariff_powerRange-DC_4": "100 - 149 kW",
  "createTariff_powerRange-DC_5": "150 - 249 kW",
  "createTariff_powerRange-DC_6": "250 - 399 kW",
  "createTariff_powerRange-DC_7": "400 - 749 kW",
  "createTariff_powerRange-DC_8": "750 - 999 kW",
  "createTariff_powerRange_info": "There are higher power ranges available more than {{maxRange}}",
  "createTariff_powerRanges": "Power ranges",
  "createTariff_priceProfile": "Price profile",
  "createTariff_progress": "Price association creation: step {{ currentStep }} of 2",
  "createTariff_providerType-allEMPs": "Public",
  "createTariff_providerType-specificEMP": "Specific EMP",
  "createTariff_selectAll": "Select all",
  "createTariff_selectConnectorGroup": "Choose a connector group",
  "createTariff_selectConnectorGroups": "Choose connector groups",
  "createTariff_selectConnectorOwnerType": "Choose connectors owner type",
  "createTariff_selectConnectorOwnerType_tooltip": "TBD...",
  "createTariff_selectCountries": "Select countries",
  "createTariff_selectCountry": "Select Country",
  "createTariff_selectCurrentType": "Select current type",
  "createTariff_selectCustomerGroup": "Choose a customer group",
  "createTariff_selectCustomerGroups": "Choose customer groups",
  "createTariff_selectEmp": "Choose an EMP",
  "createTariff_selectEmps": "Choose EMPs",
  "createTariff_selectExternalCpo": "Select an external CPO",
  "createTariff_selectExternalCpos": "Select external CPOs",
  "createTariff_selectInternalCpo": "Select an internal CPO",
  "createTariff_selectNetworkOwner": "Select the network owner",
  "createTariff_selectPowerRange": "Select power range",
  "createTariff_selectPowerRanges": "Select power ranges",
  "createTariff_selectPriceProfile": "Choose a price profile",
  "createTariff_step1Title": "Choose an association type",
  "createTariff_tariffId": "Tariff ID",
  "createTariff_tariffTypeADHOC_description": "For an ad-hoc solution",
  "createTariff_tariffTypeADHOC_title": "Ad-hoc price association",
  "createTariff_tariffTypeCPO_description": "For other EMP organisations",
  "createTariff_tariffTypeCPO_title": "CPO price association",
  "createTariff_tariffTypeEMP_description": "For your end-customers",
  "createTariff_tariffTypeEMP_title": "EMP price association",
  "createTariff_tariffTypeEXTERNALCPO_description": "For other EMP organisations",
  "createTariff_tariffTypeEXTERNALCPO_title": "CPO price association",
  "createTariff_tariffTypeEXTERNAL_CPO_description": "For other EMP organisations",
  "createTariff_tariffTypeEXTERNAL_CPO_title": "CPO price association",
  "createTariff_tariffTypeINCOMING_description": "Incoming tariff",
  "createTariff_tariffTypeINCOMING_title": "Incoming",
  "createTariff_tariffTypeINTERNAL_CPO_description": "For other EMP organizations",
  "createTariff_tariffTypeINTERNAL_CPO_title": "CPO price association",
  "createTariff_tariffTypeROAMING_PROVIDER_description": "For other EMP organizations",
  "createTariff_tariffTypeROAMING_PROVIDER_title": "Roaming provider association",
  "createTariff_validityPeriod": "Validity period",
  "created": "Created",
  "createdAt": "Created at",
  "createdByEmail": "Created by (email)",
  "createdDate": "Created Date",
  "createdKeys": "Created keys",
  "createdOn": "Created on",
  "createnNewIntegration": "Create New Integration",
  "creationDate": "Creation date",
  "credentials": "Credentials",
  "credentialsFailed": "Failed to update Credentials, try again later!",
  "credentialsSuccessfullyTested": "Credentials successfully tested",
  "credentialsSucess": "Credentials updated successfully",
  "credentialsTooltipIntegration": "Credentials is to be provided when you are initiating the handshake with the partner. If the partner initiates the handshake, you don't have to populate these fields.",
  "credit-card": "Credit Card",
  "creditAmount": "Amount ({{currency}})",
  "creditCard": "Credit Card",
  "creditCardDetails": "Credit card details",
  "creditCardReservationAmount": "Reservation amount",
  "creditCardReservationConfig": "Credit Card reservation config",
  "creditCardReservationConfigTooltip": "Credit card reservation amounts for each country and currency.",
  "creditCardReservationEnabled": "Reservation of amount on credit cards",
  "creditCardReservationUpdateSuccess": "Successfully updated credit card reservations",
  "creditCardReservationUpdatedError": "An error occurred while updating the reservation amount for {{country}}",
  "creditCardReservationUpdatedSuccess": "The reservation amount for {{country}} has been successfully updated.",
  "creditCardReservations": "Credit card reservations",
  "creditCreate": "Create Credit",
  "creditCreateError": "Unable to create credit on user",
  "creditCreateSuccess": "Credit was created successfully",
  "creditDate": "Credit date",
  "creditDeleted": "Credit successfully removed",
  "creditNote": "Invoice Note",
  "creditNoteCreate": "Create credit note",
  "creditNoteCreateError": "Unable to create credit note on invoice",
  "creditNoteCreateSuccess": "Credit note was created successfully",
  "creditReasonAddError": "Unable to add new credit reason",
  "creditReasonAddSuccess": "New credit reason added",
  "creditReasonDuplicationError": "Cannot add duplicated reason.",
  "creditReasonHeader": "Credit & Refund reason",
  "creditReasonRemoveError": "Unable to remove credit reason",
  "creditReasonRemoveSuccess": "Credit reason removed",
  "creditReasons": "Reason",
  "creditReasonsCard": "Credit & Refund reasons",
  "credits": "Credits",
  "creditsExport": "Export credits to .csv file",
  "creditsExportError": "Unable to export credits",
  "creditsList": "Credits list",
  "csvChargingStationTemplateHints": "For charger columns A to N:\n\n<0>name\nName of your charger</0>\n\n<0>location_name\nExact name of the location to which the charger belongs</0>\n\n<0>operational_status\nThese values are possible:\n- ORDERED\n- IN_TESTING\n- IN_WAREHOUSE\n- AWAITING_REPAIR\n- DISABLED\n- IN_OPERATION</0>\n\n<0>publishing_channels\nMultiple values are possible, separated by ','</0>\n\n<1>These values are possible</1>\n\n<0>model\nExact name of the charger model, e.g. Alpitronic HYC 150/300 1.6-J</0>\n\n<0>label</0>\n\n<0>charging_station_id</0>\n\n<0>installation_time\nTime of the installation in the format yyyy-MM-dd HH:mm</0>\n\n<0>description\nAdd your own description</0>\n\n<0>dynamic_ip\nTRUE or FALSE or can be left blank for websocket chargers</0>\n\n<0>url\nTRUE or FALSE or can be left blank for websocket chargers</0>\n\n<0>connection_profile\nConnection profile, e.g. Internet</0>\n\n<0>start_from_app\nIndicates, whether a remote start and remote stop is possible. These values are possible: TRUE or FALSE</0>\n\n<0>heartbeat_interval\nThe interval for the heartbeat the charger shall send in seconds, e.g. 300</0>\n\nFor connector columns O to U:\n\n<0>connector_label</0>\n\n<0>connector_type\nPossible values:\n- CCS\n- TYPE2\n- CHAdeMO\n- Schuko</0>\n\n<0>connector_evse_id\nProvide the unique EVSE for the connector</0>\n\n<0>connector_power\nConnector power, value in kW</0>\n\n<0>connector_current\nConnector current, value in A</0>\n\n<0>connector_voltage\nConnector voltage, value in V</0>\n\n<0>connector_mode\nConnector mode, possible values:\n- MODE1\n- MODE2\n- MODE3\n- MODE4</0>",
  "csvExportError": "Could not export csv file",
  "csvExportType-CHARGING_KEY": "charging key",
  "csvExportType-CHARGING_STATION": "charging station",
  "csvExportType-CUSTOMER": "customer",
  "csvExportType-ORGANIZATION": "organization",
  "csvLocationTemplateHints": "<0>name\nName of your location</0>\n\n<0>asset_owner\nExact spelling of the location’s asset owner</0>\n\n<0>operator\nExact spelling of the location’s operator</0>\n\n<0>location_owner\nExact spelling of the location’s owner</0>\n\n<0>address\nName of the street only</0>\n\n<0>street_number\nHouse number</0>\n\n<0>postal_code\nPostal code of the location</0>\n\n<0>city\nCity of the location</0>\n\n<0>country\nISO 3166-1 Alpha-3 code of the location. Most common ones: \n- DEU: Germany\n- NOR: Norway\n- FRA: France\n- NLD: Netherlands\n- GBR: United Kingdom\n- LTU: Lithuania\n- BGR: Bulgaria\n</0>\n<1>Please see the complete list of ISO codes</1>\n\n<0>zone\nOnly necessary, if the country spreads across timezones</0>\n\n<0>latitude / longitude</0>\nPlease find the values like this\n- Go to <2>Google Maps</2>\n- Find the location on the map\n- Right click on the spot of the location\n- Click on the coordinates from the menu, they will be copied\n- The first value is latitude, the second longitude\n- Make sure you remove the comma after pasting the values into both cells of the CSV file\n\n\n\n",
  "currencies": "Currencies",
  "currency": "Currency",
  "current": "Current [A]",
  "currentPage": "{{first}}-{{last}} of {{total}} items",
  "custom": "custom",
  "customSelection": "Custom Selection",
  "customer": "Customer",
  "customerDelete": "Delete Customer",
  "customerDeleteError": "Failed to delete customer.",
  "customerDeleteMessage": "If you want to permanently delete this customer you can activate the process here. You cannot undo this action.",
  "customerDeleteNotAllowed": "This customer is also a system user, and needs to be deleted in the system user view",
  "customerDeleteSuccess": "Successfully deleted customer",
  "customerDeleteUndoInfoText": "Deleting Customer",
  "customerEmail": "Customer Email",
  "customerEmailTakenError": "A user with that email already exists",
  "customerEmp": "Customer EMP",
  "customerGroup": "Customer Group",
  "customerGroupAccessRemovedError": "Failed to remove restricted access for the customer group. {{error}}",
  "customerGroupAccessRemovedSuccess": "Successfully removed access for the customer group",
  "customerGroupAdd": "Add Customer Group",
  "customerGroupCreate": "Create Customer Group",
  "customerGroupCreateError": "Unable to create Customer Group",
  "customerGroupCreateSuccess": "Successfully created Customer Group",
  "customerGroupDelete": "Delete Customer Group",
  "customerGroupDeleteConfirm": "Are you sure you want to delete this customer group?",
  "customerGroupDeleteError": "Unable to delete Customer Group",
  "customerGroupDeleteSuccess": "Successfully deleted Customer Group",
  "customerGroupDetails": "Customer Group Details",
  "customerGroupNoAvailable": "No customer group available",
  "customerGroupUpdateError": "Unable to update Customer Group",
  "customerGroupUpdateSuccess": "Successfully updated Customer Group",
  "customerGroups": "Customer Groups",
  "customerGroupsRestrictAccess": "Restrict access for customer groups",
  "customerGroupsSearch": "Search Customer Groups",
  "customerHasBillingDeleteWarning": "You are about to delete a user who has billing and may have uninvoiced charges. Click ok to proceeed",
  "customerHasDebtDeleteWarning": "You are about to delete a user who has debt. Click ok to proceeed",
  "customerKeyOrderSelect": "Select customer key orders to print addresses",
  "customerKeyOrderSelectCompleteWithout": "Select customer key orders to complete without assigning",
  "customerKeyOrders": "Customer Key Orders",
  "customerOrganizations": "Customer Organizations",
  "customerReceipt": "Customer receipt",
  "customerType": "Customer Type",
  "customerUpdateError": "Failed to update customer",
  "customerUpdateSuccess": "Successfully edited Customer information",
  "customers": "Users",
  "customersAdd": "Add customers",
  "customersAddToGroup": "Add customer(s) to this group",
  "customersAddedError": "Unable to add customers",
  "customersAddedSuccess": "Successfully added customers",
  "customizationGroupName": "Customization",
  "cvc": "CVC",
  "daily": "Daily",
  "dashboard": "Dashboard",
  "dashboardAdd": "Add Dashboard",
  "dashboardDelete": "Delete Dashboard",
  "dataFetchError": "Unable to fetch data!",
  "dataFetchedFrom": "Data Fetched From",
  "dataFetchedUntil": "Data Fetched Until",
  "dataMissing": "Data missing",
  "dataNoAvailable": "No data available",
  "dataSource": "Data source",
  "dataType": "Data type",
  "date": "Date",
  "dateAndTime": "Date & Time",
  "dateRange": "Date range",
  "dateRangeWarning": "Date range not correct. End date must be after the starting date.",
  "dateType": "Date type",
  "day": "day",
  "dayOfBirth": "Day of birth",
  "dayOfInvoice": "Day of invoice",
  "dayOfWeek": "Day of week",
  "days": "days",
  "daysOfWeek": "Days of week",
  "daysUntilDue": "Days until due",
  "debtAsBlocker": "Debt as blocker",
  "decimal": "Decimal",
  "decimalBigEndian": "Decimal - Big endian",
  "decimalLittleEndian": "Decimal - Little endian",
  "default": "Default",
  "defaultContractIdPrefix": "Default prefix",
  "defaultCurrency": "Default currency",
  "defaultPaymentOption": "Default",
  "defaultWidgetErrorMessage": "Sorry, error occured when fetching data",
  "defaultWidgetNoAccesMessage": "Sorry, you don't have acces to this widget",
  "definePriceExcludingVat": "Define price excluding VAT",
  "defineVatPercentages": "Define VAT percentages",
  "delete": "Delete",
  "deleteConfirm": "Are you sure you want to delete?",
  "deleteConnectorGroup": "Delete connector group",
  "deleteCpoError": "Failed to delete CPO",
  "deleteCpoSuccess": "CPO was successfully deleted",
  "deleteCredit": "Delete Credit",
  "deleteCreditConfirmation": "Do you really want to delete this credit?",
  "deleteErrorSetting": "Delete Error Setting",
  "deleteErrorSettings": "Delete Error Settings",
  "deleteInfo": "Type 'DELETE' to continue to delete",
  "deleteJob": "Delete Job",
  "deleteOneTimeFee": "Delete One Time Fee",
  "deleteOneTimeFeeConfirmation": "Do you really want to delete this fee?",
  "deleteOperatorMappingError": "Failed to delete Operator Mapping",
  "deleteOperatorMappingSuccess": "Operator Mapping was successfully deleted",
  "deletePriceProfile": "Delete Price Profile",
  "deletePriceProfileError": "Error occured. Price Profile was not deleted",
  "deletePriceProfileMessage": "If you want to permanently delete this price profile you can activate the process here. This action is only available for price profiles that were never associated. You can not undo this action.",
  "deletePriceProfileSuccess": "Price Profile was successfully deleted",
  "deletePriceProfileUndoInfoText": "Deleting Price Profile",
  "deleteScheduling": "Delete scheduling",
  "deleteSessionRule": "Delete session rule",
  "deleteSessionRuleError": "Failed to delete session rule",
  "deleteSessionRuleSuccess": "Session rule was deleted",
  "deleted": "Deleted",
  "deleting": "Deleting",
  "deletionFailed": "Deletion failed",
  "delivered": "Delivered",
  "deliveryAddress": "Delivery address",
  "deliveryAddressAdd": "Add delivery address",
  "deliveryAddressDeleteError": "Unable to remove delivery address",
  "deliveryAddressDeleteSuccess": "Successfully removed delivery address",
  "deliveryAddressUpdateError": "Unable to update delivery address",
  "deliveryAddressUpdateSuccess": "Successfully updated delivery address",
  "deliveryAddresses": "Delivery addresses",
  "deliveryName": "Delivery name",
  "dependencyGroup": "Dependency Group",
  "description": "Description",
  "descriptionOCPP": "Description (OCPP)",
  "details": "Details",
  "developers": "Developers",
  "diagnosticsFiles": "Diagnostics Files",
  "different_problem": "Different problem",
  "direct": "Direct",
  "directPaymentInformation": "Direct payment",
  "directPaymentInformationMessage": "Please reach out to your customer success manager when you are ready to be scheduled for the start the custom integration with your charger model.",
  "directPaymentTerminalId": "Terminal ID",
  "directPaymentTerminalLanguage": "Terminal language",
  "directPaymentTerminalType": "Terminal type",
  "direction": "Direction",
  "directions": "Directions",
  "disable": "Disable",
  "disableAdBlockerInfo": "Please disable ad blocker to view receipt",
  "disableChargingKey": "Disable charging key",
  "disableChargingKeys": "Disable charging keys",
  "disableChargingKeysDescription": "Charging keys will be disabled and can’t be used to start or stop charging sessions",
  "disableKey": "Disable key",
  "disableOnApp": "Disable on App",
  "disabled": "Disabled",
  "disabledAt": "Disabled at",
  "disabledEndTrialOnSessionInfo": "Won't finish grace period on a session",
  "discount": "Discount",
  "discountConditionsFromCPO": "Discount definition",
  "discountDefinition": "Discount definition",
  "discountLog": "Discount log",
  "discountManagement": "Discount management",
  "discountPeriod": "Discount Period",
  "discountRemoveSuccess": "Discount was successfully removed",
  "discountSource": "Discount Source",
  "discountType": "Discount type",
  "discountUpdateSuccess": "Discount updated successfully",
  "displayName": "Display Name",
  "domain": "Domain",
  "download": "Download",
  "downloadCSVFile": "Download CSV file",
  "downloadCustomerReceipt": "Download Customer Receipt",
  "downloadError": "Something went wrong when downloading the file",
  "downloadErrorsCSV": "Download .csv about issues ",
  "downloadOptions": "Download options",
  "downloadReceiptError": "There was an error opening the receipt",
  "downloadReceipts": "Download receipts",
  "downloadSessionReport": "Download session report",
  "downloadStarted": "The download has started",
  "downloadSubscriptionTerms": "Download attached file",
  "dragAndDropCsvFile": "Drag&drop your CSV file here",
  "dragAndDropTitle": "Drag and drop it here",
  "dragFilePrefix": "Drag your file here",
  "dragFileSuffix": "Or if you prefer...",
  "driveApi": "Drive API",
  "driveApiConfiguration": "Drive API configuration",
  "driveApiOrg": "{{orgName}} - Drive API",
  "driveApiTitleKey": "{{orgName}} - Developer Page Overview",
  "dueDate": "Due Date",
  "duplicateLastConfiguration": "Duplicate last configuration",
  "duration": "Duration",
  "durationMinutes": "Duration (minutes)",
  "dynamicDataPublishing": "Dynamic data publishing",
  "edit": "edit",
  "editAmount": "Edit Amount",
  "editApiKeyConfiguration": "Edit API key configuration",
  "editApiKeyPermission": "Edit API key permission",
  "editBlacklist": "Edit disabled CPO",
  "editChargingKey": "Edit Charging Key",
  "editChargingKeyName": "Edit charging key name",
  "editComment": "Edit comment",
  "editConfiguration": "Edit configuration",
  "editDiscount": "Edit discount",
  "editErrorSetting": "Edit Error Setting",
  "editErrorSettings": "Edit Error Settings",
  "editEvents": "Edit events",
  "editFee": "Edit fee",
  "editFeeAndTariff": "Editing fee and tariff",
  "editFees": "Edit fees",
  "editJob": "Edit Job",
  "editMonthlyFee": "Edit monthly fee",
  "editMonthlyFees": "Edit monthly fees",
  "editOwnerDetails": "Edit owner details",
  "editPONumbers": "Edit PO numbers",
  "editPaymentMethods": "Edit payment methods",
  "editPaymentMethodsHint": "Create a Stripe account in the General Information section to enable necessary payment methods.",
  "editPermissions": "Edit permissions",
  "editPrice": "Edit price",
  "editPrices": "Edit prices",
  "editPullJob": "Edit Pull Job",
  "editPullJobError": "Error! Job wasn't successfully edited",
  "editPullJobSuccess": "Job successfully edited",
  "editReservationAmountModalTitle": "Edit amount for {{country}}",
  "editReservationAmountModalTitle2": "Edit reservation amount",
  "editReservations": "Edit reservations",
  "editSubscriptionDetails": "Editing the subscription details",
  "editTranslation": "Edit translation",
  "editTranslationFor": "Edit translation for {{language}}",
  "editVAT": "Edit VAT values",
  "editVatFor": "Edit VAT for {{country}}",
  "editWebhookConfiguration": "Edit webhook configurarion",
  "effect": "Effect",
  "electricVehicles": "EV models",
  "electricVehiclesAdd": "Add EV model",
  "electricVehiclesAddError": "Could not add EV model",
  "electricVehiclesAddExisting": "Existing manufacturer",
  "electricVehiclesAddNew": "New manufacturer",
  "electricVehiclesAddSuccess": "Successfully added EV model",
  "electricVehiclesModelType": "Model Type",
  "electricVehiclesUserHasNone": "User hasn't registered any EV models",
  "elwinAccount": "Enoro Account",
  "elwinAccountCreate": "Create Enoro Account",
  "elwinAccountCreatedError": "Unable to create Enoro Account",
  "elwinAccountCreatedSuccess": "Successfully created Enoro Account",
  "elwinAccountFetchError": "Could not fetch Enoro account at this time.",
  "elwinAccountRegistered": "Enoro Account registered ",
  "elwinAccountRegistration": "Enoro Account registration",
  "elwinAccountRegistrationLink": "Create Enoro Account here",
  "elwinId": "Enoro ID",
  "emRoamingProvider": "E-Mobility Roaming Provider",
  "email": "Email",
  "emailAddress": "Email Address",
  "emailBody": "Email body",
  "emailCSVFile": "Email CSV File",
  "emailChangeDisclaimer": "Changing your email will cause you to log out and receive a temporary password to your new email.",
  "emailMessage": "Email Message",
  "emailOrExternalId": "Email or external ID",
  "emailPDFFile": "Email PDF File",
  "emailSentError": "Email could not be sent to: {{recipient}}. Please try again or contact the support team!",
  "emailSentSuccess": "Email is sent to: {{recipient}}. Please allow up to one hour for the email to arrive.",
  "emailSubject": "Email Subject",
  "emailTemplate": "Template",
  "empAbbrevation": "EMP",
  "empConfiguration": "EMP Configuration",
  "empConnectorGroups": "EMP connector groups",
  "empCurrency": "EMP currency",
  "empFetchError": "Failed to fetch associated EMP's",
  "empId": "EMP ID",
  "empInvoices": "EMP costs report",
  "empOrganization": "EMP Organization",
  "empOrganizationAddError": "EMP organization add action failed.",
  "empOrganizationAddSuccess": "EMP organization added.",
  "empOrganizationRemoveError": "EMP organization removed action error.",
  "empOrganizationRemoveSuccess": "EMP organization removed from assets.",
  "empPrice": "EMP price",
  "empPriceProfileAssociationsError": "Failed to fetch price profiles associations.",
  "empRoaming": "EMP Roaming",
  "empRoamingCDRs": "EMP Roaming CDRs",
  "empRoamingChannels": "EMP Roaming Channels",
  "empRoamingId": "EMP Roaming ID",
  "empRoamingPriceProfileCreateError": "Price profile association create error!",
  "empRoamingPriceProfileCreateSuccess": "Price profile association create success!",
  "empRoamingPricing": "EMP Roaming Pricing",
  "empRoamingProvidedTo": "EMP Roaming Provided To",
  "empRoamingPullJobs": "EMP Roaming Pull Jobs",
  "empRoamingPullJobsCreate": "Create EMP Roaming Pull Jobs",
  "empRoamingPullJobsEdit": "EMP Roaming Pull Jobs Edit",
  "empToken": "EMP Token",
  "enable": "Enable",
  "enableAdHocCharging": "Enable ad hoc charging through web page",
  "enableChargingKey": "Enable charging key",
  "enableChargingKeys": "Enable charging keys",
  "enableChargingKeysDescription": "Charging keys will be enabled and can be used to start or stop charging sessions",
  "enableExpiration": "Set expiration",
  "enableFreeOfChargeTooltip": "Making a subscription free of charge allows users without billing to join the subscription",
  "enableKey": "Enable key",
  "enableOnApp": "Enable on App",
  "enablePaymentTerminal": "Enable ad hoc charging through Payter terminal",
  "enableReservationInGroup": "Enable reservation for all connectors in the connector group",
  "enableRevenueToAo": "Enable revenue to asset owner",
  "enabled": "Enabled",
  "enabledCpo": "Enabled CPO",
  "enabledForEndUsers": "Visible for app users",
  "encodingType": "Encoding Type",
  "end": "End",
  "endCustomer": "End Customer",
  "endCustomers": "End Customers",
  "endDate": "End date",
  "endSubscriptionRefund": "Would you like to refund the user?",
  "endTime": "End Time",
  "endTrialOnSession": "Close grace period on first session",
  "endTrialOnSessionInfo": "Will finish grace period on a first session",
  "endUser": "End user",
  "ended": "Ended",
  "endpoint": "Endpoint",
  "endpointMessage": "Currently no partner endpoints are available",
  "endpointUrl": "Endpoint URL",
  "endpoints": "Endpoints",
  "energy": "Energy (kWh)",
  "energyConsumed": "Energy Consumed (kWh)",
  "energyLargerThan150kWh": "Energy > 150 kWh",
  "energyLargerThan150kWhTooltip": "Number of sessions in which more than 150 kWh was charged.",
  "energyLessThan0kWh": "Energy ≦ 0 kWh",
  "energyLessThan0kWhTooltip": "Number of sessions in which 0 kWh or less was charged.",
  "energyOnly": "Energy only",
  "enhancedFieldMessage": "This field has been enhanced.",
  "enhancements": "Enhancements",
  "enterValue": "Enter value",
  "erikTestAgain": "Testing string in english",
  "error": "Error",
  "errorCode": "Error Code",
  "errorCodeAdd": "Add Error Code",
  "errorCodeCreateError": "Failed to create firmware error code",
  "errorCodeCreateSuccess": "Successfully created firmware error code",
  "errorCodeDelete": "Delete Error Code",
  "errorCodeDetails": "Error Code Details",
  "errorCodeMismatchMFA": "Code does not match. Please try again.",
  "errorCodeOCPP": "Error Code (OCPP)",
  "errorCodeRelationAddError": "Failed to add error code relations",
  "errorCodeRelationAddSuccess": "Successfully added error code relations",
  "errorCodeRelationDeleteError": "Unable to delete Error Code relation",
  "errorCodeRelationDeleteSuccess": "Deleted Error Code relation",
  "errorCodeRelationRemoveError": "Failed to remove error codes relations",
  "errorCodeRelationRemoveSuccess": "Successfully removed error codes relations",
  "errorCodeRelationReplaceError": "Failed to replace error code relations",
  "errorCodeRelationReplaceSuccess": "Successfully replaced error code relations",
  "errorCodes": "Error Codes",
  "errorCodesAddToFirmware": "Add Error Codes to Firmware",
  "errorCodesConflicts": "Error Code Conflicts",
  "errorCodesDelete": "Delete Error Codes",
  "errorCodesDeleteConfirm": "Are you sure you want to delete the firmware error codes?",
  "errorCodesManage": "Manage Error Codes",
  "errorCodesNoneSelected": "No error codes selected",
  "errorDescription": "Error Description",
  "errorDetails": "Error Details",
  "errorManagement": "Error Management",
  "errorMessage": "Error message",
  "errorNotAuthorized": "Incorrect username or password",
  "errorPriceUnit": "Form contains price entries with same unit type",
  "errorScenarios": "Error Scenarios",
  "errorSessionExpiredMFA": "Session expired. Please refresh the page.",
  "errorSettings": "Error settings",
  "errorSettingsCreateDescription": "An error, sent in StatusNotification by OCPP, is considered to be a combination of ErrorCode (OCPP protocol) & Vendor Error (specified by the Firmware). With an error setting you can specify which priority an error should have and add a description to make it easier to prioritize errors.",
  "errorSettingsCreated": "Error Setting successfully created",
  "errorSettingsDeleteConfirm": "Are you sure you want to delete Error Setting from selected Error Scenario ?",
  "errorSettingsDuplicate": "Following organizations have already settings for this error scenario: {{organizations}}",
  "errorSettingsListDescription": "Manage charger errors - set priority and description for a specific error (OCPP and Vendor Error) to enable more efficient problem solving",
  "errorSettingsUpdated": "Error Setting successfully updated",
  "errorSummary": "Error summary",
  "errorType": "Error type",
  "errorUploadingFile": "An error occurred while uploading the file {{filename}}",
  "error__cant_remove_key_in_debt": "Can't delete a key having debt",
  "error_key_in_debt": "Can't delete a key having debt",
  "error_operation_not_allowed": "Operation not allowed",
  "errors": "Errors",
  "estimatedArrival": "Estimated arrival",
  "eventId": "Event ID",
  "eventLog": "Event Log",
  "eventLogDetailsAccepted": "Accepted",
  "eventLogDetailsBlocked": "Blocked",
  "eventLogDetailsNotAtThisLocation": "Not at this location",
  "eventLogDetailsRejected": "Rejected",
  "eventLogsFetchError": "Failed to get Event Log messages",
  "eventSelection": "Event selection",
  "eventSource": "Event Source",
  "eventType": "Event Type",
  "evse%": "EVSE%",
  "evseDelete": "Delete EVSE",
  "evseDeleteConfirm": "Do you want to delete this EVSE?",
  "evseDeleteError": "Failed to delete EVSE",
  "evseDeleteSuccess": "Successfully deleted EVSE",
  "evseDeleted": "EVSE deleted",
  "evseHasBeenDeleted": "This EVSE has been deleted at {{date}}.",
  "evseId": "EVSE ID",
  "evseMappingCreate": "Add evse mapping",
  "evseUidExternal": "External EVSE UID",
  "exactMatch": "(exact match)",
  "exclVat": "Excl VAT",
  "excludeError": "Exclude error from filter",
  "excludeSetting": "Exclude from synchronization",
  "excludedError": "Excluded Error(s)",
  "excludingVat": "excluding VAT",
  "existingPriceProfile": "Existing price profile",
  "expandSidebar": "Expand sidebar",
  "expiration": "Expiration",
  "expirationAmount": "Quantity",
  "expirationUnits": "Units",
  "expireDate": "Expiry Date",
  "expiryDate": "Expiry date",
  "export": "Export",
  "exportAndDownload": "Export & Download CSV File",
  "exportBy": "Export by",
  "exportCdrImport": "Export unimported rows",
  "exportFailedCdrError": "Failed to export",
  "exportFailedCdrSuccess": "Export successful. An email will be sent to {{email}}.",
  "exportOptions": "Export Options",
  "external": "External",
  "externalAccount": "External account",
  "externalAdd": "Add external payment account",
  "externalAddError": "Unable to add external payment account",
  "externalAddSuccess": "Successfully added external payment account",
  "externalCDR": "External CDR ID",
  "externalCPOConnectedError": "The External CPO selected it is connected already, please try another for integration!",
  "externalChargingSessions": "External charging sessions",
  "externalChargingStation": "External charging station",
  "externalChargingStations": "External charging stations",
  "externalConnectorDeleteError": "Failed to delete External Connector",
  "externalConnectorDeleteSuccess": "Successfully deleted External Connector",
  "externalConnectorGroup": "External connector group",
  "externalConnectorGroupName": "External connector group name",
  "externalConnectorGroupNoRules": "This external connector group has no rules.",
  "externalConnectorGroups": "External connector groups",
  "externalConnectorGroupsFetchError": "Failed to fetch external connector groups",
  "externalConnectorGroupsInfoHeader": "Group connectors from external CPOs",
  "externalConnectorGroupsInfoText": "Overview of groups of connectors based on certain criterias to create pricing rules.",
  "externalDescription": "External payment account",
  "externalEvseDeleteDescription": "EVSE including connectors will be deleted. You cannot undo this action, but you will still be able to view the EVSE data.",
  "externalId": "External ID",
  "externalIdInUse": "External ID already in use!",
  "externalIdvalues": "DE*123; SE*456; NL*789",
  "externalLocationDeleteDescription": "Location including EVSEs and connectors will be deleted. You cannot undo this action, but you will still be able to view the Location data. ",
  "externalLocations": "External Locations",
  "externalOperatorConnectedError": "The Operator selected is already added, please select a different operator.",
  "externalOrganizationId": "External ID",
  "externalRemoveError": "Unable to remove external payment account. {{error}}",
  "externalRemoveSuccess": "Successfully removed external payment account",
  "externalSessionId": "External Session ID",
  "externalUid": "External UID",
  "facilities": "Facilities",
  "failReason": "Fail reason",
  "failed": "Failed",
  "failedKeys": "Failed keys",
  "failed_status": "FAILED",
  "false": "False",
  "favicon": "Favicon",
  "fee": "Fee",
  "feeDescription": "Fee description",
  "feeOptions": "Fee options",
  "feeRemoveConfirmation": "Do you want to remove these {{count}} fees?",
  "feeType": "Fee type",
  "feeUpdateSuccess": "Successfully updated a fee",
  "feesAddedSuccess": "Successfully added fees",
  "feesRemoveSuccess": "Successfully removed fees",
  "feesUpdateSuccess": "Successfully updated fees",
  "field": "Field",
  "file": "File",
  "fileError": "File error",
  "fileInfo": "File Info",
  "fileName": "File name",
  "fileSizeLimit": "File size is limited to {{fileMaxSizeInMB}}MB",
  "fileTokensInfo": "{{count}} tokens will be pushed",
  "fileUpload": "File Upload",
  "fileUploadSuccess": "Successfully uploaded file",
  "fileUploadTitle": "Drag and drop files here or click to upload",
  "filename": "Filename",
  "filter": "Filter",
  "finance": "Finance",
  "findIntegration": "Find integration",
  "firmware": "Firmware",
  "firmwareAdd": "Add Firmware",
  "firmwareAddNew": "Add New Firmware",
  "firmwareAddTo": "Add to Firmware",
  "firmwareDetails": "Firmware Details",
  "firmwareErrorCodeDeleteConfirm": "Are you sure you want to delete the Error Code?",
  "firmwareErrorCodeDeleteError": "Failed to delete firmware error code",
  "firmwareErrorCodeDeleteSuccess": "Successfully deleted firmware error code",
  "firmwareErrorCodeUpdateError": "Failed to update firmware error code",
  "firmwareErrorCodeUpdateSuccess": "Successfully updated firmware error code",
  "firmwareInstall": "Install Firmware",
  "firmwareInstallError": "Failed to install new firmware",
  "firmwareInstallSuccess": "Installing new firmware, it might take a few minutes before the Charging Station comes back online",
  "firmwareInstalling": "Installing new firmware",
  "firmwareLatestReported": "Latest firmware version reported by Charging Station",
  "firmwareRemove": "Remove Firmware",
  "firmwareRemoveFrom": "Remove from Firmware",
  "firmwareReplaceFor": "Replace for Firmware",
  "firmwareUpdateError": "Failed to update Firmware",
  "firmwareUpdateSuccess": "Successfully updated Firmware",
  "firmwareUploadError": "Firmware upload failed: {{error}}",
  "firmwareUploading": "Uploading Firmware",
  "firmwareVersion": "Firmware Version",
  "firmwares": "Firmwares",
  "firmwaresAvailable": "Available Firmwares",
  "firstName": "First Name",
  "firstReceived": "First received at",
  "firstReceivedFrom": "First received from",
  "firstReceivedTo": "First received to",
  "firstTier": "First tier",
  "fixedDiscount": "Fixed discount",
  "fixedFees": "Fixed fees",
  "fleet": "Fleet",
  "fleetChargingSessionsExport": "Export Fleet Charging Sessions",
  "fleetChargingSessionsReportExport": "Generate session history report",
  "fleetChargingSessionsReportExportError": "Unable to export charging sessions summary",
  "fleetDriverDeleteError": "An error occurred while removing the user from the fleet.",
  "fleetDriverDeleteSuccess": "The user has been successfully deleted from fleet.",
  "fleetDriverInvitedError": "Unfortunately an error occurred while sending the invite.",
  "fleetDriverInvitedSuccess": "Thank you, we have received your request. The user will receive an email in case he/she is already registered the platform.",
  "fleetDrivers": "Fleet Drivers",
  "fleetDriversInvite": "Invite Fleet Driver",
  "fleetFeeAmount": "Fee amount",
  "fleetFeeInformation": "Fleet fee information",
  "fleetFeeUpdateError": "An error occurred when updating the fleet fee.",
  "fleetFeeUpdateSuccess": "Fleet fee updated successfully",
  "fleetFeeVatPercent": "Fleet fee VAT",
  "fleetMembership": "Fleet membership",
  "fleetNamePlaceholder": "Fleet name",
  "fleetOrganization": "Fleet organization",
  "floorLevel": "Floor level",
  "forever": "Forever",
  "forgotPassword": "Forgot your password?",
  "formFieldsFetchError": "Could not fetch form fields!",
  "formValidationError": "Validation Error: Please check your input values.",
  "format": "Format",
  "fortumCertified": "Fortum Certified",
  "forwardChargingSessions": "Forward charging sessions",
  "freeOfCharge": "Free of charge",
  "freeOfChargeForAllSupportedCountries": "Free of charge for all supported countries",
  "from": "From",
  "fromDate": "From date",
  "fromStartTime": "From: Start Time",
  "ftpUrlNotFound": "No FTP URL found",
  "gatewayProtocol": "Gateway protocol",
  "gender": "Gender",
  "general": "General",
  "generalInformation": "General Information",
  "generalInformationFetchedError": "Unable to fetch General Information",
  "generalReportsTab": "General",
  "generateFile": "Generate file",
  "generatePrepaidReportForCampaign": "Export prepaid report for this campaign - contains a row for every redeemed prepaid code and how much of the credit is used and how much is left to ve consumed",
  "geoDistanceInMeters": "Geo distance",
  "getDiagnostics": "Get Diagnostics",
  "goBack": "Go back",
  "goLive": "Go live",
  "goToGroupPriceAssociations": "Go to group associations",
  "goToGroupPriceAssociationsHint": "For more advanced options, please create price profile association for connector groups.",
  "goToOrganization": "Go to organization",
  "goToSignIn": "Go to sign in page",
  "gracePeriod": "Grace period",
  "gracePeriodDeletion": "30 day data storage before deletion.",
  "gracePeriodType-ABSOLUTE": "Fixed",
  "gracePeriodType-RELATIVE": "Variable",
  "gracePeriodTypeOfPeriod-DAILY": "day",
  "gracePeriodTypeOfPeriod-MONTHLY": "month",
  "graceRule": "Grace Rule",
  "groupMaxCurrent": "Group max current [A]",
  "groupPrices": "Group prices",
  "handshakeButtonText": "Initiate Handshake",
  "handshakeButtonUpdate": "Update Handshake",
  "handshakeFailed": "Handshake Failed",
  "handshakeResetFailed": "Reset Handshake Failed",
  "handshakeResetSuccess": "Reset Handshake Successful",
  "handshakeSuccess": "Handshake Successful",
  "handshakeText": "Initiate handshake to continue setting up the integration",
  "handshakeUpdate": "Update handshake integration",
  "handshakeUpdateFailed": "Handshake update failed",
  "handshakeUpdated": "Handshake Updated Successfully",
  "hardReset": "Hard Reset",
  "hardResetInitializedInfo": "Hard Reset initialized, it may take a while for the Charging Station to come back online",
  "hardResetRequired": "Successfully synced settings, but hard reset required",
  "hardResetRequiredChargingStation": "Hard reset required for Charging Station to update for keys:",
  "hardResetRequiredKey": "Hard reset required for some keys and some keys failed to synchronize",
  "headers": "Headers",
  "heartbeatInterval": "Heartbeat interval",
  "heliosNumber": "Helios Number",
  "help": "Help",
  "hexadecimal": "Hexadecimal",
  "hexadecimalBigEndian": "Hexadecimal - Big endian",
  "hexadecimalLittleEndian": "Hexadecimal - Little endian",
  "hidden": "Hidden",
  "hide": "Hide",
  "hideAdvancedFields": "Hide advanced fields",
  "hideAdvancedFilters": "Hide advanced filters",
  "hideEndpoint": "hide endpoint",
  "hideFullText": "Hide full text",
  "hideToken": "hide token",
  "highlightWierdLabel": "Highlight CPO Display Names with numbers or symbols",
  "home": "Home",
  "homeChargers": "Home Chargers",
  "homeChargersAddMappingError": "Could not add charge point as home charger.",
  "homeChargersAddMappingSuccess": "Added home charger to user",
  "homeChargersNoAssociations": "No chargers are in relation to the user.",
  "homeChargersRemoveMappingError": "Could not remove home charger from user",
  "homeChargersRemoveMappingSuccess": "Removed home charger from user",
  "homeCharging": "Home Charging",
  "homeChargingExemptVAT": "Home Charging (Exempt VAT)",
  "homeChargingFor": "Home Charging for {{header}}",
  "hour": "hour",
  "hours": "hours",
  "howToFillTemplate": "How to fill in this template:",
  "iAcceptTerms": "I accept the terms and conditions",
  "iAm": "I belong to",
  "iban": "IBAN",
  "ibanEndingWith": "IBAN ending with {{ibanLastFour}}",
  "id": "ID",
  "identityDocument": "Identity document",
  "ignored_status": "IGNORED",
  "image": "Image",
  "immediateDeletion": "Immediate deletion.",
  "import": "Import",
  "importCdrFile": "Import CDR file",
  "importCdrs": "Import CDRs",
  "importChargingKeyReservations": "Import Charging Key Reservations",
  "importedBy": "Imported by",
  "importedFiles": "Imported files",
  "in_progress": "In progress",
  "inactive": "Inactive",
  "inclVat": "Incl VAT",
  "includeDeleted": "Include deleted",
  "includeError": "Include error for filtering",
  "includedError": "Included Error(s)",
  "includingVat": "including VAT",
  "incorrectColumnSize": "Incorrect columns size at line: {{line}}",
  "incorrectColumnValue": "Incorrect value at column {{column}}, line {{line}}",
  "incorrectColumnValueType": "Incorrect key number format at line: {{line}}",
  "incorrectCsvFile": "Incorrect CSV file",
  "infoUpdatedOnError": "Failed to update info on {{entity}}",
  "infoUpdatedOnSuccess": "Updated info on {{entity}}",
  "informationPopupConfirm": "OK",
  "inheritedBrandingLogoRemoveError": "Cannot remove inherited logotype",
  "initialFee": "Initial fee",
  "initiatedDate": "Initiated date",
  "inoperative": "Inoperative",
  "inputInvalidTokensTooltip": "The input should be the list of comma separated keyIdentifiers (OCPI - token uids)",
  "inputTokensTooltip": "The input should be a list of comma separated token uids",
  "inputValues": "Input values",
  "install": "install",
  "installationStatus": "Installation status",
  "installationTime": "Installation Time",
  "integrationDetails": "Integration Details",
  "integrationNotFound": "No integration found",
  "integrationOwner": "Integration owner",
  "integrationVia": "Integration via",
  "integrations": "Integrations",
  "internal": "Internal",
  "internalName": "Internal name",
  "invalidActiveFromDate": "Valid from date has to be today or in the future",
  "invalidActiveToDate": "Valid to date has to be in the future",
  "invalidDate": "Invalid date",
  "invalidEmail": "Email addresses invalid",
  "invalidSyntaxAt": "Invalid syntax at \"{{location}}\"",
  "invalidVatNumber": "Incorrect format, must start with country code (two letters) followed by the number",
  "invalidVerificationCode": "Invalid verification code",
  "invalidXhtmlTemplateDetailedError": "Unable to parse {{templatePartName}}XHTML template due to issue on line [{{lineNumber}}:{{columnNumber}}]: {{message}}",
  "invalid_request_parameters": "Invalid request",
  "invalid_request_valid_to_before_valid_from": "Active from date has to be today or in the future",
  "invalid_vin_format": "Invalid VIN format",
  "invoice": "Invoice",
  "invoiceAmount": "Invoice amount",
  "invoiceApprove": "Approve Invoice",
  "invoiceApproveError": "Invoice could not be approved",
  "invoiceApproved": "Invoice successfully approved",
  "invoiceBlockError": "Invoice could not be blocked",
  "invoiceBlocked": "The invoice has been blocked.",
  "invoiceDate": "Invoice date",
  "invoiceDetailsTemplate": "Invoice Details",
  "invoiceDownloadError": "Unable to download invoice",
  "invoiceNotModifiableError": "Operation not allowed because invoice for the month of {{month}} is not modifiable.",
  "invoiceNumber": "Invoice Number",
  "invoicePaid": "Invoice was successfully paid",
  "invoicePayError": "Error paying the invoice",
  "invoicePeriod": "Invoice period",
  "invoiceSEPAPaid": "A new SEPA request has been sent to the bank",
  "invoiceStatusApproved": "APPROVED",
  "invoiceStatusBlocked": "BLOCKED",
  "invoiceStatusCreated": "CREATED",
  "invoiceStatusDisputeLost": "DISPUTE LOST",
  "invoiceStatusDisputeWon": "DISPUTE WON",
  "invoiceStatusDisputed": "DISPUTED",
  "invoiceStatusDraft": "DRAFT",
  "invoiceStatusFinalized": "FINALIZED",
  "invoiceStatusOverdue": "OVERDUE",
  "invoiceStatusPaid": "PAID",
  "invoiceStatusPastDueDate": "PASTDUEDATE",
  "invoiceStatusPostponed": "POSTPONED",
  "invoiceStatusRefunded": "REFUNDED",
  "invoiceStatusWrittenOff": "WRITTENOFF",
  "invoiceSummaryTemplate": "Invoice Summary",
  "invoiceText": "Text on the invoice",
  "invoiceUnblockError": "Invoice could not be unblocked",
  "invoiceUnblocked": "The invoice has been unblocked.",
  "invoices": "Invoices",
  "invoicesApprove": "Approve ALL invoices",
  "invoicesApproveConfirm": "Are you sure you want to approve and send all invoices with status CREATED?",
  "invoicesApproveError": "No invoices were approved",
  "invoicesApproved": "All invoices were approved successfully",
  "invoicesFor": "Invoices for {{name}}",
  "invoicesPayConfirm": "Are you sure you want to pay this invoice?",
  "invoicesWriteOffConfirm": "Are you sure you want to write off this invoice?",
  "isContainerApp": "Is container App/Webportal",
  "isoCode": "Iso code",
  "issueDeleteConfirm": "Are you sure you want to delete this issue?",
  "issueDeleteError": "Could not delete issue",
  "issueFindError": "Could not find issue",
  "issueNew": "Write a new issue",
  "issueSaveError": "Could not save issue",
  "issues": "Issues",
  "issuesFetchError": "Could not get issues",
  "issuesShowMore": "Show more issues",
  "item": "Item",
  "itemSelected": "item selected",
  "items": "Items",
  "itemsPerPage": "Items per page",
  "itemsSelected": "items selected",
  "jobDeleteError": "Error! Job wasn't successfully deleted",
  "jobDeleted": "Job successfully deleted",
  "jobDescriptionIntegration": "The following jobs will be created - add or edit if needed or skip this and create by default editable",
  "jobResetError": "Error! Job wasn't successfully reset",
  "jobResetSuccess": "Job successfully reset",
  "jobStartError": "Error! Job wasn't successfully started",
  "jobStarted": "Job successfully started",
  "jobs": "Jobs",
  "kanaAddressLine1": "Line 1 (kana)",
  "kanaCompanyAddress": "Company address (kana)",
  "kanaFirstName": "First name (kana)",
  "kanaLastName": "Last name (kana)",
  "kanaPersonalAddress": "Personal address (kana)",
  "kanaPostalCode": "Postal code (kana)",
  "kanaState": "State (kana)",
  "kanaTown": "Town (kana)",
  "kanjiAddressLine1": "Line 1 (kanji)",
  "kanjiCompanyAddress": "Company address (kanji)",
  "kanjiFirstName": "First name (kanji)",
  "kanjiLastName": "Last name (kanji)",
  "kanjiPersonalAddress": "Personal address (kanji)",
  "kanjiPostalCode": "Postal code (kanji)",
  "kanjiState": "State (kanji)",
  "kanjiTown": "Town (kanji)",
  "keyAlreadyCreated": "The key was successfully identified and is already activated. Here is the <0>link</0>.",
  "keyAlreadyExists": "The key already exists.",
  "keyConfiguration": "Key configuration",
  "keyConfigurationInfo": "Please choose these options carefully as they will impact the whole batch at once.",
  "keyIdentifier": "Serial number",
  "keyInformation": "Key information",
  "keyInformationSectionMessage": "Our system automation will fetch most of the necessary info if the key is already in the Reservation database.",
  "keyMissing": "Key missing",
  "keyName": "Key Name",
  "keyNameDescription": "The key name should contain the name of your organization",
  "keyNumber": "Serial Number (Provided by the key manufacturer)  ",
  "keyNumberFormat": "Key Number Format (Set by the key manufacturer)",
  "keyNumberFormatDescription": "Set by the key manufacturer",
  "keyNumberFormats": "Key Number Formats",
  "keyReservationFound": "The key was successfully identified in the reservation database.",
  "keyReservationNotFound": "The key wasn't identified on the reservation database. To proceed, please enter the charging key details.",
  "keyType": "Key Type",
  "keyTypeDescription": "Choose Tag or Card",
  "km": "km",
  "label": "Label",
  "labelPrefix": "Label (Prefix)",
  "labels": "Labels",
  "lang-ar": "Arabic",
  "lang-bg": "Bulgarian",
  "lang-cy": "Welsh",
  "lang-da": "Danish",
  "lang-de": "German",
  "lang-en": "English",
  "lang-es": "Spanish",
  "lang-et": "Estonian",
  "lang-fi": "Finnish",
  "lang-fr": "French",
  "lang-hi": "Hindi",
  "lang-hu": "Hungarian",
  "lang-it": "Italian",
  "lang-lt": "Lithuanian",
  "lang-lv": "Latvian",
  "lang-nb": "Norwegian",
  "lang-nl": "Dutch",
  "lang-pl": "Polish",
  "lang-sv": "Swedish",
  "language": "Language",
  "languageUpdateError": "Unable to update language on user",
  "languagesToAdd": "Languages to add",
  "lastChargingSession": "Last charging session",
  "lastContact": "Last status update: {{lastContact}}",
  "lastDigits": "Last digits",
  "lastModified": "Last Modified",
  "lastNDays": "Last {{count}} days",
  "lastNHours": "Last {{count}} hours",
  "lastNMonths": "Last {{count}} months",
  "lastNWeeks": "Last {{count}} weeks",
  "lastName": "Last Name",
  "lastReceived": "Last received at",
  "lastReceivedFrom": "Last received from",
  "lastReceivedTo": "Last received to",
  "lastReported": "Last Reported",
  "lastReportedTime": "Last reported: {{lastReportedTime}}",
  "lastRunCompletedAt": "Last run completed at",
  "lastRunStartedAt": "Last run started at",
  "lastStatusUpdate": "Last status update",
  "lastSynchronized": "Last synchronized",
  "lastTier": "Last tier",
  "lastUpdated": "Last updated",
  "lastUpdatedCpoSystem": "Last updated in the CPO system",
  "lastUpdatedCpoSystemDescription": "Indicates when the EVSE was last updated in the CPO system. For Hubject EVSEs it is also updated when we receive a status update from the CPO.",
  "lastUpdatedCpoSystemLocationDescription": "Indicates when the Location was last updated in the CPO system. Note that Hubject locations are virtual, created in our system only, as part of grouping Hubject EVSEs.",
  "lastUpdatedFrom": "Last updated from",
  "lastUpdatedTo": "Last updated to",
  "latitude": "Latitude",
  "legalNoticeUrl": "Legal notice URL",
  "libellePrelevementField": "Libellé de prélèvement",
  "licensePlate": "License Plate",
  "licensePlateExactMatch": "\nLicense Plate (exact match)",
  "licensePlates": "License Plates",
  "limit": "Limit",
  "link": "Link",
  "listOfTokens": "List of tokens",
  "listeningToEvents": "Listening to events",
  "loadBalancing": "Load Balancing",
  "loadBalancingConfig": "Load Balancing Configuration",
  "loadBalancingConfigDeleteConfirm": "Do you want to delete this Load Balancing Configuration?",
  "loadBalancingConfigDeleteError": "Failed to delete Load Balancing Configuration",
  "loadBalancingConfigDeleteSuccess": "Load Balancing Configuration deleted",
  "loadBalancingConfigUpdatedError": "Failed to update Load Balancing Configuration.",
  "loadBalancingConfigUpdatedSuccess": "Update Load Balancing Configuration",
  "loadBalancingGroup": "Load Group",
  "loadBalancingGroupAdd": "Add Load Balancing Group",
  "loadBalancingGroupAddNew": "Add New Load Balancing Group",
  "loadBalancingGroupCreateError": "Failed to create Load Group",
  "loadBalancingGroupCreateSuccess": "New Load Balancing Group has been created",
  "loadBalancingGroupFetchError": "Failed to fetch Load Group",
  "loadBalancingGroupUpdateError": "Failed to update Load Group",
  "loadBalancingGroupUpdateSuccess": "Updated Load Group",
  "loadBalancingGroupUpdatedChargingStationsError": "Failed to updated connected Charging Stations",
  "loadBalancingGroupUpdatedChargingStationsSuccess": "Updated connected Charging Stations",
  "loadBalancingGroups": "Load Balancing Groups",
  "loadLabelsError": "Failed to load labels. {{error}}",
  "loadRuleSchemaError": "Failed to load rule schema. {{error}}",
  "loadSessionRuleSchemaError": "Failed to load rule schema. {{error}}",
  "loading": "Loading...",
  "loadingComponentError": "Failed to load component",
  "local": "Local",
  "localization": "Localization",
  "location": "Location",
  "locationAdd": "Add location",
  "locationAddCsv": "Create locations from a CSV file",
  "locationAddedError": "Failed to add Location",
  "locationAddedSuccess": "Successfully added location",
  "locationAndOwnership": "Location & ownership",
  "locationDelete": "Delete Location",
  "locationDeleteConfirm": "Do you want to delete this Location?",
  "locationDeleteError": "Failed to delete location",
  "locationDeleteSuccess": "Successfully deleted Location",
  "locationDeleteWarning": "Once the locations are deleted, cannot be restored by pulling them again. Also, the CDRs received over these locations will not be processed once deleted. ",
  "locationDeleted": "Location deleted",
  "locationDetails": "Location Details",
  "locationEditedError": "Failed to update location",
  "locationEditedSuccess": "Successfully edited location",
  "locationFetchedError": "Unable to fetch Location",
  "locationFreeTextInfo": "This will appear in the app.",
  "locationHasBeenDeleted": "This location has been deleted at {{date}}.",
  "locationId": "Location ID",
  "locationIdExternal": "External Location ID",
  "locationOrSite": "Location / Site",
  "locationOwner": "Location owner",
  "locationType": "Location Type",
  "locationUptime": "Location Uptime",
  "locationUtilisation": "Location Utilization",
  "locationUtilisationCannotBeTriggered": "Location is either empty or calculation cannot be triggered.",
  "locations": "Locations",
  "locationsCreateInstructionHeading": "Please follow these steps to create many locations from a CSV file",
  "locationsCreateInstructionStep1": "Download the latest version of the CSV template here:",
  "locationsCreateInstructionStep2": "Fill out the CSV template with your data, <0>please see the hints below</0>",
  "locationsCreateInstructionStep3": "Upload your CSV file (You can add up to 4k locations at once)",
  "locationsCreated": "{{count}} locations successfully created.",
  "locationsCreating": "Upload in progress, do not close this browser tab.",
  "locationsNotCreatedDueToInvalidData": "{{count}} locations could not be created due to incorrect data",
  "locationsNotCreatedDueToInvalidDataDescription": "Please validate and edit the data in these rows and upload your file again.\nIn case you can not find the problem, please create a support ticket for assistance.",
  "lockInPeriodDuration": "Lock in period ",
  "lockInPeriodDurationTooltip": "Lock in period - this controls when the user can end the subscription themselves. A system user can always end a users subscription in the web",
  "lockInPeriodUntil": "Lock in period until",
  "logMessage": "Log Message",
  "loginChangePasswordHeader": "Change password",
  "loginEmailPlaceholder": "user@company.com",
  "loginMFAHeader": "Two-factor authentication",
  "loginPasswordPlaceholder": "Type your password",
  "loginResetPasswordHeader": "Request new password",
  "loginSetupMFAHeader": "Two-factor authentication setup",
  "loginSignInHeader": "Sign in to your account",
  "logotype": "Logotype",
  "longitude": "Longitude",
  "mOfNSelected": "{{count}} of {{totalCount}} selected",
  "mainPartner": "main partner",
  "makeItPrivate": "Make it Private",
  "makeItPublic": "Make it Public",
  "manageCookiePreferences": "Manage cookie preferences",
  "manageErrorSettings": "Manage error settings",
  "manual": "Manual",
  "manualPriceDescription": "Manual price description",
  "manualTariffModule": "Manual tariff module",
  "manuallyCreate": "Manually create",
  "manufacturer": "Manufacturer",
  "manufacturerName": "Manufacturer name",
  "map": "Map",
  "mappingFile": "Mapping file",
  "mappingUpdatedError": "Failed to update mappings",
  "mappingUpdatedSuccess": "Successfully updated mappings",
  "markAsClosed": "Mark as closed",
  "markAsOpen": "Mark as open",
  "marketplace": "Marketplace",
  "marketplaceAgreementDate": "Marketplace Agreement Date",
  "markup": "Markup",
  "markupCurrencyInfo": "This markup will be applied to associated price profiles matching this currency.",
  "markupDescriptionInfo": "Automatically based on the price profile which the markup is associated with",
  "markupInformation": "This markup will be applied to all price segments in the associated price profiles",
  "masterConfiguration": "Master Configuration",
  "maxEntries": "Max Entries",
  "maxKwh": "Maximum kWh",
  "maxPrice": "Max price",
  "memberCount": "Member count",
  "membersCount": "Customers",
  "message": "Message",
  "messageDirection": "Message Direction",
  "messageType": "Message Type",
  "messageTypesDeselect": "Deselect All Message Types",
  "messageTypesSelectAll": "Select All Message Types",
  "method": "Method",
  "mfaCode": "Authentication code from app",
  "mfaCodeConfirm": "Enter the authentication code from your authentication app.",
  "mfaSetupDescription": "Please scan this QR code with an authentication app of your choice. We recommend Google Authenticator, Microsoft Authenticator, 1Password or Authy.",
  "minKwh": "Minimum kWh",
  "minimalCommitmentPeriod": "Minimal commitment period",
  "minute": "minute",
  "minuteOrMinutes": "minute(s)",
  "minutes": "Minutes",
  "missing": "Missing",
  "missingEvseId": "Missing EVSE ID",
  "missingExternalCpoIntegrationForAgreement": "No integration found! When adding an external CPO you need to have an integration setup before creating a Marketplace agreement.",
  "missingValidPaymentOption": "Provided organisation does not have a valid payment option",
  "missing_power_information": "Missing power information",
  "missing_price_information": "Missing price information",
  "mode": "Mode",
  "model": "Model",
  "modelConfiguration": "Model Configuration",
  "modelDetails": "Model Details",
  "modelName": "Model name",
  "models": "Models",
  "modify": "Modify",
  "month": "Month",
  "monthFrom": "Month from",
  "monthOfBirth": "Month of birth",
  "monthTo": "Month to",
  "monthly": "Monthly",
  "monthlyFees": "Monthly fees",
  "moreFilters": "More Filter",
  "multipleCKsummaryMessage": "You can wait for the result after confirming the import or view it later in the \"Imported files\" section.",
  "multipleCKuploadMessage": "Your file is being uploaded. Please wait",
  "multipleChargingKeyCreationTitle": "Multiple charging key creation",
  "multipleDays": "Multiple days",
  "multipleKeys": "Multiple keys",
  "multipleRedemption": "Multiple codes allowed",
  "multipleRedemptionTooltip": "If checked then a user can redeem multiple codes",
  "mustAccept": "You must accept",
  "mustBeBlank": "Must be blank",
  "mustBeValue": "Must be {{value}}",
  "myAssetOwners": "My Asset Owners",
  "myChargers": "I am the CPO",
  "myCustomers": "My customers",
  "name": "Name ",
  "nameMissing": "Name missing",
  "networkError": "Network Error",
  "networkFilter": "Network Filter",
  "networkFilteringConfirmation": "This action will hide locations for all users of the selected EMP. Any session started after the restriction will be rejected. Sessions currently in progress will go through and CDRs will be accepted as usual. ",
  "networkReceiver": "Network receiver",
  "newColors": "Colors used in new design",
  "newItemsAmount": "New items amount",
  "newMarkupPrice": "New markup price",
  "newOrders": "New Orders",
  "newPassword": "New password",
  "newRegularPrice": "New regular price",
  "newValue": "New value",
  "newVatValues": "New VAT values",
  "next": "Next",
  "nfc": "NFC",
  "nfcPrefix": "NFC prefix",
  "no": "No",
  "no-evseId": "UID: no-evseId-0",
  "noActiveFromDate": "no active from date",
  "noActiveToDate": "no active to date",
  "noBulkAssociationsFound": "No Price Profile Associations found",
  "noChargersForErrorScenario": "No chargers that are affected by this error scenario",
  "noChargersFound": "No chargers found",
  "noChargingKeys": "No charging keys yet!",
  "noChargingKeysInfo": "Here you will find your charging keys",
  "noConnectionsWarning": "There are currently no connections available for this EMP.",
  "noConnectorSpecificPrice": "No Price Profile is associated with the specific connector.",
  "noConnectorSpecificPriceInfo": "No Price Profile is associated with the specific connector. Group prices will be used.",
  "noConnectors": "No connectors linked",
  "noCposAddedToIntegration": "No CPOs added to this integration",
  "noDataText": "There is no data in this table",
  "noEndDate": "no end date",
  "noErrorScenarioFound": "This error scenario is not found",
  "noErrorScenarioSettingsFound": "This error scenario setting is not found",
  "noEvseMappingError": "No evse mapping for organization found! Either add one or manually input Charging Station ID Prefix",
  "noFilter": "No Filter",
  "noGroupAdded": "No group added",
  "noInformationProvided": "No information provided",
  "noLocationUtilisationCalculationData": "Old calculation detected. Preparing new utilization calculation.",
  "noOfDaysLabel": "Number of days",
  "noOfDaysPlaceholder": "e.g. 2",
  "noOfFailed": "# of failed",
  "noOfFreeSessions": "Number of free sessions",
  "noOfIgnored": "# of ignored",
  "noOfPaidTransactions": "Number of paid transactions",
  "noOfRejected": "# of rejected",
  "noOfRows": "# of rows",
  "noOfSessions": "# of Sessions",
  "noOfUnit": "Number of unit",
  "noOptions": "No options",
  "noOrgPriceProfiles": "This organization does not have any price profiles.",
  "noPartnersAddedToIntegration": "No partners added to integration",
  "noPaymentMethodsProvided": "There are no available payment methods provided",
  "noPermissions": "No permissions",
  "noPriority": "No priority",
  "noProfilesAvailable": "No Profiles Available",
  "noReportedProblemsInfo": "No problems have been reported on your chargers by your customers",
  "noStartMethodsAvailable": "No start methods available",
  "noStripeAccount": "No Stripe account registered",
  "noSubCposAdded": "No sub-CPOs are added for this integration",
  "noTrialForSubscription": "The subscription doesn't have a grace period",
  "none": "None",
  "notFound": "Not Found",
  "notFoundErrorHeading": "Oops! Page not found",
  "notFoundErrorMessage": "It seems you've taken a wrong turn. There is no such page. But we have many other pages, try starting with the home page.",
  "notLargerThan": "Must not be larger than {{size}}",
  "notLessThan": "Must not be less than {{size}}",
  "notLongerOrShorterThanCharacters": "Must not be longer than {{max}} or shorter than {{min}} characters.",
  "notLongerThan": "Must not be longer than {{length}}",
  "notLongerThanCharacters": "Must not be longer than {{max}} characters",
  "notShorterThan": "Must not be shorter than {{length}}",
  "notSubscribed": "Not subscribed to any subscriptions",
  "notValidWebportalTheme": "Not valid Webportal theme",
  "note": "Note",
  "notes": "Notes",
  "notificationProfileAdd": "Add Notification Profile",
  "notificationProfileAddNew": "Add New Notification Profile",
  "notificationProfileCreatedError": "Failed to create notification profile",
  "notificationProfileCreatedSuccess": "Notification Profile created",
  "notificationProfileDeleteConfirm": "Delete Notification Profile?",
  "notificationProfileDeletedError": "Failed to delete notification profile",
  "notificationProfileDeletedSuccess": "Notification Profile deleted",
  "notificationProfileUpdatedError": "Failed to update notification profile",
  "notificationProfileUpdatedSuccess": "Notification Profile updated",
  "notificationProfiles": "Notification Profiles",
  "numberOfAffectedChargersLastNDays": "{{count}} affected chargers in last {{days}} days",
  "numberOfAffectedChargersLastNMonths": "{{count}} affected chargers in last {{months}} months",
  "numberOfAffectedChargersLastNWeeks": "{{count}} affected chargers in last {{weeks}} weeks",
  "numberOfDays": "{{count}} day",
  "numberOfDays_plural": "{{count}} days",
  "numberOfHours": "{{count}} hour",
  "numberOfHours_plural": "{{count}} hours",
  "numberOfMins": "{{count}} minute",
  "numberOfMins_plural": "{{count}} minutes",
  "numberOfMonths": "{{count}} month",
  "numberOfMonths_plural": "{{count}} months",
  "numberOfWeeks": "{{count}} week",
  "numberOfWeeks_plural": "{{count}} weeks",
  "numberOfYears": "{{count}} year",
  "numberOfYears_plural": "{{count}} years",
  "ocpiConfigUpdateError": "Failed to update the configuration",
  "ocpiConfigUpdateSuccess": "Successfully updated the configuration",
  "ocpiDirect": "OCPI (Direct)",
  "ocpiGenericHub": "OCPI (Generic hub)",
  "ocpiGireveHub": "OCPI (Gireve hub)",
  "ocpiIntegration": "OCPI integration",
  "ocpiRestrictionsAfterDuration": "After {{minDuration}}",
  "ocpiRestrictionsBetweenDuration": "Between {{minDuration}} and {{maxDuration}}",
  "ocpiRestrictionsFirstDuration": "First {{maxDuration}}",
  "ocpiRestrictionsKwhAbove": "When energy consumption above {{minKwh}}",
  "ocpiRestrictionsKwhBetween": "When energy consumption between {{minKwh}} and {{maxKwh}}",
  "ocpiRestrictionsKwhLessThan": "When energy consumption less than {{maxKwh}}",
  "ocpiVersionUpgradeText": "This section allows you to upgrade the OCPI version for this integration. Pick a higher version and update the handshake. Version upgrades shall always be agreed with the partner before executed.",
  "ocppLog": "OCPP Log",
  "ocppLogsMessagesGetError": "Failed to get Ocpp Log messages",
  "offerings": "Offerings",
  "offline": "Offline",
  "offlineTrigger": "Offline Trigger",
  "offlineTriggers": "Offline Triggers",
  "offset": "Offset",
  "ok": "Ok",
  "oneTime": "One time",
  "oneTimeFeeCreateError": "Unable to create one time fee",
  "oneTimeFeeCreateSuccess": "One time fee was created successfully",
  "oneTimeFeeDate": "Creation date",
  "oneTimeFeeDeleted": "Fee successfully removed",
  "oneTimeFeeNote": "Invoice Note",
  "oneTimeFeeRemoveConfirmation": "Do you want to remove these {{count}} one time fees?",
  "oneTimeFees": "One time fees",
  "ongoingSessions": "Ongoing Sessions",
  "online": "Online",
  "onlineOrOffline": "Online/offline",
  "onlyAppliesToPrivateChargers": "I want to create an association that only applies to private chargers",
  "openNewTab": "Open new tab",
  "openPDFReceipt": "Open PDF receipt",
  "openStripeWebFormError": "Unable to create Stripe account. Please try again or contact supprt.",
  "openingHours": "Opening hours",
  "operational": "Operational",
  "operationalStatus": "Operational Status",
  "operator": "Operator",
  "operatorEvseIDMappings": "Operator Evse ID Mappings",
  "operatorMappingDelete": "Delete Operator Mapping",
  "operatorMappingExternalIdDescription": "The values must be separated by the character ';' e.g. DE*123; SE*456; NL*789",
  "operatorMappingType": "Operator Mapping Type",
  "operatorMappingValuesDescription": "The values must be separated by the character ';' e.g. value 1; value 2; value 3",
  "operatorMappings": "Operator Mappings",
  "operatorMappingsTooltip": "Locations will be mapped to the CPO based on the subOperator/operator property",
  "operatorName": "Operator name",
  "operatorNamevalues": "value 1; value 2; value 3",
  "optionalFieldForExternalOrg": "Not required for external CPO and EMPs",
  "or": "or",
  "orDragAndDrop": "or drag and drop it here",
  "orderCreateDate": "Created Date",
  "orderDate": "Order Date",
  "orderId": "Order id",
  "orderNumber": "Order Number",
  "orderStatus": "Payment Status",
  "orderType": "Type",
  "orderedAfter": "Ordered after",
  "orderedBefore": "Ordered before",
  "orders": "Orders",
  "orgConfiguration": "Organization config",
  "orgPricing": "Price",
  "organization": "Organization",
  "organizationAccessibleFetchedError": "Something went wrong when accessing organizations",
  "organizationAdd": "Add Organization",
  "organizationAddBilling": "Add billing settings",
  "organizationAddError": "Unable to add organization",
  "organizationAddPaymentMethod": "Add payment method",
  "organizationAddSuccess": "Successfully added organization",
  "organizationAddSupporting": "Add supported organization",
  "organizationAddSupportingError": "Failed to add supported organization",
  "organizationAddSupportingSuccess": "Supported organization successfully added",
  "organizationCreate": "Create organization",
  "organizationCreatedAt": "Organization Created At",
  "organizationCreatedError": "Unabled to create Organization",
  "organizationCreatedSuccess": "Organization was created successfully.",
  "organizationCreation": "Organization creation",
  "organizationDetails": "Organization General Information",
  "organizationFetchedError": "Unable to fetch organization information",
  "organizationISupport": "Organizations I Support",
  "organizationKey": "Organization key",
  "organizationLabels": "Organization labels",
  "organizationMappings": "Organizations communicating over this connection",
  "organizationMissing": "You need to select an organization",
  "organizationModuleType": "Type",
  "organizationModules": "Organization Modules",
  "organizationName": "Organization Name",
  "organizationNameField": "Nom de l'organisation",
  "organizationNeedsChargingKeys": "Organization must have at least one Charging Key",
  "organizationNumber": "Organization Number",
  "organizationOrder": "Order",
  "organizationOrderCancelFailed": "Cancel organization order failed.",
  "organizationOrderCancelSuccess": "Organization order cancelled successfully.",
  "organizationOrders": "Organization Orders",
  "organizationPaymentMethod": "Payment Method",
  "organizationPaymentMethodEnabledError": "Unable to add payment method",
  "organizationPaymentMethodEnabledSuccess": "Successfully added payment method",
  "organizationPaymentMethodsUpdateError": "Unable to update the organization's payment methods",
  "organizationPaymentMethodsUpdateSuccess": "Successfully updated the organization's payment methods",
  "organizationPaymentProvider": "Payment Provider",
  "organizationRemoveAccessConfirm": "Are you sure you want to remove the organization access",
  "organizationRemoveAccessError": "Failed to remove supported organization.",
  "organizationRemoveError": "Unable to remove organization",
  "organizationRemoveSuccess": "Successfully removed organization",
  "organizationRemovedAccessSuccess": "Successfully removed supported organization",
  "organizationSessions": "Charging Sessions / Organization",
  "organizationSupporting": "Supported Organizations",
  "organizationType": "Type of Organization",
  "organizationUpdatedError": "An error occurred when updating the organization",
  "organizationUpdatedSuccess": "Organization updated!",
  "organizations": "Organizations",
  "originalProfile": "Original profile",
  "other": "Other",
  "outOfOrder": "Out of order",
  "outstandingAmount": "Outstanding Amount",
  "overrideVatRate": "Override VAT rate",
  "overview": "Overview",
  "owner": "Owner",
  "page": "Page",
  "pageNotFound": "Page not found",
  "parentOrganization": "Parent Organization",
  "parked_status": "PARKED",
  "parkingDuration": "Parking duration",
  "partner": "Partner",
  "partnerAdd": "Add Partner",
  "partnerAddNew": "Add new partner",
  "partnerApiKey": "Partner API Key",
  "partnerCDApiKey": "C&D API Key",
  "partnerConfigurationsFetchError": "Failed to load partner configurations",
  "partnerCountryCode": "Partner country code",
  "partnerCreateError": "Failed to create partner",
  "partnerCreateSuccess": "Successfully created partner",
  "partnerDelete": "Remove Partner",
  "partnerDeleteConfirm": "Remove partner for certain?",
  "partnerDeleteError": "Failed to delete partner",
  "partnerDetails": "Partner Details",
  "partnerDynamicDataPublishing": "Dynamic data publishing",
  "partnerErrorMessage": "Calling the partner URL failed",
  "partnerGenerateNewKey": "Generate new key",
  "partnerLoadError": "Failed to load partner info",
  "partnerName": "Partner Name",
  "partnerPartyId": "Partner Party Id",
  "partnerRemoveError": "Unable to remove partner",
  "partnerRemoveSuccess": "Successfully removed partner",
  "partnerToken": "Partner Token",
  "partnerType": "Partner Type",
  "partnerUpdateError": "Failed to update partner",
  "partnerUpdateSuccess": "Successfully updated partner",
  "partnerUrl": "Partner URL",
  "partnerVersionURL": "Partner Version URL",
  "partnerVirtualSystemKey": "Virtual System Key",
  "partners": "Partners",
  "partnersSince": "Partners since",
  "password": "Password",
  "passwordSentError": "Password can not be sent for this user.",
  "passwordSentSuccess": "Password has been sent to the user.",
  "payAsYouGo": "Pay as you go",
  "payNow": "Pay now",
  "payee": "Payee",
  "payerOrganization": "Payer",
  "payerOrganizationId": "Payer Organization",
  "payerType-B2B_CUSTOMER": "B2B Customer",
  "payerType-CPO_PUBLIC": "CPO Public",
  "payerType-EMP_PUBLIC": "EMP Public",
  "payerType-ORGANIZATION": "Organization",
  "payerType-USER_GROUP": "Customer group",
  "payment": "Payment",
  "paymentAccount": "Payment Account",
  "paymentInformation": "Payment Information",
  "paymentMethod": "Payment Method",
  "paymentMethodConfig_BILLING_CREDIT_CARD": "Billing credit card",
  "paymentMethodConfig_BILLING_SEPA_CREDIT_TRANSFER": "Billing SEPA credit transfer",
  "paymentMethodConfig_BILLING_SEPA_DEBIT": "Billing SEPA debit",
  "paymentMethodConfig_CHARGE_AND_DRIVE_PREPAID": "Prepaid",
  "paymentMethodConfig_ELWIN_INVOICE": "Enoro",
  "paymentMethodConfig_STRIPE_CREDIT_CARD": "Stripe credit card",
  "paymentMethodNotAssigned": "No payment method assigned",
  "paymentMethodType": "Type",
  "paymentMethods": "Payment Methods",
  "paymentMethodsCardTitle": "Payment info",
  "paymentModel": "Payment model",
  "payment_failed": "Payment failed",
  "paymentsConfiguration": "Payments Configuration",
  "payoutId": "Payout ID",
  "payoutReportExportCard": "Export Payouts Report as CSV",
  "payoutReportExportCardDescription": "Export the organisation's payouts report.Select the month the payout was received on and click \"Email CSV File\" to get an email sent to you",
  "payout_is_not_unique": "Multiple reports were found for the requested payout",
  "payout_not_found": "No report was found for the requested payout.",
  "payout_only_connected_account_allowed": "Payouts are only available for connect accounts",
  "payoutsReportTab": "Payouts",
  "payter": "Payter",
  "pdfFileSizeLimit": "Only .pdf file. {{fileMaxSizeInMB}} Mb max file size",
  "pendingAgreementTooltip": "When agreement is pending, you can still receive locations and tariffs from the CPO and push tokens to the CPO. The locations will not be shown to the user and charging authorizations will not work until the agreement is active.",
  "pendingDeletion": "Pending deletion",
  "percentFailureToStopTokenPush": "Failure stop percentage",
  "percentFailureToStopTokenPushTooltip": "Percentage of failed tokens to stop the token push job",
  "performedAction": "Performed Action",
  "periodDuration": "Period duration",
  "periodEnd": "Invoice Date",
  "periodStart": "Period Start",
  "periodType": "Period type",
  "permissionSelection": "Permission selection",
  "permissionUpdateSuccess": "Successfully updated permissions",
  "permissions": "Permissions",
  "personalAddress": "Personal address",
  "personalIdNumber": "Personal ID number",
  "personalInformation": "Personal information",
  "phoneNumber": "Phone Number",
  "phoneNumberEnoro": "Phone Number (Enoro)",
  "phoneVerification": "Phone verification",
  "physicalReference": "Physical reference",
  "planned": "Planned",
  "platformLabels": "Platform labels",
  "platformSideFee": "Set up a subscription fee on the platform side",
  "plugAndCharge": "Plug&Charge",
  "plugAndChargeContractIdPrefix": "Plug&Charge prefix",
  "plug_and_charge": "Plug&Charge",
  "plugsurfingPowerPlatform": "Plugsurfing Power Platform",
  "pmNickname": "Nickname",
  "pncContract": "Plug&Charge Contract",
  "pncDefaultContract": "Used as default for vehicle",
  "poNumber": "PO number",
  "position": "Position",
  "post": "Post",
  "postalCode": "Postal Code",
  "power": "Power",
  "powerRange_condition": "Power",
  "powerSq": "Power [kW]",
  "powerType": "Power Type",
  "powerType_condition": "Current",
  "premium": "Premium",
  "prepaid": "Prepaid",
  "prepaidAdd": "Add Prepaid",
  "prepaidAddError": "Failed to add prepaid account!",
  "prepaidAddSuccess": "Successfully added prepaid account",
  "prepaidDeleteConfirm": "Are you sure you want to delete the selected prepaid account?",
  "prepaidDeleteError": "Failed to delete the selected prepaid account. {{error}}",
  "prepaidDeleteSuccess": "Successfully deleted prepaid account",
  "prepaidReportExport": "Export report to .csv file",
  "prepaidUpdateError": "Failed to update prepaid amount!",
  "prepaidUpdateSuccess": "Successfully added prepaid account",
  "prepaidValidity": "Validity of the prepaid",
  "preview": "Preview",
  "previewFile": "Preview file",
  "previous": "Previous",
  "price": "Price",
  "priceAdd": "Add Price",
  "priceComponentAdd": "Add Price Component",
  "priceDefinedExcludingVat": "Price defined excluding VAT",
  "priceDefinedIncludingVat": "Price defined including VAT",
  "priceDefinition": "Price definition",
  "priceDescriptionPublic": "Public price description",
  "priceElementENERGY": "Energy",
  "priceElementFLAT": "Flat",
  "priceElementMARKUP": "Markup",
  "priceElementPARKING_TIME": "Parking time",
  "priceElementTIME": "Time",
  "priceExcVat": "Price excl VAT",
  "priceForCustomers": "Tariff for customers",
  "priceIn": "Price in",
  "priceIncVat": "Price incl VAT",
  "priceOn": "Price on",
  "priceOutDrivers": "Price out driver",
  "priceOutEMPs": "Price out EMP",
  "priceOverview": "Price overview",
  "pricePerUnit": "Price per unit",
  "priceProfile": "Price profile",
  "priceProfileActive": "Active",
  "priceProfileAssociationCreateError": "An error occurred when creating the new association.",
  "priceProfileAssociationCreateSuccess": "The new association is now created",
  "priceProfileAssociationDeleteConfirm": "Are you sure you want to remove the price profile association?",
  "priceProfileAssociations": "Price Profile Associations",
  "priceProfileAssociationsBulkUpload": "Import price profile associations",
  "priceProfileAssociationsCSVBulkUploadAddButton": "Import price profiles associations from CSV",
  "priceProfileAssociationsCSVBulkUploadAddButton ": "Import price profiles associations from CSV",
  "priceProfileAssociationsCSVBulkUploadDownloadExample": "Please use the template and fill it with relevant data. Don’t change the name and order of columns.",
  "priceProfileAssociationsCSVBulkUploadDownloadExampleButtonTitle": "Download template",
  "priceProfileAssociationsCSVBulkUploadError": "An error occurred while uploading the file!",
  "priceProfileAssociationsCSVBulkUploadFormTitle": "File upload",
  "priceProfileAssociationsCSVBulkUploadModalTitle": "Import price profile associations",
  "priceProfileAssociationsCSVBulkUploadPageTitle": "Price profile associations CSV import",
  "priceProfileAssociationsCSVBulkUploadPartialError": "Errors:\n{{errors}}",
  "priceProfileAssociationsCSVBulkUploadSuccess": "Success !",
  "priceProfileBulkUpload": "Import price profiles",
  "priceProfileCSVBulkUploadAddButton": "Import price profiles from CSV",
  "priceProfileCSVBulkUploadDownloadExample": "Please use the template and fill it with relevant data. Don’t change the name and order of columns.",
  "priceProfileCSVBulkUploadDownloadExampleButtonTitle": "Download template",
  "priceProfileCSVBulkUploadError": "An error occurred while uploading the file!",
  "priceProfileCSVBulkUploadFormTitle": "File upload",
  "priceProfileCSVBulkUploadModalTitle": "Import price profiles",
  "priceProfileCSVBulkUploadPageTitle": "Price Profile CSV import",
  "priceProfileCSVBulkUploadPartialError": "Errors:\n{{errors}}",
  "priceProfileCSVBulkUploadSuccess": "Success !",
  "priceProfileCreate": "Create price profile",
  "priceProfileDecommissioned": "Decommissioned",
  "priceProfileDeleteError": "An error occurred while deleting the price association",
  "priceProfileDeleteSuccess": "The selected price association has been successfully deleted",
  "priceProfileNotFound": "Price profile not found",
  "priceProfileOverrideGracePeriod": "Provide a different grace period than 2 minutes.",
  "priceProfileOverrideVat": "Provide another VAT rate than the default one in this country.",
  "priceProfilePricePerHour": "Price per kWh",
  "priceProfilePublicDescription": "Public price description",
  "priceProfileSelect": "Select price profile",
  "priceProfileSimulation": "Simulate Session",
  "priceProfileStatus": "Profile status",
  "priceProfileUpdatedConflictError": "A price profile with the same configuration already exists",
  "priceProfileUpdatedError": "Failed to update Price Profile",
  "priceProfileUpdatedSuccess": "Successfully updated Price Profile",
  "priceProfiles": "Price Profiles",
  "priceProfilesFetchError": "An error occurred while fetching the price profiles.",
  "priceProfilesNone": "No price profiles",
  "priceSections": "Price sections",
  "priceSimplificationHint": "The system simplified price profile.",
  "priceSimulationError": "Price Simulation Failed",
  "priceStartsAfter": "Price starts after",
  "priceSummary": "Price summary",
  "priceTooltip": "Please add the price including VAT",
  "priceType": "Price type",
  "priceTypeAbsolute": "Date & Time based",
  "priceTypeRelative": "Duration based",
  "pricesUpdateSuccess": "Successfully updated prices",
  "pricingGroupName": "Pricing",
  "pricingProfileEndMinutes": "End (minutes)",
  "printAddresses": "Print Addresses",
  "printAddressesInCards": "Print Addresses (70x37mm labels)",
  "priority": "Priority",
  "privacyPolicy": "Privacy Policy",
  "privacyUrl": "Privacy notice URL",
  "private": "Private",
  "privateAccess": "Private access",
  "privateCharging": "Private charging",
  "privateForUserGroups": "Private for customer groups",
  "privileges": "Privileges",
  "privilegesAddError": "Failed to add privileges to the role",
  "privilegesAddSuccess": "Successfully added privileges to the role",
  "privilegesAssigned": "Assigned privileges",
  "privilegesAvailable": "Available privileges",
  "privilegesRemoveError": "Failed to remove privileges from the role",
  "privilegesRemoveSuccess": "Successfully removed privileges from the role",
  "problemType": "Problem",
  "processingError": "Processing Error",
  "productAvailability": "Availability",
  "productConfiguration": "Product configuration",
  "productCountriesAddHint": "Please include total price with VAT.",
  "productCreate": "Create Product",
  "productCreateError": "Unable to create product",
  "productCreateSuccess": "Successfully created product",
  "productDisable": "Disable product",
  "productDisableConfirm": "Product will become invisible in the app immediately.",
  "productDisabledConfirm": "Are you sure you want to disable the product?",
  "productEditTitle": "Product edit",
  "productEnableConfirm": "Product will be visible in the app immediately.",
  "productId": "Product Id",
  "productImageUploadDescription": "You can upload a PNG, JPEG, GIF or WEBP image with max size {{size}}KB.",
  "productName": "Product Name",
  "productNameConfig": "Product name",
  "productOrderAssignError": "Something went wrong when trying to assign the product order",
  "productOrderAssignSuccess": "The product order was assigned successfully",
  "productOrderCancelError": "Something went wrong when trying tocancel the product order.",
  "productOrderCanceled": "The product order was cancelled.",
  "productOrderConfirmError": "Something went wrong when trying to confirm the product order.",
  "productOrderConfirmSuccess": "Product order confirmed",
  "productOrderConfirmedBulk": "Successfully confirmed selected product orders",
  "productOrderConfirmedBulkError": "Error occurred during confirming selected product orders",
  "productOrderSelect": "Select product orders to print addresses",
  "productOrderSelectCompleteWithout": "Select product orders to complete without assigning",
  "productOrders": "Product orders",
  "productOrders-ALL": "All",
  "productOrders-CANCELED": "Canceled",
  "productOrders-COMPLETED": "Completed",
  "productOrders-NEW": "New",
  "productPriceIncludesVAT": "Note that prices are total prices with VAT",
  "productPrices": "Product prices",
  "productRemove": "Remove product",
  "productRemoveConfirm": "Are you sure you want to remove the product?",
  "productRemoveError": "Unable to remove product",
  "productRemoveSuccess": "Successfully removed product",
  "productTranslationToggleInfo": "Add additional languages to translate names and descriptions",
  "productType": "Product Type",
  "productTypeConfig": "Product type",
  "productUpdateError": "Unable to update product",
  "productUpdateSuccess": "Successfully updated product",
  "productUploadImageError": "Unable to upload image",
  "products": "Products",
  "profile": "Profile",
  "profileName": "Profile Name",
  "profileType": "Profile Type",
  "promptLeaveForm": "Are you sure you want to leave? You have unsaved changes.",
  "protocol": "Protocol",
  "provideSpecificValidityPeriod": "Provide Specific Validity Period",
  "provideSpecificValidityPeriodTooltip": "Default validity period will be from now without stop date. For a specific time period, set a start and stop date.",
  "provider": "Organization",
  "providerName": "Provider name",
  "providers": "Providers",
  "psMessage": "PS message",
  "public": "Public",
  "publish": "Publish",
  "published": "Published",
  "publishingChannel": "Publishing Channel",
  "publishingChannelUpdateError": "Failed to update publishing channels for {{assetName}}",
  "publishingChannelUpdateSuccess": "Updated publishing channels for {{assetName}}",
  "publishingChannels": "Publishing Channels",
  "publishingChannelsAndConnectorGroupsWarning": "Warning! All current publishing channels and connector groups will be deleted on the Charging Station.",
  "publishingChannelsConnectorGroupsDeleteSingleWarning": "Warning! All current connector groups will be deleted on the Charging Station.",
  "publishingChannelsConnectorsUpdateWarning": "Warning! All current connector groups will be deleted on the following Charging Stations:",
  "publishingChannelsDeleteMultipleWarning": "Warning! All current publishing channels will be deleted on the following Charging Stations:",
  "publishingChannelsDeleteWarning": "Warning! All current publishing channels will be deleted on the Charging Station.",
  "publishingChannelsDescriptionUpdateWarning": "Warning! Description will be overwritten on the following Charging Stations:",
  "publishingChannelsFetchError": "Failed to fetch available publishing channels",
  "publishingChannelsNotAvailable": "No available publishing channels",
  "publishingChannelsSavedError": "Failed to save available channels",
  "pullInterval": "Pull interval",
  "pullJobActions": "Pull job actions",
  "pullJobDetails": "Pull job details",
  "pullJobEndDateTooltip": "If not set, the current date will be used.",
  "pullJobInformation": "Pull job information",
  "pullJobStartDateTooltip": "This date range refers to the 'last updated' date in case of an OCPI CDR and 'session authorized' date in case of a Hubject/OICP CDR.",
  "pullJobValidationErrors": "Fail on validation errors",
  "pullTimeInformation": "Pull time information",
  "pullType": "Pull type",
  "pushAllTokensForEMP": "Push all tokens for this EMP",
  "pushDeletedKeys": "Push deleted keys",
  "pushDeletedKeysTooltip": "Push the Keys that are deleted in P3",
  "pushTokenAsInvalid": "Push token as Invalid",
  "pushTokenAsInvalidMessage": "This option will push all the tokens as INVALID to the CPO. Are you sure to continue with this option?",
  "pushTokenBatchSizeTooltip": "Number of tokens sent at the same time. Many CPOs accept 10 but if not sure, please check with CPO how may requests they can accept per second.",
  "pushTokenDurationTooltip": "Do you want to push tokens updated between specific dates?",
  "pushTokenOffsetTooltip": "Do you want to start pushing tokens from zero or from other number (if previous push failed halfway)?",
  "pushTokens": "Push tokens",
  "pushTokensDescription": "You can push tokens to the CPO over this connection (tokens belonging to all the receiver EMPs, any specific receiver EMP or even the selected list of tokens) ",
  "pushTokensError": "Error! There was a problem with pushing tokens",
  "pushTokensSuccess": "Tokens pushed successfully",
  "qrScanner": "QR scanner",
  "qualified_status": "QUALIFIED",
  "quantity": "Quantity",
  "queryParams": "Query params",
  "quickCharger": "Quick Charger",
  "rank": "Rank",
  "rawTariff": "Raw tariff",
  "readingFileError": "reading file error",
  "receipt": "Receipt",
  "receiptDate": "Date of receipt",
  "receiptDownloadError": "Unable to fetch receipt!",
  "receivedDate": "Received Date",
  "received_status": "RECEIVED",
  "receiver": "Receiver",
  "receiverName": "Receiver name",
  "receiverOrganization": "Receiver organization",
  "receivers": "Receivers",
  "recentIssues": "Recent issues",
  "recipients": "Recipients",
  "recurring": "Recurring",
  "redesignAnnouncement": "Plugsurfing Power Platform will have a new look in 2023! Learn more and see the preview <0>here</0>",
  "referenceId": "Reference ID",
  "referenceIdLabelExactMatch": "Reference ID (exact match)",
  "refreshPage": "Refresh page",
  "refund": "Refund",
  "refundAccepted": "Refund was successfully created",
  "refundAmount": "Refund amount",
  "refundAmountInvalid": "Invalid refund amount",
  "refundConfirm": "Are you sure you want to refund",
  "refundConfirm2": "Are you sure you want to refund {{amount}} {{currency}}?",
  "refundCreate": "Issue Refund",
  "refundError": "Unable to add refund",
  "refundHint": "Enter amount in {{currency}} to refund or leave unchanged to do a full refund",
  "refundInfo": "Enter amount to refund or leave empty to do a full refund",
  "refundReportExportCardCard": "Export Refund Report as CSV",
  "refundReportExportCardDescription": "Export the organisation's refund report.Select the month the refund was received on and click \"Email CSV File\" to get an email sent to you",
  "refundSendError": "Unable to refund",
  "refundSendSuccess": "Refund request successfully sent. Please note that it might take up to 1 minute for the session to be refunded. Refresh page to see effect.",
  "refund_failed": "Refund failed",
  "refunded": "Refunded",
  "regenerateFile": "Regenerate file",
  "regenerateFileConfirmation": "Do you want to regenerate the invoice?",
  "regenerateInvoiceSuccess": "Successfully regenerated invoice",
  "registerAccount": "Register account",
  "registeredApiKeys": "Registered API keys",
  "regularPrice": "Regular price",
  "relation": "Relation",
  "relative": "Relative",
  "relativeTrialType": "Relative period type",
  "releaseDate": "Release Date",
  "released": "Released",
  "remoteStartConfig": "Remote start config",
  "remoteStartConfigAddWarning": "This CPO already exists in the remote start disabled config, only the comment will be updated ",
  "remoteStartConfigBlacklist": "Remote start configuration",
  "remoteStartConfigTitle": "List of Disabled Remote Start CPOs",
  "remove": "Remove",
  "removeAccess": "Remove access",
  "removeButonn": "Remove",
  "removeConnector": "Remove Connector",
  "removeCountries": "Remove countries",
  "removeCountriesFromTable": "Remove countries from table",
  "removeCountriesHint_one": "Be careful, this action will remove a country from the product, and will affect the app immediatly.",
  "removeCountriesHint_other": "Be careful, this action will remove {{count}} countries from the product, and will affect the app immediatly.",
  "removeCreditCardReservationHint_one": "Are you sure you want to remove credit card reservation from the selected country?",
  "removeCreditCardReservationHint_other": "Are you sure you want to remove credit card reservation from the selected {{count}} countries?",
  "removeDiscount": "Remove discount from table",
  "removeDiscountMessage": "Are you sure you want to remove discount from table?",
  "removeFee": "Remove fee",
  "removeFees": "Remove fees",
  "removeFeesConfirmation": "Do you want to remove the fee?",
  "removeFleetDriversTable": "Remove fleet driver",
  "removeFromBlackList": "Remove CPO from disable list",
  "removeFromBlackListError": "Failed to remove CPO from disable list",
  "removeFromBlackListMessage": "Are you sure you want to you want to remove this CPO from disabled list?",
  "removeFromBlackListSuccess": "Successfully removed CPO from disable list",
  "removeFromTable": "Remove {{object}} from table",
  "removeLanguage": "Remove language",
  "removeLanguageFromTable": "Remove {language} from table",
  "removeLanguageHint": "Be careful, this action will affect the app immediatly.",
  "removeOneTimeFee": "Remove one time fee",
  "removeOwner": "Remove owner",
  "removePrice": "Remove price",
  "removeReservations": "Remove reservations",
  "removeRestriction": "Remove restriction",
  "removeRestrictionCommentPlaceholder": "Please include the requesting party, reason for restriction, and your name.",
  "removeRestrictionConfirm": "Be careful, this will affect the service up to 48hrs.",
  "removeRestrictionError": "There was an error while removing network restriction",
  "removeRestrictionSuccess": "Successfully removed network restriction",
  "removeSubscription": "End subscription",
  "removeSupportedCountriesHint_one": "Are you sure you want to remove support for the selected country? This action will remove all chargers in the selected country from the app.",
  "removeSupportedCountriesHint_other": "Are you sure you want to remove support for the selected {{count}} countries? This action will remove all chargers in the selected countries from the app.",
  "removeValidToDate": "Set to default date",
  "removedEvents": "Removed events",
  "removedPermissions": "Removed permissions",
  "replace": "Replace",
  "reportNotAvailable": "Report is currently not available. Please try again later.",
  "reportProblem": "Report charger problems",
  "reportedAfter": "Reported after",
  "reportedAt": "Report Date",
  "reportedBefore": "Reported before",
  "reportedProblems": "Reported problems",
  "reportedProblemsDetails": "Details of reported problem",
  "reportedTickets": "Reported Tickets",
  "reports": "Reports",
  "requestBody": "Request body",
  "requestNewPassword": "Request new password",
  "requestTime": "Request Time",
  "requestedAt": "Requested at",
  "requireCountry": "Require Country",
  "required": "Required",
  "reseller": "Reseller",
  "resendTemporaryPassword": "Resend temporary password",
  "reservableAmount": "Reservable amount",
  "reservableAmountInputLabel": "Final amount, including {{vat}} VAT",
  "reservableConnector": "Reservation",
  "reservationCapability": "Reservation",
  "reservationData": "Reservation data",
  "reservationValue": "Reservation",
  "reserveKeysForFleetMember": "Reserve keys for fleet organisation",
  "reservedForOrganisation": "Reserved for organisation",
  "reset": "Reset",
  "resetConfirmPasswordPlaceholder": "Repeat new password",
  "resetConfirmationCodePlaceholder": "Enter a code from mail",
  "resetHandshake": "Reset Handshake",
  "resetHandshakeWarning": "This option should only be used when an initial handshake did not succeed on both ends of the integration (CPO/EMP). If the handshake is reset, the endpoint URLs to the partner and the agreed OCPI version will be cleared so the handshake process can be tried again",
  "resetJob": "Reset Job",
  "resetNewPasswordPlaceholder": "Create new password",
  "resetPasswordSuccessDescription": "Your password has been successfully updated. Now you can use it to sign in to the platform. ",
  "responseBody": "Response body",
  "restrictAccess": "Restrict access",
  "restrictedCpo": "Restricted CPO",
  "restrictions": "Restrictions",
  "results": "Results",
  "resultsPerPage": "Results per page",
  "retry": "Retry",
  "retryBulkCDRs": "Retry CDRs",
  "retryBulkCDRsMessage": "Are you sure you want to retry the selected CDRs? We will try to process these CDRs again.",
  "retryBulkCDRsTitle": "Retry CDRs",
  "retryCDR": "Retry CDR",
  "retrying_status": "RETRYING",
  "revenueElements": "Revenue elements",
  "rightOfWithdrawal": "The right of withdrawal",
  "roaming": "Roaming",
  "roamingChannel": "Roaming channel",
  "roamingChannelUpdatedError": "An error occurred when updating the roaming channel.",
  "roamingChannelUpdatedSuccess": "Roaming channel updated!",
  "roamingCpoTooltip": "Fill in the roaming ID of the CPO you wish to make a price profile association for",
  "roamingDetailsFetchError": "Failed to fetch roaming details",
  "roamingEvseTooltip": "Using this field you can assign CPO connectors to new Price Associations.\nPlease use \"%\" wildcard if you would like to assign connectors starts with ID typed before \"%\".\nHere are some examples:\n\n- % or E% this will assign the whole CPO connectors\n- E84A*5A4 - this will assign specific connector\n- E84% - this will be assign all CPO connectors starts with \"E84\"",
  "roamingIdUpdatedError": "An error occurred when updating the roaming ID.",
  "roamingIdUpdatedSuccess": "Roaming ID updated!",
  "roamingPrice": "Roaming price",
  "roamingProtocol": "Roaming Protocol",
  "roamingProvidedBy": "Roaming provided by {{organization}}",
  "roamingProviderPrice": "Roaming provider price",
  "roamingPullJobs": "Roaming Pull Jobs",
  "roamingType": "Roaming type",
  "role": "Role",
  "roles": "Roles",
  "routingNumber": "Routing number",
  "rowErrors": "Row errors",
  "rows": "{{pageSize}} rows",
  "rppAbbrevation": "RPP",
  "rules": "Rules",
  "runJob": "Run Job",
  "runState": "Run State",
  "saasClientCountryCode": "SaaS Client Country Code",
  "saasClientPartyId": "SaaS Client Id",
  "save": "Save",
  "saveChanges": "Save changes",
  "saveForOfflineChargerWarn": "Due to charger being offline sync. for new configuration will not be available.",
  "saveToCsv": "Save to CSV",
  "saveToCsvForDP": "Export as CSV for Deutsche Post",
  "saveToFileError": "Cannot save selected orders.",
  "saveToFileNotSelectedError": "Product orders not selected.",
  "schedule": "Schedule",
  "scheduleExpiry": "Schedule expiry date",
  "scheduleRemoval": "Schedule removal",
  "scheduledVatForCountry": "Scheduled VAT for {{country}}",
  "search": "Search",
  "searchInDetails": "Search in Details",
  "see": "See",
  "seeAll": "See all",
  "seePriceProfilesAssociations": "See price profile associations",
  "seePriceProfilesAssociations_cpoPrice": "See associations for EMP",
  "seePriceProfilesAssociations_empPrice": "See associations for drivers",
  "seePriceProfilesAssociations_priceOutDrivers": "See associations for drivers",
  "seePriceProfilesAssociations_priceOutEMPs": "See associations for EMPs",
  "seePriceProfilesAssociations_roamingPrice": "See associations for EMPs",
  "select": "Select",
  "selectAllEvents": "Select all events",
  "selectAllPermissions": "Select all permissions",
  "selectBillingPaymentMethod": "How do you want to pay?",
  "selectComparisonType": "Select comparison type",
  "selectConfigType": "Select config type",
  "selectConnectorGroup": "Select connector group",
  "selectCountry": "Select country",
  "selectCpoTariff": "Select CPO tariff",
  "selectCurrency": "Select currency",
  "selectCustomerGroup": "Select Customer Group",
  "selectCustomerIoTemplate": "Select Customer-IO template",
  "selectDeletionType": "Keep in mind you cannot undo this action.",
  "selectGender": "Select a gender",
  "selectLabel": "Select label",
  "selectLanguage": "Select language",
  "selectNumberOfChargingKey": "Single key or upload a file of multiple keys?",
  "selectOperator": "Select operator",
  "selectPermissionsToBeEnabled": "Please select which permissions need to be enabled.",
  "selectRevenueElements": "Select revenue elements",
  "selectStartMethod": "Select start method",
  "selectTokens": "Select token push type",
  "selectedCountries": "Selected countries",
  "selectedEvents": "Selected events",
  "selectedPermissions": "Selected permissions",
  "selectedRevenueElements": "Selected revenue elements",
  "sendAuthorizationList": "Send Authorization List",
  "sendCommands": "Send Commands",
  "sendInvite": "Send invite",
  "senderEmail": "Sender's e-mail",
  "sepaTermsText": "By providing your IBAN and confirming this payment, you are authorizing {{ company }} and Stripe, our payment service provider, to send instructions to your bank to debit your account and your bank to debit your account in accordance with those instructions. You are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited.",
  "serialNumber": "Serial Number",
  "serialNumberExactMatch": "Serial Number (exact match)",
  "serialNumberOfReferenceId": "Serial number or reference ID",
  "serialNumberReservation": "Serial number (reservation)",
  "sessionCount_one": "{{count}} session",
  "sessionCount_other": "{{count}} sessions",
  "sessionDebtAllCleared": "Successfully sent clear sessions debt request",
  "sessionDebtClearAll": "Clear all sessions in debt",
  "sessionDebtClearAllLabel": "Clear all sessions in debt",
  "sessionDebtClearError": "An error has occurred while clearing sessions in debt. Please try again later",
  "sessionDebtClearInfo": "Please note that it might take up a few minutes for the all sessions debt to be cleared, depending on the number of sessions",
  "sessionDebtConfirmClearDebt": "Are you sure you want to clear all sessions debt for this user?",
  "sessionDebtSee": "See debt",
  "sessionDebtSeeLabel": "See debt",
  "sessionDebtWarning": "This user has sessions in debt",
  "sessionDefinition": "Session definition",
  "sessionId": "Session ID",
  "sessionLabel": "Session label",
  "sessionLabels": "Session Labels",
  "sessionRuleMessagePlaceholder": "Additional information about the session rule",
  "sessionRules": "Session Rules",
  "sessionStartDate": "Session start date",
  "sessionType": "Session type",
  "sessionsRightNow": "Sessions right now",
  "setDateAndTime": "Set date and time",
  "setLockInPeriod": "Set lock in period",
  "setMyPrice": "Set my price",
  "setPrivate": "Set as private",
  "setPublic": "Set as public",
  "setSpecificUserGroup": "Limit to specific customer group",
  "setSpecificUserGroupTooltip": "If a specific customer group is selected, the price association will only apply to that customer group. If no customer group is selected, it will apply to all customer groups.",
  "setSpecificValidityPeriod": "Provide specific validity period",
  "setSpecificValidityPeriodTooltip": "If you wish for an association to start or end in the future then select this option, otherwise the association will be valid immediately and forever",
  "setToOk": "Set to OK",
  "setVersionsAllowedButton": "Set versions allowed",
  "setting": "Setting",
  "settingsAndServices": "Settings and Services",
  "settingsUpdateError": "Failed to update settings",
  "settingsUpdateSuccess": "Successfully updated settings",
  "shallCreateContract": "Manage Contract certificates",
  "shareVirtualTokensWithPartner": "Share Virtual Tokens With Partner",
  "shareVirtualTokensWithPartner_tooltip": "Shares virtual charging keys with the partner. Most CPOs requires us to share these charging keys for remote start to work, but not all. The default value is true.",
  "shortcuts": "Shortcuts",
  "show": "Show",
  "showAccountInStripe": "Show account in Stripe",
  "showAdvancedFields": "Show advanced fields",
  "showAdvancedFilters": "Show advanced filters",
  "showFullText": "Show full text",
  "showMoreTickets": "Show more tickets",
  "showOnlyAvailableChargers": "Show only available chargers",
  "showOnlyDeleted": "Show only deleted",
  "showOnlyQuickChargers": "Show only quick chargers",
  "showOriginal": "Show original",
  "showRetiredFirmwares": "Show retired firmwares",
  "showSimplified": "Show simplified",
  "showing": "Showing",
  "signIn": "Sign in",
  "signOut": "Sign out",
  "simple": "Simple",
  "simplePrices": "Simple prices",
  "simplifiedProfile": "Simplified profile",
  "simulateSessionFor": "Simulation session for {{profileName}} profile",
  "singleChargingKeyCreation": "Single charging key creation",
  "singleKey": "Single key",
  "siret": "Siret",
  "siretField": "SIRET",
  "skippedLocations": "Skipped locations - {{type}}",
  "smartCharging": "Smart Charging",
  "smsLog": "SMS Log",
  "smsLogDetails": "Message: {{details}}",
  "smsLogDetailsFailure": "Details: {{details}}",
  "socialSecurityNumber": "Social Security Number",
  "softDeleteLocation": "Soft delete location",
  "softDeleteLocationConfirm": "Are you sure you want to soft delete the location for this agreement?",
  "softDeleteLocationError": "There was an error deleting the location",
  "softDeleteLocationSuccess": "Location for the agreement was deleted successfully",
  "softReset": "Soft Reset",
  "someoneElses": "I am the EMP",
  "somethingWentWrong": "Something went wrong",
  "somethingWentWrongAccessingData": "Something went wrong when trying to access your data.",
  "specificCustomerGroup": "Specific customer groups",
  "specificEMP": "Specific EMP",
  "specifyBillingAddress": "Specify Billing Address",
  "specifyContactPerson": "Specify contact person",
  "specifyCpoTariff": "Specify CPO tariff",
  "specifyCpoTariffTooltip": "Enabling an CPO subscription results in all users joining this subscription to be sent to ex. Ionity for driving different CPO session pricing",
  "specifyVisitingAddress": "Specify visiting address",
  "ssnLastDigits": "Last 4 digits of SSN",
  "start": "Start",
  "startDate": "Start date",
  "startFee": "Start fee",
  "startFeeDuration": "Start fee duration (minutes)",
  "startMethod": "Channel",
  "startNumber": "Start Number",
  "startTime": "Start Time",
  "state": "State",
  "stateOrProvince": "State/Province",
  "statementDescriptor": "Statement descriptor",
  "status": "Status",
  "statusUpdateSuccess": "Successfully updated status",
  "stop": "Stop",
  "stopBeforeStart": "Stop before start",
  "stopBeforeStartTooltip": "Number of session in which the stop time is before the start time.",
  "stopTime": "Stop Time",
  "street": "Street",
  "streetName": "Street Name",
  "streetNumber": "Street Number",
  "string": "String",
  "stripeAccount": "Stripe Account",
  "stripeAccountCreatedError": "Could not create Stripe account!",
  "stripeAccountCreatedSuccess": "Congratulations, you successfully created an account!",
  "stripeAccountRegistered": "Stripe Account registered",
  "stripeAccountRegistration": "Stripe Account Registration",
  "stripeAccountRegistrationInfo": "Contact support for creating a Stripe account",
  "stripeAccountRegistrationLink": "Create Stripe Account",
  "stripeAdditionalOwnersInfo": "People that own at least 25% of the company, in addition to the representative",
  "stripeBankAccountInfo": "In order to create a Stripe Connect account, please provide a bank account.",
  "stripeCompanyInfo": "The company that will be associated with your newly created Stripe account",
  "stripeConnectLoadError": "Failed to load Stripe Connect account for organization",
  "stripeCountryInfo": "In order to create a Stripe Connect account, a country must be set to the organization.",
  "stripeError_account_number_invalid": "Invalid account number",
  "stripeError_routing_number_invalid": "Invalid routing number",
  "stripeFailure": "Registration failed",
  "stripeFailureMessage": "Something went wrong during the Stripe Connect account registration",
  "stripeFileUploadRow1": "Provide a document to prove your identity during account provisioning.",
  "stripeFileUploadRow2": "The file must be either a PNG or JPG image, and the maximum allowed file size is 8 MB.",
  "stripeInactiveAccountInfo": "The Stripe Connect account is inactive.",
  "stripeInactiveAccountWarning": "The Stripe Connect account for this organization is inactive. To solve this, finalize the Stripe process.",
  "stripeInfoAddress": "Street address/PO box/Company name",
  "stripeInfoCompanyName": "Thie legal name of the company",
  "stripeInfoCountry": "The country where the bank account is located",
  "stripeInfoCurrency": "The currency paid out to the bank account",
  "stripeInfoFirstName": "The first name of the individual responsible for the account",
  "stripeInfoGender": "The gender of the individual responsible for the account",
  "stripeInfoKanaFirstName": "The Kana variation of the first name of the individual responsible for the account",
  "stripeInfoKanaLastName": "The Kana variation of the last name of the individual responsible for the account",
  "stripeInfoKanjiFirstName": "The Kanji variation of the first name of the individual responsible for the account",
  "stripeInfoKanjiLastName": "The Kanji variation of the last name of the individual responsible for the account",
  "stripeInfoLastName": "The last name of the individual responsible for the account",
  "stripeInfoPersonalId": "The ID number of the individual responsible for the account, as appropriate for the country. For example, a social security number in the US, social insurance number in Canada, etc.",
  "stripeInfoSSNLastDigits": "The last 4 digits of the social security number of the individual responsible for the account",
  "stripeInfoState": "State/County/Province/Region",
  "stripeInfoTown": "City/District/Suburb/Town/Village",
  "stripeInfoZip": "Zip/Postal code",
  "stripePendingAccountInfo": "The Stripe Connect account is not completed.",
  "stripePendingAccountWarning": "The Stripe Connect account for this organization is not completed.",
  "stripePersonalInformationInfo": "The individual responsible for the account",
  "stripeSuccess": "Your information was registered.",
  "stripeSuccessCompletedMessage": "Your Stripe Connect account was successfully registered.",
  "stripeSuccessMessage": "Your Stripe Connect account was successfully registered.",
  "stripeSuccessNotCompletedMessage": "Your information was registered. However, some information still needs to be added.",
  "subCpos": "Sub-CPOs",
  "subject": "Subject",
  "submit": "Submit",
  "subscribe": "Subscribe",
  "subscribeError": "Unable to subscribe",
  "subscribeSuccess": "Successfully subscribed",
  "subscribedAt": "Subscribed at",
  "subscriptionAccess": "Access to subscription",
  "subscriptionAdditionalInformation": "Additional info",
  "subscriptionAdditionalInformationLabel": "Additional info label",
  "subscriptionAdditionalInformationUrl": "Additional info url",
  "subscriptionAdditionalLanguages": "Subscription languages",
  "subscriptionAppInfoText": "This will appear in the app.",
  "subscriptionCancellation": "Subscription cancellation",
  "subscriptionCancellationMessage": "Are you sure you want to you want to cancel subscription {{subscription}} for {{user}}?",
  "subscriptionChargingKeyFee": "SubscriptionFee - Charging Key",
  "subscriptionChargingKeyFeeInfo": "Set a Subscription fee per charging key",
  "subscriptionChargingPrice": "Charging Price",
  "subscriptionConvertFees": "Convert fees to local currency if absent",
  "subscriptionCreate": "Create Subscription",
  "subscriptionCreateError": "Unable to create subscription",
  "subscriptionCreateSuccess": "Successfully created subscription",
  "subscriptionDetails": "Subscription details",
  "subscriptionDuration": "Subscription duration",
  "subscriptionDurationPlaceholder": "Select subscription duration",
  "subscriptionExpiration-DAYS": "day",
  "subscriptionExpiration-MONTHS": "month",
  "subscriptionExpiration-YEARS": "year",
  "subscriptionFeeInformation": "Subscription fees",
  "subscriptionFeePerUser": "Subscription fee per user, including {{vat}}% VAT",
  "subscriptionFeePerUserIncludingVat": "Subscription fee per user, including VAT",
  "subscriptionFeeTariffInformation": "Fee and tariff",
  "subscriptionFeeType_FREE": "Free of charge",
  "subscriptionFeeType_MONTH": "Recurring fee",
  "subscriptionFeeType_ONE_TIME_FEE": "One off fee",
  "subscriptionGracePeriodPlaceholder": "Choose a grace period",
  "subscriptionHidePriceProfileInfo": "Hide price profile info",
  "subscriptionLockInPeriod": "Lock in period (days)",
  "subscriptionNoBillingAccount": "Customer has no billing account and can therefore not subscribe to a subscription",
  "subscriptionPlans": "Subscription plans",
  "subscriptionPriceInformation": "Price Information",
  "subscriptionPricePerChargingKey": "Price per key",
  "subscriptionPricePerUser": "Subscription fee per user",
  "subscriptionSettings": "Subscription settings",
  "subscriptionShowAdditionalInformation": "Show a link to additional information about the subscription",
  "subscriptionShowPriceProfileInfo": "Show price profile info",
  "subscriptionTermsAndConditionsUploadError": "Unable to upload terms and conditions",
  "subscriptionTranslationInformation": "Subscription translations",
  "subscriptionTranslationToggleInfo": "Add additional languages to translate names, descriptions and priceInformations",
  "subscriptionUpdateError": "Unable to update subscription",
  "subscriptionUpdateSuccess": "Successfully updated subscription",
  "subscriptionUserFee": "SubscriptionFee - User",
  "subscriptionUserFeeInfo": "Set a Subscription fee per user",
  "subscriptions": "Subscriptions",
  "subscriptionsBrowse": "Browse subscriptions",
  "subscriptionsNotAvailable": "No subscriptions available",
  "subsidizedSessions": "Subsidized sessions",
  "subtotal": "Subtotal",
  "succeeded_status": "SUCCEEDED",
  "successfullyPulledLocations": "Successfully pulled locations",
  "summary": "Summary",
  "supportEmailAddress": "Support email address",
  "supportFaqLink": "Support faq url",
  "supportPhoneNumber": "Support phone number",
  "supportUrl": "Additional Support URL",
  "supportedCommands": "Supported commands",
  "supportedCountries": "Supported",
  "supportedCountriesCard": "Supported countries",
  "supportedCountryAddError": "Error adding supported country. {{error}}",
  "supportedCountryAddSuccess": "Added supported country",
  "supportedCountryDuplicationError": "Cannot add duplicated supported country",
  "supportedCountryRemoveError": "Error when trying to remove supported country",
  "supportedCountryRemoveMinimumReachedError": "Need to have at least one supported country code",
  "supportedCountryRemoveSuccess": "Supported country removed",
  "supportedCurrencies": "Supported currencies",
  "supportedPaymentMethods": "Supported payment methods",
  "synchronize": "Synchronize",
  "synchronizeError": "Failed to synchronize",
  "synchronizeKeysError": "Failed to synchronize some keys",
  "synchronizeSettingsSuccess": "Successfully synced settings",
  "system": "System",
  "systemUser": "System User",
  "systemUserCreate": "Create System User",
  "systemUserCreatedError": "Failed to create system user",
  "systemUserCreatedSuccess": "Successfully created system user",
  "systemUserDelete": "Delete System User",
  "systemUserDeleteDisclaimer": "If you want to permanently delete this system user you can activate the process here. You cannot undo this action. Please note that it will take 30 days before the system user email can be added to another organization.",
  "systemUserDeleteMessage": "If you want to permanently delete this system user you can activate the process here. You cannot undo this action. Please note that it will take 30 days before the system user email can be added to another organization.",
  "systemUserDeleteModal": "Choose type of deletion",
  "systemUserDeleteUnfoInfoText": "Deleting System User",
  "systemUserDeletedError": "Failed to delete system user.",
  "systemUserDeletedSuccess": "Successfully updated system user",
  "systemUserRestMfa": "Reset MFA",
  "systemUserRestMfaError": "Failed to reset MFA",
  "systemUserRestMfaMessage": "You can reset a user's MFA, for example, when they no longer have access to their linked phone.",
  "systemUserRestMfaModal": "Are you sure want to reset MFA for the user {{email}}.",
  "systemUserRestMfaSuccess": "Successfully reset MFA",
  "systemUserUpdatedError": "Unable to update system user",
  "systemUserUpdatedSuccess": "Successfully updated system user",
  "systemUsersManage": "Manage System Users",
  "tag": "Tag",
  "tariffCustomerGroup": "Tariff customer group",
  "tariffGroup": "Tariff Group",
  "tariffId_condition": "Tariff ID",
  "tariffIds": "Tariff IDs",
  "tariffModuleDisabled_tooltip": "Disables the tariff module. Will not accept incoming calls to the tariff module endpoint.",
  "tariffModuleFileUploadSuccess": "Successfully uploaded file. You will receive the processing result at {{email}}.",
  "tariffName": "Tariff name",
  "tariffOwner": "Tariff owner",
  "technical": "Technical",
  "technicalInformation": "Technical Information",
  "telephone": "Telephone",
  "template": "Template",
  "templateEditError": "An error occurred while saving the template",
  "templateEditSuccess": "Template successfully saved",
  "templateEditUnsupportedTypeMessage": "The editing of this type of template is not currently supported",
  "templateFeatureDescription-charging-keys-report-ready-email": "Notifies the user that the charging keys report requested is ready to be downloaded",
  "templateFeatureDescription-organization-invoice-items-report-ready-email": "Notifies the user that the invoice items report requested is ready to be downloaded",
  "templateFeatureDescription-organization-invoices-report-ready-email": "Notifies the user that the invoices report requested is ready to be downloaded",
  "templateFeatureDescription-product-order-receipt": "Receipt for a product order",
  "templateFeatureDescription-session-history-report-ready-email": "Notifies the user that the session-history report requested is ready to be downloaded",
  "templateFeatureDescription-subscription-welcome-email": "A welcome email sent when a customer signs up for a subscription",
  "templateFeatureDescription-users-report-ready-email": "Notifies the user that the users report requested is ready to be downloaded",
  "templateFeatureName-b2b-customer-registration-email": "b2b customer registration email",
  "templateFeatureName-b2b-member-signup-email": "Email sent to B2B members when they're signed up to the platform",
  "templateFeatureName-billing-account-invoice": "Billing account invoice",
  "templateFeatureName-billing-correction-email": "Email sent to users when an invoice has to be corrected",
  "templateFeatureName-billing-failed-payment-email": "Email sent to a user when an invoice cannot be payed using users payment method",
  "templateFeatureName-billing-fleet-invoice-ready-email": "Email sent to fleet manager when invoice is ready for download",
  "templateFeatureName-billing-invoice-ready-email": "Email sent to user when invoice is ready for download",
  "templateFeatureName-billing-signup-sepa-email": "Email sent to a user when he starts to use a payment by direct debit",
  "templateFeatureName-charging-keys-report-ready-email": "Charging keys report ready for download email",
  "templateFeatureName-charging-session-receipt": "Receipt for a charging session",
  "templateFeatureName-customer-confirmed-email": "Customer confirmed email",
  "templateFeatureName-end-customer-welcome-email": "Email sent to an end-customers when they register to the platform",
  "templateFeatureName-forgot-password-email": "Email sent to users when they need to reset the password",
  "templateFeatureName-one-time-payment-receipt": "One-time payment receipt",
  "templateFeatureName-organization-invoice-items-report-ready-email": "Organization invoice items report ready for download email",
  "templateFeatureName-organization-invoices-report-ready-email": "Organization invoices report ready for download email",
  "templateFeatureName-product-order-receipt": "Product order receipt",
  "templateFeatureName-session-history-report-ready-email": "Session history report ready for download email",
  "templateFeatureName-subscription-paid-email": "Subscription paid email",
  "templateFeatureName-subscription-refund-email": "Subscription refunded email",
  "templateFeatureName-subscription-terminated-email": "Subscription terminated email",
  "templateFeatureName-subscription-terminated-email-payment-failed": "Subscription terminated due to payment failed email",
  "templateFeatureName-subscription-terminated-email-subscription-expired": "Subscription expired email",
  "templateFeatureName-subscription-terminated-email-user-cancellation": "Subscription cancelled by customer email",
  "templateFeatureName-subscription-welcome-email": "Subscription welcome email",
  "templateFeatureName-system-user-welcome-email": "Email sent to system-users when they get registered to the platform",
  "templateFeatureName-system-users-deletion-confirmation": "System User deletion confirmation email",
  "templateFeatureName-user-debt-notification-email": "User debt notification email",
  "templateFeatureName-usergroup-members-report-ready-email": "Customer group members report ready for download email",
  "templateFeatureName-users-deletion-confirmation": "User deletion confirmation email",
  "templateFeatureName-users-deletion-error": "User deletion failed email",
  "templateFeatureName-users-deletion-success": "User deletion successful confirmation email",
  "templateFeatureName-users-report-ready-email": "Users report ready for download email",
  "templateName": "Template Name",
  "templates": "Templates",
  "templatesEditUnsavedChanges": "You have unsaved changes for the languages {{languages}}. Do you want to continue? All your changes will be discarded.",
  "temporaryFilename": "Temporary Filename",
  "terminate": "Terminate",
  "terminateAgreement": "Terminate agreement",
  "terminateAgreementConfirm": "Are you sure you want to terminate this agreement?",
  "terminateMarketplaceConnectionError": "Failed to terminate agreement",
  "terminateMarketplaceConnectionSuccess": "Agreement terminated",
  "terminatedAt": "Terminated at",
  "terminationReason": "Termination reason",
  "termsAndConditions": "Terms and Conditions",
  "termsUrl": "Terms and Conditions URL",
  "test": "Test",
  "theCurrenciesYourCustomersCanChoose": "The currencies your customers can choose to be billed in.",
  "thirdPartyData": "Third party data enhancements",
  "thisMonth": "This month",
  "thresholdSum": "Threshold sum",
  "throttle": "Throttle (in Milliseconds)",
  "tierDefinitionByDiscount": "Tier definition by Discount",
  "tierDefinitions": "Tier definitions",
  "tiered": "Tiered",
  "tieredPrices": "Tiered prices",
  "tiersMustNotHaveGaps": "Tier ranges must not have gaps",
  "time": "Time",
  "timeOfDay": "Time of day",
  "timeSkew": "Time skew",
  "timeThreshold": "Time Threshold",
  "timesReceived": "Times received",
  "timesTried": "Times tried",
  "timestamp": "Timestamp",
  "timezone": "Time zone",
  "title": "Title",
  "to": "To",
  "toDate": "To date",
  "toDateHint": "Your keys will be valid for 10 years by default. You can add a custom validity date.",
  "toStartTime": "To: Start Time",
  "toggleCreditCardReservationError": "An error occurred, please try again",
  "toggleCreditCardReservationSuccess": "Reservation of the amount on credit cards has been {{status}}",
  "token": "Token",
  "tokenPushEnabled": "Token Push Enabled",
  "tokenPushEnabled_tooltip": "Pushes new/updated charging keys to the partner. The default value is true, it should only be disabled if the partner does not support receiving such updates.",
  "topSearch": "Top search",
  "total": "Total",
  "totalBalance": "Total Balance",
  "totalItems": "Total items",
  "totalPrice": "Total price: {{price}} {{currency}}",
  "totalTime": "Total Time (min)",
  "transactionDate": "Transaction date",
  "transactionId": "Transaction ID",
  "transactionList": "Transaction list",
  "transactionListDownloadError": "An error occurred while fetching the transaction list.",
  "transactionOverview": "Transaction overview",
  "transactionsAndRefund": "Transactions & refund",
  "translateToEnglish": "See translation (to English)",
  "translation": "Translation",
  "translationRemoveSuccess": "Successfully removed translation",
  "translationUpdateSuccess": "Successfully updated translation",
  "translations": "Translations",
  "translationsAddSuccess": "Successfully added translations",
  "trialActive": "Set grace period",
  "trialPeriodInformation": "Trial period",
  "trialType": "Type of grace period",
  "troubleshooting": "Troubleshooting",
  "true": "True",
  "trustDevice": "Trust this device",
  "tryAgain": "Try again",
  "tryAnotherAccount": "Try another account",
  "tvaField": "N° TVA",
  "twentyFourSevenAvailability": "24/7 availability",
  "twentyFourSevenOpening": "24/7",
  "type": "Type",
  "typeInTheReferenceId": "Type in the reference ID",
  "typeInTheSerialNumber": "Type in the serial number",
  "typeToSearch": "Type to search",
  "unAuthorizedErrorHeading": "We didn't recognise you",
  "unAuthorizedErrorMessage": "Sorry, your account is not authorised to access this page. Please log in with another account or contact your manager.",
  "unableCancelReservation": "Unable to cancel reservation!",
  "unableStartSession": "Unable to start session!",
  "unableStopSession": "Unable to stop session!",
  "unassigned": "Unassigned",
  "unauthorized": "Unauthorized",
  "unavailable": "Unavailable",
  "unavailableConnectorsAggregated": "Needs attention",
  "unblockInvoice": "Unblock invoice",
  "unchangedValues": "Unchanged values",
  "unchangedVatValues": "Unchanged VAT values",
  "undo": "Undo",
  "unit": "Unit",
  "unitPrice": "Unit price",
  "unitType": "Unit type",
  "units": "Units",
  "unknown": "Unknown",
  "unknownType": "Unknown type",
  "unlimited": "Unlimited",
  "unlimitedUse": "Unlimited use",
  "unlock": "Unlock",
  "unnamedUser": "Unnamed user",
  "unselectAllEvents": "Unselect all events",
  "unselectAllPermissions": "Unselect all permissions",
  "unsubscribe": "Unsubscribe",
  "unsubscribeError": "Unable to unsubscribe",
  "unsubscribeSuccess": "Successfully unsubscribed",
  "unusualSessions": "Unusual sessions",
  "unusualSessionsDescription": "Sessions are labelled based on different criteria to give you an overview and starting point for investigation.",
  "upcomingInvoice": "Next Invoice",
  "update": "Update",
  "updateBlacklistCpoSuccess": "Disabled CPO has been successfully updated",
  "updateCredentials": "Update Credentials",
  "updateError": "There was a problem while updating",
  "updateFirmware": "Update Firmware",
  "updateInvoiceStatus": "Update invoice status",
  "updateInvoiceStatusConfirmation": "Please note that once the invoice status is updated, it cannot be changed back.",
  "updateLabelsError": "Failed to update labels. {{error}}",
  "updateLabelsSuccess": "Labels updated",
  "updateLocationFreeTextError": "Cannot update location",
  "updateLocationFreeTextSuccess": "Location updated",
  "updateOpeningHoursError": "Cannot update Opening hours",
  "updateOpeningHoursSuccess": "Opening hours updated",
  "updatePaymentMethodsSuccess": "Successfully updated payment methods.",
  "updateRestrictionError": "There was an error while updating network restriction",
  "updateRestrictionSuccess": "Successfully updated network restriction",
  "updateStatusSuccess": "Successfully updated status",
  "updateSuccess": "Successfully updated",
  "updateType": "Update type",
  "updateWebhookConfigurationSuccess": "Successfully updated webhook configuration",
  "updateWebhookEventsSuccess": "Successfully updated webhook events",
  "updated": "Updated",
  "upload": "Upload",
  "uploadBatch": "Upload batch",
  "uploadBatchDescription": "Only .csv files. 500kb max file size.",
  "uploadBatchInfo": "For best results, we recommend following the template from the file below.",
  "uploadImageDescription": "Only .jpg and .png files. 500kb max file size",
  "uploadInProgress": "Upload in progress, do not close this browser tab.",
  "uploadTariffIdMappingFile": "Upload EVSE and tariff associations",
  "uploadTariffModuleFile": "Upload tariff details",
  "uploadTariffModuleHint": "Use downloaded association files as the template. CSV and Excel file is accepted.",
  "uptime": "Uptime (%)",
  "uptimeNetworkOverview": "Uptime Network Overview",
  "url": "URL",
  "usage": "Usage",
  "useContractId": "Add Contract-ID to new charging keys",
  "useContractId_tooltip": "Add Contract-ID to new charging keys",
  "useCustomerIoTemplate": "Use Customer-IO template",
  "useEvcoIds": "Use EVCO id's",
  "user": "Customer",
  "userCanActivateKey": "User can activate key",
  "userChargingSessions": "User Charging Sessions",
  "userDetails": "User General Information",
  "userGroup": "Customer group",
  "userHasNoBillingCannotAddCreditsError": "User has no billing, credits cannot be added",
  "userHasNoBillingCannotAddOneTimeFeeError": "User has no billing, one-time fee cannot be added",
  "userName": "User name",
  "userRemoveError": "Unable to remove user",
  "userRemoveSuccess": "Successfully removed user",
  "userRoles": "User Roles",
  "user_has_billing": "This user has billing. Billing account must be removed before removal is allowed",
  "user_has_debt": "This user has debt. All debts should be cleared before removal is allowed",
  "user_has_subscription": "This user has an active subscription. The subscriptions must be canceled or expired before removal is allowed.",
  "utilisationByTime": "Utilization (time) %",
  "validAt": "Valid at",
  "validDateHint": "Your keys will be valid for 10 years by default. You can add a custom validity date.",
  "validFrom": "Valid From",
  "validFromAndUntil": "Valid from and until",
  "validTo": "Valid To",
  "validUntil": "Valid until",
  "validateActiveToActiveFrom": "\"Active to\" date must be later than \"Active from\"",
  "validateAfter": "Must not be after {{limit}}",
  "validateAssetLabel": "Must not be empty, maximum two alphanumerical characters, must be unique within this Charging Station.",
  "validateConnectorNumbers": "A connector number can only occur once",
  "validateDescription": "Must not be longer than 255 characters",
  "validateEndDateSet": "Must have a value when end date is set",
  "validateEvseId": "Must consist of only letters, numbers and '*'s",
  "validateExternalId": "Must be a valid External ID e.g. DE*123",
  "validateFileAllowedFormat": "Selected file is not of an allowed format ({{allowedFormats}})!",
  "validateIdInUse": "ID already in use",
  "validateIncludesLowercase": "Must include lowercase letters",
  "validateIncludesNumber": "Must include numbers",
  "validateIncludesSpecialCharacters": "Must include special characters",
  "validateIncludesUppercase": "Must include uppercase letters",
  "validateInvalidCharacters": "Invalid characters",
  "validateInvalidDateComparison": "Must not be after",
  "validateInvalidDateComparisonStartAfterStop": "Start time must not be after Stop time",
  "validateInvalidDateComparisonStartStopSame": "Start and Stop time can not be the same",
  "validateInvalidDurationComparisonMinMaxSame": "Start and End duration can not be the same",
  "validateInvalidKwhComparisonMinMaxSame": "Minimum and maximum kWh can not be the same",
  "validateInvalidMaxBeforeMinKwh": "Minimum kWh must be smaller than maximum kWh",
  "validateInvalidOrganization": "Must be valid organization",
  "validateInvalidPrice": "Invalid price",
  "validateInvalidTimeComparisionFromBeforeTo": "From time must not be after To time",
  "validateInvalidTimeComparisionFromTo": "From and To time can not be the same",
  "validateIsBiggerThanZero": "Must be bigger than zero",
  "validateIsInteger": "Must be an integer",
  "validateIsLessThanDuration": "Must be less than duration",
  "validateIsNumber": "Must be a non-negative number",
  "validateIsNumberBiggerThanZero": "Must be a number bigger than 0",
  "validateIsRegularPriceForExternalCpo": "Price profile type should be Regular for external CPO",
  "validateLabelInUse": "Label already in use",
  "validateLastPriceSectionDuration": "Last end duration must be empty. Please add another price section or empty last end duration.",
  "validateLengthExactly": "Must be exactly {{length}} characters",
  "validateLessThanGroupMax": "Must be less than Group max current",
  "validateLessThanMaxPerConnector": "Must be less than Max current per connector",
  "validateMaxCharacters": "Must not be longer than {{max}} characters",
  "validateMinCharacters": "Must be at least {{min}} characters.",
  "validateMinLengthEnd": "characters.",
  "validateMinLengthStart": "Must be at least",
  "validateModules": "At least a module needs to be selected",
  "validateMultipleIdsInUse": "More than 10 IDs are already in use",
  "validateNoDuplicateEmails": "Must not contain duplicates",
  "validateNoForbiddenCharacters": "Should not have forbidden characters",
  "validateNoSpace": "Must not contain spaces",
  "validateNoSpecialCharacters": "No special characters allowed",
  "validateNonNegative": "Must be non-negative",
  "validateNonNegativeNumber": "Must be non-negative number",
  "validateNotAfter": "Must be after {{ date }}",
  "validateNotDuplicate": "Must not be duplicate",
  "validateNotEmpty": "Must not be empty",
  "validateNotFalse": "Must be checked",
  "validateNumberBetween": "Must be a number between {{min}} and {{max}}",
  "validateNumberOfPriceSections": "There must be at least two price sections for a duration based profile. Alternatively, use a Date & time based profile",
  "validateOpeningHoursDate": "Days in conflict with other Opening hours: {{days}}",
  "validateOrganizationDomain": "Must be lowercase alphanumeric. Allowed additional symbols are: ['.', '-', '_']",
  "validatePasswordsEqual": "Passwords must be equal",
  "validatePowerOutletId": "Please provide EVSE Id with this format - E{up to 30 characters}",
  "validateSmsLogPhoneNumber": "Must be on format +xx71234567 where xx is country code",
  "validateStartDateBeforeNow": "Start date is in the future",
  "validateStartDateSet": "Must have a value when start date is set",
  "validateStartFeeDurationDefined": "Start fee cannot be 0 with no start fee duration",
  "validateStartFeeWhileDuration": "Must be provided when using start fee duration. Can be 0.",
  "validateSubscriptionChargingKeyFeeOrUserFee": "Specify charging key fee or user fee",
  "validateUnableToValidateAssetLabel": "Possibly invalid label.",
  "validateUnableToValidateExternalId": "Possibly invalid ID.",
  "validateUnique": "Must be unique",
  "validateValidEmail": "Must be a valid email address",
  "validateValidPhoneNumber": "Must be a valid phone number",
  "validateValidUrl": "Must be a valid URL",
  "validityInDaysFromRedemption": "Validity in days from redemption",
  "validityPeriod": "Validity period",
  "validityPeriod_forever": "Forever",
  "value": "Value",
  "values": "Values",
  "variableFees": "Variable fees",
  "variableNotDefined": "Variable \"{{variable}}\" is not defined",
  "variables": "Variables",
  "vat": "VAT",
  "vatCategory": "VAT category",
  "vatChargingSession": "VAT charging session",
  "vatFieldEnabled": "Enable VAT field for Users",
  "vatMasterTable": "VAT master table",
  "vatNumber": "VAT number (Must start with the country code)",
  "vatNumberHeader": "VAT number",
  "vatProducts": "VAT products",
  "vatRate": "VAT rate",
  "vatSubscriptions": "VAT subscriptions",
  "vbanBalance": "Vban balance",
  "vendorErrorCode": "Vendor Error Code {{code}}",
  "vendorErrorCodeLabel": "Vendor Error Code",
  "vendorErrorCodeTrigger": "Vendor Error Code Trigger",
  "vendorErrorCodeTriggers": "Vendor Error Code Triggers",
  "verified": "Verified",
  "version": "Version",
  "viaChargingNetwork": "Via charging network",
  "viewAs": "View as",
  "viewCDRPayload": "View CDR Payload",
  "viewDetails": "View details",
  "viewEndpoint": "view endpoint",
  "viewJob": "View Job",
  "viewLocations": "View locations",
  "viewMore": "View more",
  "viewOrganization": "View organization",
  "viewScheduledValues": "View scheduled values",
  "viewSessions": "View sessions",
  "viewToken": "view token",
  "viewViolations": "View violations",
  "vinAlreadyInUse": "Provided VIN is already in use by the user:",
  "vinNumber": "VIN",
  "vinRestrictions": "Requires VIN number to login",
  "vin_already_in_use": "Provided VIN is already in use by another user",
  "vin_not_allowed_by_emp": "Provided VIN is not allowed by EMP",
  "violations": "Violations",
  "virtaulKeyUserPart": "Virtual System Key: User Part",
  "virtual": "Virtual",
  "virtualKeyKeyPart": "Virtual System Key: Key Part",
  "virtualKeyLoadError": "Failed to load virtual key information",
  "visibility": "Visibility",
  "visibilityInTheApp": "Visibility in the app",
  "visibilityPeriod": "Visibility period",
  "visible": "Visible",
  "visitingAddress": "Visiting Address",
  "voltage": "Voltage [V]",
  "volumeBasedDiscount": "Volume based discount",
  "voucherCampaignCreate": "Create voucher campaign",
  "voucherCode": "Voucher code",
  "voucherCodePrefix": "Prefix for the Voucher Code",
  "voucherCodeReusable": "Reusable Voucher Code",
  "voucherCodeTypeError": "Non existant voucher type",
  "voucherCodeUnique": "Unique Voucher Code",
  "voucherUpdateError": "Failed to update voucher quantity",
  "vouchersExport": "Export vouchers to .csv file",
  "vouchersExportError": "Failed to export vouchers",
  "waitingForRefund": "Waiting for refund",
  "warningCredentials": "Updating the partner token and URL should normally only be done before a first successful handshake is completed.  Changing the partner token on an operational OCPI connection will cause interruptions.",
  "webhookConfiguration": "Webhook configuration",
  "webhookCreateSuccess": "Successfully created a webhook",
  "webhookEvents": "Webhook events",
  "webhookEventsHint": "Please select which events need to be enabled.",
  "webhookHeaderHint": "At least one header must be configured to enhance the endpoint's security.",
  "webhookN": "Webhook {{n}}",
  "webhooks": "Webhooks",
  "webportalBrandingTitle": "Webportal",
  "webportalConfigUpdateFailed": "Webportal configuration update failed",
  "webportalConfigUpdateSuccess": "Webportal configuration update success",
  "week": "Week",
  "weekly": "Weekly",
  "whichPaymentMethodsDoYouWantSupport": "Which payment methods do you want support?",
  "withTransactions": "with transactions",
  "withoutRetiredFirmwares": "Without retired firmwares",
  "writeOff": "Write off",
  "writeOffConfirm": "Are you sure you want to write off session?",
  "writeOffInvoiceRequestSuccess": "Write off request successfully sent. Please note that it might take up to 1 minute for the invoice to be written off. Refresh page to see effect.",
  "writeOffRequestError": "Unable to write off debt",
  "writeOffRequestSuccess": "Write off request successfully sent. Please note that it might take up to 1 minute for the session to be written off. Refresh page to see effect.",
  "writeTokens": "Specify tokens",
  "wrong_connector_power": "Wrong connector speed",
  "wrong_connector_type": "Wrong connector type",
  "year": "Year",
  "yearOfBirth": "Year of birth",
  "yes": "Yes",
  "youCanOnlyLinkToOneIntegration": "You can only link to one integration",
  "yourCSVFile": "Your CSV file",
  "yourFile": "Your file",
  "yourNetwork": "Your network"
};
